import { Component, createRef } from 'preact';
import { PureComponent } from 'preact/compat';
import _ from 'lodash';
import { subscribe, unsubscribe, dispatch } from '../../../customEvents';

class ScrollTransition extends PureComponent {
	constructor(props){
		super(props);

		this.uid = _.uniqueId();

		this.state = {
			inited: false,
			viewportIntersection: 'below',
			lazyLoadIntersection: 'below',
			isVisible: false,
			hasLayout: false,

			inTransition: false,
			suppressTransition: true,
		}

	}

	render(props, state){

		// by default this sets variables for translation
		// but we can extend this behavior to other variables as well, like opacity/ border
		// set <div uses="scroll-transition" scroll-variables="border opacity">
		// 'border' would print out a style for border: var(--border-above) (-inside / -below), allowing one to access it through css variables

		let viewportIntersection = this.state.viewportIntersection;

		let scrollVariables = this.props['scroll-variables'].split(' ').filter(name=>name!=='').map(variable=> '--scroll-'+variable );
		scrollVariables.push('--scroll-translate', '--scroll-transition', '--scroll-opacity');
		scrollVariables = scrollVariables.map(variableName=> variableName +': var('+(variableName.startsWith('--') ? '': '--' )+variableName+'-'+viewportIntersection + '); ').join(' ')

		return <>
			{props.children}		
			<style id="scroll-transition">{`
				:host {
					--scroll-opacity-above: 1;
				    --scroll-opacity-inside: 1;
				    --scroll-opacity-below: 0;
					opacity: var(--scroll-opacity, 1);

					--scroll-transition-above: 0s transform ease-in-out, 0s opacity ease-in-out;
				    --scroll-transition-inside: 1s transform ease-in-out, 1s opacity ease-in-out;
				    --scroll-transition-below: 0s transform ease-in-out, 0s opacity ease-in-out;
					${(this.state.suppressTransition || !this.state.inited) ? '': 'transition: var(--scroll-transition);'}

					--scroll-translate-below: translateY(40px);
					--scroll-translate-inside: translateY(0px);
					--scroll-translate-above: translateY(0px);

					${scrollVariables}
				}
			`}</style>		
		</>		

	}

	onViewportIntersectionChange = data => {

		this.mostRecentVisibility = {
			isVisible: data.visible,
			viewportIntersection: data.position,
			hasLayout: data.hasLayout
		}

		if( this.ticking){
			return;
		}

		this.ticking = true;

		this.viewportAnimFrame = requestAnimationFrame(()=>{

			if(this.props.onViewportChange){
				this.props.onViewportChange(this.mostRecentVisibility.viewportIntersection)
			}

			this.setState({...this.mostRecentVisibility})

			this.ticking = false;
		})
	}



	onTransitionStart = ()=>{
		this.setState({
			inTransition: true
		})
	}	

	onTransitionEnd = ()=>{
		this.setState({
			suppressTransition: true,
			inTransition: false
		})
	}


	componentDidMount(){

		this.props.baseNode.addEventListener('transitionstart', this.onTransitionStart);
		this.props.baseNode.addEventListener('transitionend', this.onTransitionEnd);
		this.props.baseNode.addEventListener('transitioncancel', this.onTransitionEnd);

		subscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);

		this.initTimeout = setTimeout(()=>{
			this.setState({
				inited: true
			})
		}, 300);
		
	}

	componentDidUpdate(prevProps, prevState){

		// if we reparented things, we'll have a brief moment of no-layout
		if( !this.state.hasLayout && this.state.hasLayout!== prevState.hasLayout && this.state.inited){
			this.setState({
				inited: false,
			})
		}
		// when bringing things back online after layout change, reset init
		if(this.state.hasLayout!== prevState.hasLayout && this.state.isVisible && this.state.hasLayout && !this.state.inited){
			
			clearTimeout(this.initTimeout);
			this.initTimeout = setTimeout(()=>{
				this.setState({
					inited: true
				})
			}, 120);			
		}
		if(
			(this.state.viewportIntersection !== prevState.viewportIntersection &&
			this.state.inited) 
		){
			clearTimeout(this.transitionCheckTimeout);
			if( this.state.isVisible){

				this.setState({
					suppressTransition: false,
				}, ()=>{

					this.transitionCheckTimeout = setTimeout(()=>{
						if( !this.state.inTransition && this.state.isVisible ){
							this.setState({
								suppressTransition: true
							})
						}						
					}, 50);				
				})
			} else {

				// if it's out of viewport, stop the animation and reset its state
				this.setState({
					suppressTransition: true,
				}, ()=>{
					this.transitionCheckTimeout = setTimeout(()=>{
						this.setState({
							suppressTransition: false,
						})
					}, 120);
				})
			}

		}

		// after init — check if the element is visible - if it's not , make tansitions allowed
		if(
			this.state.inited !== prevState.inited
		){
			this.setState({
				suppressTransition: this.state.isVisible,
			})

		}			


	}


	componentWillUnmount(){
		clearTimeout(this.transitionCheckTimeout);
		cancelAnimationFrame(this.viewportAnimFrame);
		cancelAnimationFrame(this.lazyloadFrame);

		clearTimeout(this.initTimeout)
		this.props.baseNode.removeEventListener('transitionstart', this.onTransitionStart);		
		this.props.baseNode.removeEventListener('transitionend', this.onTransitionEnd);
		this.props.baseNode.removeEventListener('transitioncancel', this.onTransitionEnd);

		unsubscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange)

	}
}

ScrollTransition.defaultProps = {
	'scroll-variables': '',
}

ScrollTransition.transformSlot = '--scroll-translate'

export default ScrollTransition;

