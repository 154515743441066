import { lt } from 'lodash';
import { Component } from 'preact';
import { createRef } from 'preact/compat'

import { flyingObjectDefaults } from '../../../../../admin/src/defaults/flyingobject-defaults';

import { subscribe, unsubscribe, dispatch } from '../../../customEvents';

class FlyingObject extends Component {
	constructor(props){
		super(props);
		this.flierRef = createRef();
		const startingFlightCount = this.generateRandomIntInclusive(1, 10000);
		this.state = {
			currentAnimation: 'fly1',
			flightCount: startingFlightCount,
			calculatedAnimations: {
				fly1: this.calculateFlyAnimation(startingFlightCount, 1),
				fly2: this.calculateFlyAnimation(startingFlightCount, 2),
				fly3: this.calculateFlyAnimation(startingFlightCount, 3),
				fly4: this.calculateFlyAnimation(startingFlightCount, 4),
			}
		}
	}

	generateRandomIntInclusive(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included 
	}

	calculateDuration(speed) {
		const percent = (Math.max((100 - Math.abs(speed)), 5)) * 0.01;
		const total = 100;
		return (total * percent);
	}

	generateRandomIntFromSeed(seed) {
		var x = Math.sin(seed) * 10000; 
		return x - Math.floor(x);
	}

	shuffleFromSeed(array, seed) {
		var m = array.length, t, i;
		  
		while (m) {
			i = Math.floor(this.generateRandomIntFromSeed(seed) * m--);
			t = array[m];
			array[m] = array[i];
			array[i] = t;
			++seed
		}
		return array;
	}

	calculateFlyAnimation(seed, part) {

		const isEven = seed % 2 == 0;

		const arr = this.shuffleFromSeed(['ltr','rtl','ttb','btt'], seed);
		const offsetNumber = Number(this.props.scale.replace('vmax', ''));

		const totalRotation = (this.props.rotation / 5) * 180;
		
		const ltr = [
			{'x':`calc(0vw - ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 0 - (1.4 * offsetNumber) : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vh`},
			{'x':`calc(100vw + ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vh`}
		]

		const rtl = [
			{'x':`calc(100vw + ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vh`},
			{'x':`calc(0vw - ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 0 : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vh`},
		]

		const ttb = [
			{'x':`${this.generateRandomIntInclusive(isEven ? 0 - (1.4 * offsetNumber) : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vw`, 'y':`calc(0vh - ${1.4 * offsetNumber}vmax)`},
			{'x':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vw`, 'y':`calc(100vh + ${1.4 * offsetNumber}vmax)`},
		]

		const btt = [
			{'x':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vw`, 'y':`calc(100vh + ${1.4 * offsetNumber}vmax)`},
			{'x':`${this.generateRandomIntInclusive(isEven ? 0 - (1.4 * offsetNumber) : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vw`, 'y':`calc(0vh - ${1.4 * offsetNumber}vmax)`},
		]

		const points = arr.reduce((prevValue, currentValue) => {
			switch(currentValue) {
				case 'ltr':
					return prevValue.concat(ltr);
				case 'rtl':
					return prevValue.concat(rtl);
				case 'ttb':
					return prevValue.concat(ttb);
				case 'btt':
					return prevValue.concat(btt);
				default:
					return prevValue;
			}
		}, [])

		switch ( part ) {
			case 1: {
				return `
					0% {
						transform: translateX(${points[0].x}) translateY(${points[0].y});
						opacity: 0;
					}
					0.001% {
						transform: translateX(${points[0].x}) translateY(${points[0].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[1].x}) translateY(${points[1].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[1].x}) translateY(${points[1].y});
						opacity: 0;
					}
				`
			}
			case 2: {
				return `
					0% {
						transform: translateX(${points[2].x}) translateY(${points[2].y});
						opacity: 0;
					}
				
					0.001% {
						transform: translateX(${points[2].x}) translateY(${points[2].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[3].x}) translateY(${points[3].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[3].x}) translateY(${points[3].y});
						opacity: 0;
					}
				`
			}
			case 3: {
				return `
					0% {
						transform: translateX(${points[4].x}) translateY(${points[4].y});
						opacity: 0;
					}
		
					0.001% {
						transform: translateX(${points[4].x}) translateY(${points[4].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[5].x}) translateY(${points[5].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[5].x}) translateY(${points[5].y});
						opacity: 0;
					}
				`
			}
			case 4: {
				return `
					0% {
						transform: translateX(${points[6].x}) translateY(${points[6].y});
						opacity: 0;
					}
		
					0.001% {
						transform: translateX(${points[6].x}) translateY(${points[6].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[7].x}) translateY(${points[7].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[7].x}) translateY(${points[7].y});
						opacity: 0;
					}
				`
			}
		}
	}

	onAnimationEnd(e) {
		const prev = Number(e.animationName.substr(3));
		const next = prev < 4 ? prev + 1 : 1;

		this.setState({
			currentAnimation: `fly${next}`,
			...(next === 1 && {
				flightCount: this.state.flightCount + 1,
				calculatedAnimations: {
					fly1: this.calculateFlyAnimation(this.state.flightCount + 1, 1),
					fly2: this.calculateFlyAnimation(this.state.flightCount + 1, 2),
					fly3: this.calculateFlyAnimation(this.state.flightCount + 1, 3),
					fly4: this.calculateFlyAnimation(this.state.flightCount + 1, 4),
				}
			}),
		});
	}

	componentDidMount() {
		if ( this.flierRef.current) {
			this.flierRef.current.addEventListener('animationend', (e) => this.onAnimationEnd(e))
		}

		this.manuallySetMediaItemVisibility()
		setTimeout(this.manuallySetMediaItemVisibility, 250)
	}

	manuallySetMediaItemVisibility = () =>{
		// media item is not getting visibility for some reason - possibility the dislocation between the media-item / spinner?
		// so we'll trigger it manually
		dispatch(this.props.baseNode, 'lazyLoadIntersectionChange', {
			visible: true,
			position: 'inside',
		}, {
			bubbles: false
		});

		dispatch(this.props.baseNode, 'viewportIntersectionChange', {
			visible: true,
			position: 'inside',
		}, {
			bubbles: false
		});
	}

	componentWillUnmount() {
		if ( this.flierRef.current) {
			this.flierRef.current.removeEventListener('animationend', (e) => this.onAnimationEnd(e))
		}
	}

	render(props, state){

		const { 
			speed, 
			rotation,
			scale,
			delay,
			adminMode 
		} = props;

		const { 
			currentAnimation,
			flightCount,
			calculatedAnimations
		} = state;

		return (
			<>
			<style>{`
				:host { 
					--rotate-transform: rotate(0deg) !important;
				}

				.flier {
					pointer-events: none !important;
					top: 0;
					left: 0;
					position: fixed;
					z-index:9999;
					transform: translateX(-100%) translateY(-100%);
					animation-delay: ${delay}s;
					animation-duration: ${this.calculateDuration(speed) / 4}s;
					animation-timing-function: linear;
					animation-direction: ${Math.sign(speed) === 1 ? 'normal' : 'reverse'};
				}

				.fly1 {
					animation-name: fly1;
				}

				.fly2 {
					animation-name: fly2;
				}

				.fly3 {
					animation-name: fly3;
				}

				.fly4 {
					animation-name: fly4;
				}

				.spinner {
					${Number(rotation) !== 0 ? `animation: spin ${this.calculateDuration(rotation)}s ${Math.sign(Number(rotation)) === -1 ? 'reverse' : 'normal'} linear infinite;` : ''}
					width: ${scale};
					height: ${scale};
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.spinner [part="media"] {
					pointer-events: none;
					border: none !important;
					padding: 0 !important;
				}

				.spinner figure {
					height: ${scale};
				}

				.spinner .sizing-frame {
					pointer-events: none;
					padding: unset;
				}

				.spinner img {
					object-fit: contain;
				}
			
				/* Keyframe values control where the element will begin
					and end its trajectory across the screen. Each rule
					represents a path the element follows across the screen. */
				
			
				@keyframes fly1 {
					${calculatedAnimations.fly1}
				}

				@keyframes fly2 {
					${calculatedAnimations.fly2}
				}

				@keyframes fly3 {
					${calculatedAnimations.fly3}
				}

				@keyframes fly4 {
					${calculatedAnimations.fly4}
				}

				@keyframes spin {
					0% {
						transform: rotateZ(0deg);
					}
					100% {
						transform: rotateZ(3600deg);
					}
				}
			`}</style>

			<div className={`flier ${currentAnimation}`} ref={this.flierRef}>
				<div className="spinner">
					{props.children}
				</div>
			</div>
		</>)
	}

}

FlyingObject.defaultProps = flyingObjectDefaults;

export default FlyingObject