import React, {useState} from 'react';

export const ContextMenuCheckbox = ({ label, value, onCheckboxChange, name, id, onCheckboxBlur, onPointerUp, icon, classList, ...otherProps }) => {

	const isChecked = value === true || (value === "on");

	// State from "passed" props in unique instances.
	const [hover, setHover] = useState(false);
	const [flickerOn, setFlickerOn] = useState(false);
	const [flickerOff, setFlickerOff] = useState(false);

	return (
		<label
			type={`checkbox`}
			htmlFor={id}
			checked-on={`${isChecked ? 'true': ''}`}
			className={`${hover ? 'hover' : '' }${flickerOn ? ' flicker-on' : '' }${flickerOff ? ' flicker-off' : '' }${classList ? ' '+classList : ''}`}
			onPointerEnter={() => setHover(true) }
			onPointerLeave={() => setHover(false) }
			onPointerUp={ (e) => {
				setFlickerOff(true)
				setTimeout(() => {
					setFlickerOn(true)
					setFlickerOff(false)
					setTimeout( ()=>{
						onPointerUp(e);
					}, 90)
				}, 90)
			}}
			{...otherProps}
		>
			<span className="before"></span>
			<div className="checkbox-label">
				{label}
			</div>
			<input
				type="checkbox"
				name={id}
				id={id}
				checked={isChecked}
				onBlur={onCheckboxBlur}
				readOnly
			/>
			<span className={`after${icon ? ' icon' : ''}`}>{icon}</span>
		</label>
	);
}
