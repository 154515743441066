import React, {Component, useRef, useEffect, useState, useLayoutEffect} from 'react';
import { deviceType } from "@cargo/common/helpers";
import _ from 'lodash';

export const MenuContext = React.createContext({
    children: null,
    innerUI: null,
    event: null,
    type: null,
    offset: null,
    onOpen: () => null,
    onConfirm: () => null,
    onClose: (e) => null,   
});

// DEBUG MODES:
// ctrl = right click passthrough
// Q+W+E ( hold ) = keep menu open / preserve current menu state for inspection

export const ContextMenu = (props) => {

    const isTouch = deviceType() === 'touch';

    let buttonOffset = {x: 7, y: 7};
    let mouseOffset =  {x: 10, y: 10};

    const menuDefaults = {
        children: null,
        innerUI: null, // internal UI component
        event: null,
        type: null, // mouse or button -- changes pos and pointer up logic
        offset: null, // manual px offset, passed as { x: --, y: -- }
        ignorePointerCoords: false,
        onOpen: () => null,
        onConfirm: () => null,
        onClose: (e) => null,
    }

    const initState = {
        menuActive: false,
        ...menuDefaults
    }

    const initPos = {
        align: 'top',
        top: 0,
        left: 0,
        visibility: 'hidden',
    }

    const initPointerUp = false;

    // State from "passed" props in unique instances.
    const [state, setState] = useState(initState);

    // Track and set window style to prevent scrolling, reset when menu closes.
    const [windowStyle, setWindowStyle] = useState(null);

    // Track last known pointer positioning.
    const [pointerCoords, setPointerCoords] = useState( {x: null, y: null} );

    // Track last button positioning
    const [buttonCoords, setButtonCoords] = useState( {rect: null} );

    // Track last button positioning
    const [staticOffset, setStaticOffset] = useState( {x: 0, y: 0} );

    // Set window position based on passed event.
    const [position, setPosition] = useState(initPos);

    // close on pointer up bool
    const [closeOnPointerUp, setCloseOnPointerUp] = useState( initPointerUp );

    // prevent close after context menu
    const [preventCloseAfterContext, setPreventCloseAfterContext  ] = useState( false );

    // prevent close after context menu
    const [preventEvents, setPreventEvents  ] = useState( false );

    // DETECT MULTI KEYPRESS FOR DEBUG MODE
    const [keysPressed, setKeyPressed] = useState(new Set([]));

    // Store the max height if the Menu UI is taller than the viewport
    const [maxMenuUIHeight, setMaxMenuUIHeight] = useState(null);

    function downHandler({ key }) {
        // Close menu on ESC if menu layer is in DOM
        if ( key === 'Escape' && document.querySelector('.context-menu-layer') ) {
            closeMenu(null)
        }

        setKeyPressed(keysPressed.add(key));
    }

    const upHandler = ({ key }) => {
        keysPressed.delete(key);
        setKeyPressed(keysPressed);
    };

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);

        };
    }, []);
    // DETECT MULTI KEY PRESS FOR DEBUG MODE


    useEffect(() => {

        if (state.menuActive === true) {

            let dimensions = getDimensions()
            let clientHeight = document.documentElement.clientHeight;
            if ((dimensions.y + 15 + dimensions.menuUIHeight) > clientHeight) {
                setMaxMenuUIHeight(clientHeight - dimensions.y - 15)
            }

            pauseGlobalEventExecution();

        } else {

            // This OFTEN happens after a timeout, which can interfere with alert modals.
            // resumeGlobalEventExecution();

        }

    }, [state.menuActive])

    const overlayRef = useRef();
    const menuRef = useRef();
    const menuUIRef = useRef();

    let timeOut = null;

    useLayoutEffect(() => {
        // Positions the menu after it is rendered.
        calcMenuPosition();
    }, [state])

    const openMenu = (opts) => {

        const options = _.defaults(opts, menuDefaults);

        if( isSuppressedDebug() ){ return }

        opts?.event.preventDefault()

        if( !options.holdFocus ){
            document?.activeElement?.blur();
        }

        timeOut = setTimeout(() => {
            setCloseOnPointerUp( true )
        }, 300);
        
        if ( options.onOpen ){
            options.onOpen();
        }

        if( opts?.offset ){
            setStaticOffset( opts?.offset );
        } else {
            setStaticOffset({x: 0, y:0 })
        }

        // If it's a click event, we should have some pointer coordiantes.
        // store them for positioning logic.
        if( opts?.event?.clientX && opts?.event?.clientY && opts?.type !== 'button' ){
            setPointerCoords({ x: opts?.event?.clientX, y:opts?.event?.clientY });
        }

        if( opts?.event?.target ){
            setButtonCoords({ rect: opts?.event?.target?.getBoundingClientRect() })
        }

        // if this was a button-invoked context menu
        if( opts?.event?.target && opts?.type == 'button' ){
            setPreventCloseAfterContext(true)
        }

        // store window style before preventing scroll
        const originalStyle = window.getComputedStyle(document.body).overflow;
        setWindowStyle( originalStyle );
        // Prevent scrolling on mount
        document.body.style.overflow = "hidden";

        setState({
            menuActive: true,
            ...options
        })

    }

    const closeMenu = (e) => {

        if( 
           e?.target.classList.contains('menu-ui') 
           || e?.target.nodeName == 'HR' 
           || e?.target.classList.contains('.prevent-release')
           || e?.target.closest('.prevent-release')
        ){
            return
        }

        // Debug dont close
        if( isHeldOpenDebug() ) { return }

        if ( state.onClose ){
            state.onClose(e);
        }

        clearTimeout( timeOut )
        setCloseOnPointerUp( false )
       
        if( e?.target.closest('.menu-ui') ){

            document.body.style.overflow = null;
            setPreventEvents( true )

            // Resume event execution before timeout.
            resumeGlobalEventExecution()

            setTimeout(() => {

                setState({
                    menuActive: false,
                    ...menuDefaults
                })

                 setPreventEvents( false );

                 resetControllerState()
            }, 180) // time corresponds to closing animations

        } else {

            document.body.style.overflow = null;

            // Resume event execution
            resumeGlobalEventExecution()

            setState({
                menuActive: false,
                ...menuDefaults
            })

            resetControllerState()
        }
    }

    const resetControllerState = () => {
        setWindowStyle(null)
        setPointerCoords({x: null, y: null})
        setButtonCoords({rect: null})
        setStaticOffset({x:0,y:0})
        setPosition({
            align: 'top',
            top: 0,
            left: 0,
            visibility: 'hidden',
        })
        setPreventEvents(false)
        setMaxMenuUIHeight(null)
    }

    const calcMenuPosition = () => {

        const clientWidth = document.documentElement.clientWidth,
        clientHeight      = document.documentElement.clientHeight;

        let menuWidth  = menuRef?.current?.offsetWidth, // Menu width
        menuHeight     = menuRef?.current?.offsetHeight, // Menu height
        align          = 'top',
        mouseX         = pointerCoords.x,
        mouseY         = pointerCoords.y ,
        offsetX        = mouseOffset.x, //horizontal offset from mouse
        offsetY        = -mouseOffset.y, //vertical offest from mouse
        x              = null,
        y              = null,
        buffer         = mouseOffset.x;

        x = mouseX + offsetX
        y = mouseY + offsetY

        // buttons open "fixed" to the button's location
        if( state.type == 'button' ){
            x = buttonCoords.rect.x + buttonOffset.x + staticOffset.x;
            y = buttonCoords.rect.y + buttonOffset.y + staticOffset.y;
        }

         // Open Left
        if( ( x + menuWidth > clientWidth - buffer ) || state.openLeft ){
            x = x - menuWidth - offsetX - mouseOffset.x; 
        }

        // Open above
        if( menuHeight + y > clientHeight - buffer ){
            align = 'bottom'
            y = mouseOffset.y;
        }

        // If at top of the viewport, open at the limit of the buffer range.
        if( y - buffer < buffer ){
            y = buffer
        }

        setPosition({align: align, x: x, y: y, width: menuWidth, height: menuHeight, visibility: 'visible'})

    }

    const getDimensions = () => {
        
        // This returns old data when called. Not sure why.
        const clientWidth = document.documentElement.clientWidth,
        clientHeight      = document.documentElement.clientHeight;

        let menuWidth  = menuRef?.current?.offsetWidth, // Menu width
        menuHeight     = menuRef?.current?.offsetHeight, // Menu height
        menuUIHeight   = menuUIRef?.current?.offsetHeight, // Inner menu UI height
        align          = 'top', // align top or bottom.
        mouseX         = pointerCoords.x,
        mouseY         = pointerCoords.y ,
        offsetX        = mouseOffset.x, //horizontal offset from mouse
        offsetY        = -mouseOffset.y, //vertical offest from mouse
        x              = null,
        y              = null,
        buffer         = mouseOffset.x;

        x = mouseX + offsetX
        y = mouseY + offsetY

        if( x + menuWidth > clientWidth - buffer ){
            // Open Left
            x = x - menuWidth; 
        }

        if( menuHeight + y > clientHeight - buffer ){
            // Open above
            // y = y - menuHeight;
            align = 'bottom'
            y = mouseOffset.y;
        }

        return { align: align, x: x, y: y, width: menuWidth, height: menuHeight, menuUIHeight: menuUIHeight }

    }


    const handleOverlayUp = (e) => {
        // Debug dont close
        if( isHeldOpenDebug() ) { return }

        if ( e.type == 'contextmenu' ){
            e.preventDefault();
        }

        if ( e.target == overlayRef.current && closeOnPointerUp ){
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            closeMenu(e);
        }

    }

    const pauseGlobalEventExecution = () => {

        if( window.__c3_admin__ === true ){

            window.store.dispatch({
                type: 'UPDATE_ADMIN_STATE', 
                payload: {
                    pauseGlobalEventExecution: true
                }
            }); 
        }

    }

    const resumeGlobalEventExecution = () => {

        if( window.__c3_admin__ === true ){

            window.store.dispatch({
                type: 'UPDATE_ADMIN_STATE', 
                payload: {
                    pauseGlobalEventExecution: false
                }
            }); 
        }

    }

    const isHeldOpenDebug = () => {
        // return  keysPressed.has('q') &&
        //         keysPressed.has('w') &&
        //         keysPressed.has('e');  
        return false;
    }

    const isSuppressedDebug = () => {
        // return  keysPressed.has('Control');
        return false;
    }

    const handlePointerUp = (e) => {
        
        // Debug dont close
        if( isHeldOpenDebug() ) { return }

        // Check if our pointer up is somewhere within the submenu label.
        var targetIsSubmenuLabel = e.target.classList.contains('sub-menu-label') ||
        ( e.target.classList.contains('before') && e.target.nextElementSibling?.classList.contains('sub-menu-label') )||
        ( e.target.classList.contains('after') && e.target.previousElementSibling?.classList.contains('sub-menu-label') ) 
        // If our pointer up is within a submenu label, turn back now.
        if( 'pointerup' == e.type && targetIsSubmenuLabel ){
            e.preventDefault();
            return
        }

        if( 'contextmenu' ==  e.type ){
            e.preventDefault()
            setPreventCloseAfterContext( true )
            return
        }

        if( 'pointerup' == e.type &&
            preventCloseAfterContext &&
            !e.target.closest('button') &&
            !e.target.closest('label') 
        ){
            setPreventCloseAfterContext( false )
            return
        }

        closeMenu(e);
    }

    let alignment = position.align;
    const styles = { [alignment] : position.y, left: position.x, visibility: position.visibility }

    return (
        <MenuContext.Provider value={{
                openMenu: openMenu,
                closeMenu: closeMenu,
                getDimensions: getDimensions
            }}>
            {state.menuActive === true ? (
               <div
                    className={`context-menu-layer`}
                    onPointerUp={(e)=>{ 
                        if( isTouch ){ return }
                        handleOverlayUp(e);
                    }}
                    onClick={(e)=> { 
                        if( isTouch ){
                            e.preventDefault();
                            handleOverlayUp(e);
                        }
                    }}
                    onContextMenu={(e)=>{ 
                        if( isTouch ){ return }
                        handleOverlayUp(e)
                    }}
                    ref={overlayRef}
                >
                    <div
                        className={`context-menu${preventEvents ? ' block-hover' : ''}`}
                        ref={ menuRef }
                        onPointerUp={ handlePointerUp }
                        onPointerDown={ e => {
                            // prevent losing focus in the content window
                            e.preventDefault();
                        }}
                        onMouseDown={ e => {
                            // prevent losing focus in the content window
                            e.preventDefault();
                        }}
                        onContextMenu={ handlePointerUp }
                        style={ styles }
                    >
                        <div 
                            className={`menu-background${maxMenuUIHeight ? ' clipped-height' : ''}`}
                            style={{height: maxMenuUIHeight}}
                        >
                            <div 
                                className={`menu-ui-body`}
                            >
                                <div 
                                    className={`menu-ui`} 
                                    ref={ menuUIRef }
                                >
                                    { state.innerUI ? (
                                        <>
                                            {React.cloneElement(state.innerUI, { controllerProps: { button : buttonCoords?.rect, pointer : pointerCoords }} )}
                                        </>
                                    ) :
                                    state.children }    
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            ) : null}
            { props.children }
        </MenuContext.Provider>
    )
        
}
