import { Component, createRef, render } from "preact";
import { createPortal } from 'preact/compat'

import _ from 'lodash';
import Slideshow from "../../../gallery/layouts/slideshow";

import { diffProps } from 'node_modules!preact/src/diff/props.js'

import { connect } from 'react-redux';
import selectors from "../../../../../selectors";
import { backdropSettings } from "@cargo/common";

import { getCSSValueAndUnit, isServer } from "@cargo/common/helpers";
import windowInfo from "../../../../window-info"
import { subscribe, unsubscribe, dispatch } from '../../../../../customEvents';

const defaultWallpaperModel = {
	hash: 'F1332270929626196865795426877515',
    id: 1234,
	name: 'scape.jpg',
    width: 4000,
    height: 2250,
    file_type: "jpg",
    mime_type: "image/jpg",
    is_image: true,
    is_video: false,
}

class Wallpaper extends Component {

	constructor(props){
		super(props);
		this.state = {
			isMobile: windowInfo.data.mobile.active,			
			loaded: false,
			showNavigation: true,
			navigationContainer: null,
			backdropShadowRoot: null,
			navigationElement: null,
		}

		this.containerRef = createRef();
		this.wallpaperRef = createRef();
		this.slideshowRef = createRef();
		this.navigationRef = createRef();
	}


	render(){

		const {
			showNavigation,
			navigationContainer,
			isMobile
		} = this.state;

		const {
			modelArray,
			singleModel,
			visibility,
			wrapperDimensions
		} = this.props;

		const settings = {};

		for (const [key, defaultEntry] of Object.entries(backdropSettings.wallpaper.default.defaults)) {
			
			if(this.props.settings?.hasOwnProperty(key)){
				settings[key] = this.props.settings[key]
			} else {
				settings[key] = defaultEntry.value;
			}
		}

		let marginSize = getCSSValueAndUnit(settings.margin, '%');
		let remSize = '';
		if(marginSize[1]=== '%'){
			marginSize[0] = Math.min(this.props.dimensions.width, this.props.dimensions.height) * parseFloat(marginSize[0])*.01;
			marginSize[1] = 'px';
		} else if ( marginSize[1].includes('rem') && isMobile ) {
			marginSize[0] = 'calc( var(--mobile-padding-offset, 1) * ' + marginSize[0] + 'rem )';
			marginSize[1] = ''
		}

		let heightLimit;

		if( marginSize[1]==='px' ){
			heightLimit = (this.props.dimensions.height+.5 - marginSize[0]*2) +'px';
		} else if (marginSize[0]?.indexOf?.('calc(') > -1) {
			heightLimit = `calc( ${this.props.dimensions.height+.5}px - ( 2 *  ( ${marginSize[0].replace('calc(', '') } ) )`;
		} else {
			heightLimit = `calc( ${this.props.dimensions.height+.5}px - ${marginSize[0]*2 + marginSize[1]} )`;
		}

		const imageFit = settings['image-fit']  == 'fit';

		const slideshowAttributes = {
			'autoplay': settings.autoplay,
			'transition-speed': settings['transition-speed'],			
			'transition-type': settings['transition-type'],
			'autoplay-delay': settings['autoplay-delay'],
			'pause-on-hover': false,
			'limit-by': 'height',
			'shuffle': settings['shuffle'],
			'navigation': false,
			'scale': heightLimit,
			'show-captions': false,
			'slideshowLoad': this.onFirstLoad,
			'afterSlideChange': this.onAfterSlideChange,

		}


		let alignments = this.props.settings.alignments || {}
		let alignmentStyle = ''
		for (const [key, position] of Object.entries(alignments)) {	
			alignmentStyle+= ` media-item[hash="${key}"] { --position-image: ${position.x}% ${position.y}% }`
		}

		let wallpaperContent =null;
		let navigation = null;

		if( modelArray.length > 0 ){

			navigation= settings['navigation'] && settings['cycle-images'] && modelArray.length > 1 ? (<>
				<div className={`backdrop ${this.props.settings.clipScroll ? 'clip':''}` }>
					<div className="wallpaper-slideshow" ref={this.navigationRef}>
						{this.state.navigationElement ? createPortal(<>
							<style>{`
[part="slideshow-nav"] {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 

    pointer-events: none;
    position: absolute;
    inset: 0;
    z-index: 1001;
    

    opacity: ${this.state.loaded && showNavigation ? '1': '0'};

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}


							`}</style>
							<div
								part="slideshow-nav"
							>
								<div
									part="slideshow-nav-previous-button"
									onClick={this.prevSlide}					
								>
									<svg part="slideshow-nav-prev" class="left-arrow" x="0px" y="0px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;">
										<rect part="slideshow-nav-background" width="36" height="36" rx="36"/>
										<path part="slideshow-nav-arrow" class="arrow" d="M22.3,28l-10-10l10-10"/>
									</svg>
								</div>
								<div
									part="slideshow-nav-next-button"
									onClick={this.nextSlide}
								>
									<svg part="slideshow-nav-next" class="next" x="0px" y="0px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;">
										<rect part="slideshow-nav-background" width="36" height="36" rx="36"/>
										<path part="slideshow-nav-arrow" class="arrow" d="M22.3,28l-10-10l10-10"/>
									</svg>
								</div>
							</div>
						</>, this.state.navigationElement.shadowRoot): null}

					</div>
				</div>
				<div className="page-layout">
					<div className="page-content">
						
					</div>				
				</div>				
				</>
			
			) : null;

			wallpaperContent = settings['cycle-images'] && modelArray.length > 1 ? (
				<gallery-slideshow
					{...slideshowAttributes}
					className="image-container multiple-item"
					ref={this.slideshowRef}
				>
					{
						modelArray.length < 2 ? <>
							<media-item
								disable-zoom={true}
								key="slide-1"
								hash="placeholder"
								part="media-item" 
								exportparts="media, sizing-frame, placeholder"
							/>
							<media-item
								disable-zoom={true}
								key="slide-2"
								hash="placeholder"
								part="media-item" 
								exportparts="media, sizing-frame, placeholder"
							/>
						</> : modelArray.map((model,index)=>{
							return <media-item
								image-fit={imageFit ? 'fit' : 'fill'}
								disable-zoom={true}
								key={'slide-'+index}
								hash={model.hash}
								muted="true"
								autoplay={model.is_video}
								loop={true}
								part="media-item" 
								exportparts="media, sizing-frame, placeholder"
							/>
						})
					}
				</gallery-slideshow>
			) : (
				<div
					className="image-container single-item"
				>
					<media-item
	
						image-fit={imageFit ? 'fit' : 'fill'}
						disable-zoom={true}
						hash={singleModel?.hash || 'placeholder'}
						model={singleModel}
						part="media-item" 
						muted="true"
						autoplay={singleModel.is_video}	
						loop="true"					
						exportparts="media, sizing-frame, placeholder, frame"
						itemLoad={this.onFirstLoad}
						limit-by={imageFit ? "height": "width"}
						scale={imageFit ? heightLimit : '100%'}
					/>
				</div>
			)
		}

		return <div className="wallpaper" ref={this.wallpaperRef} exportparts="media-item, media, placeholder">
			{this.state.backdropShadowRoot && createPortal(<>
				<style>
					{`:host {
						background-color: ${settings['bg-color'] || 'none'};
					    position: absolute;
					    inset: 0;
					    
					    transform: translate3d(0,0,0);
					    opacity: ${this.state.loaded ? 1: 0};
					    transition: opacity 0.5s ease-in-out;

					}

					gallery-slideshow media-item figcaption.caption {
					    display: none!important;
					}

					gallery-slideshow media-item::part(sizing-frame) {
					    margin: auto 0;
					    flex-grow: 0;
					}

					gallery-slideshow media-item::part(frame) {
					    display: flex;
					    flex-wrap: wrap;
					    width: ${imageFit ? 'var(--item-width)' : '100%' }; 
					    height: var(--slide-height);
					    align-content: center;
					    align-self: center;
					}					

					.inner {
					    position: absolute;
					    inset: ${marginSize.join('')};
					}

					.image-container {
						opacity: ${settings.opacity};
						inset: 0;
					    width: 100%;
					    height: 100%;
					    position: absolute;
					    text-align: center;
					    z-index: 2;
					}

					.image-container.single-item {
						display: flex;
						align-content: center;
						justify-content: center;
						position: absolute; 
						margin: auto;
						inset: 0;
					}

					.image-container.single-item media-item {
						margin: auto;
						max-width: auto;
					}

					.image-container.single-item media-item::part(figure){
						height: 100%;
					}				

					gallery-slideshow, media-item {
						transform: translateX(0)!important;
					}

			

					${imageFit ? `


						`: `			

						media-item::part(sizing-frame),
						media-item::part(frame) {
						    margin: 0;
						    width: 100%;
						    height: ${heightLimit};
						    pointer-events:none;
						    display: block;
						    padding: 0;
						}		

						media-item::part(media),
						media-item::part(video) {
						    object-position: var(--position-image, center center);                  
						    pointer-events: auto;
						    display: block;
						    margin: 0;
						    width: 100%;
						    height: 100%;
						    position: absolute;					    
						    top: 0;
						    left: 0;
						}						
					`}


					${alignmentStyle}`}
				</style>
				{navigation && navigationContainer && createPortal(navigation, navigationContainer)}
				<div className="overlay"/>
				<div className="inner" ref={this.containerRef}>
					{wallpaperContent}	
				</div>
			</>, this.state.backdropShadowRoot)}
		</div>
	}

	prevSlide = (e)=>{
		this.hideNavigation.cancel();
		e?.preventDefault();
		this.slideshowRef.current?.prevSlide();
	}
	nextSlide = (e)=>{
		e?.preventDefault();
		this.hideNavigation.cancel();
		this.slideshowRef.current?.nextSlide();
	}	

	onPointerMove =(e)=>{

		if( !this.state.showNavigation ){
			this.setState({
				showNavigation: true
			})
		}

		if( e && e.target && this.state.navigationContainer?.contains(e.target)){
			this.hideNavigation.cancel();
		} else {
			this.hideNavigation();			
		}

	}

	hideNavigation = _.debounce(()=>{
		this.setState({
			showNavigation: false
		})
	}, 1500)

	onMobileChange = (isMobile)=>{
		this.setState({isMobile})
	}

	componentDidMount(){
		windowInfo.on('mobile-change', this.onMobileChange)

		if( this.wallpaperRef.current && !this.wallpaperRef.current.shadowRoot ){
			this.wallpaperRef.current.attachShadow({mode: 'open'})
		}

		this.setState({
			backdropShadowRoot:this.wallpaperRef.current?.shadowRoot
		})

		window.addEventListener('pointermove', this.onPointerMove)

		if( this.props.backdropRef.current ){

			const target = document.createElement('div');
			target.classList.add('wallpaper-navigation');
			let closestPage = this.props.backdropRef.current.closest('.page');
			closestPage.appendChild(target);
			this.setState({
				navigationContainer: target,
			})			
		}

		// setTimeout to allow for react shadow root to render
		setTimeout(()=>{
			this.setMediaElementProperties();
		}, 100);
	}

	componentWillUnmount(){
		windowInfo.off('mobile-change', this.onMobileChange)

		if( this.state.navigationContainer){
			this.state.navigationContainer.remove();
		}
		window.removeEventListener('pointermove', this.onPointerMove)
		this.hideNavigation.cancel();		
	}

	componentDidUpdate(prevProps, prevState){

		if(
			this.props.settings.navigation !== prevProps.settings.navigation ||
			this.props.settings['cycle-images'] !== prevProps.settings['cycle-images']
		){
			this.onPointerMove();			
		}

		if(
			this.props.settings.activeImage !== prevProps.settings.activeImage &&
			this.props.settings['cycle-images'] &&
			this.slideshowRef.current
		){
			const activeMediaItem = this.slideshowRef.current.querySelector('[hash="'+this.props.settings.activeImage+'"]');

			if( activeMediaItem){
				const activeIndex = Array.from(this.slideshowRef.current.children).indexOf(activeMediaItem);
				this.slideshowRef.current.goToSlide(activeIndex)
			}
			
		}

		if(
			this.props.visibility.visible &&
			this.containerRef.current &&
			(
				(prevProps.visibility.visible !== this.props.visibility.visible) ||
				(this.props.settings['cycle-images'] !== prevProps.settings['cycle-images'] )
			)
			
		){
			this.setMediaElementProperties();	
		}


		if( this.navigationRef.current !== this.state.navigationElement){

			if( this.navigationRef.current && !this.navigationRef.current.shadowRoot){
				this.navigationRef.current.attachShadow({mode: 'open'})
			}
			this.setState({
				navigationElement: this.navigationRef.current
			})
		}

	}

	setMediaElementProperties=()=>{
		const mediaItemsInWallpaper = Array.from(this.containerRef.current.querySelectorAll('media-item'));
		mediaItemsInWallpaper.forEach((mediaItem)=>{
				
			dispatch(mediaItem, 'lazyLoadIntersectionChange', {
				visible: true,
				position: 'inside',
			}, {
				bubbles: false
			});

			dispatch(mediaItem, 'viewportIntersectionChange', {
				visible: true,
				position: 'inside',
			}, {
				bubbles: false
			})

		})		
	}

	onFirstLoad=(e)=>{

		// send these events out, just in case the admin is listening
		if(this.slideshowRef.current){
			const activeItem = this.slideshowRef.current.getActiveSlide()
			this.wallpaperRef.current.dispatchEvent(new CustomEvent('afterSlideChange', {
				detail: {
					slideIndex: activeItem[0],
					mediaItem: activeItem[1]
				},
				composed: false,
				bubbles: false
			}));			
		}		

		if( this.state.loaded ){
			return
		}
		this.setState({
			loaded: true,
			showNavigation: true,
		})
		
		if(this.props.onLoad){
			this.props.onLoad();
		}

	}

	onAfterSlideChange= (slideIndex, mediaItem)=>{

		this.wallpaperRef.current.dispatchEvent(new CustomEvent('afterSlideChange', {
			detail: {
				slideIndex,
				mediaItem
			},
			composed: false,
			bubbles: false
		}));

	}

}

export default connect(
	(state, ownProps) => {
		let modelArray = selectors.getMediaByParent(state)[ownProps.id];
		modelArray = modelArray.filter(model=> !model.loading && !model.is_deleted);
		let singleModel = null;
		let activeImageHash = ownProps.settings?.activeImage || null;

		if( activeImageHash=== defaultWallpaperModel.hash){

			singleModel = defaultWallpaperModel
			modelArray.push(singleModel);

		} else {
			// if there's an active image, find it in the image model
			if( activeImageHash ){
				singleModel = modelArray.find(model=>model.hash == activeImageHash);
			}

			// if the active image can't be found, look for it in the image array
			if (!singleModel){
				singleModel = modelArray[0];
			}			
		}

		// then, filter the array by the exclusion array
		if( ownProps.settings.excluded ){

			modelArray = modelArray.filter(model=>ownProps.settings.excluded.indexOf(model.hash) == -1);

			// after the exclusion pass, there might only be one image left
			// set the single model to that image so that it can display as a single image
			if(modelArray.length == 1 && ownProps.settings['cycle-images'] ){
				singleModel = modelArray[0];
			}			
		}

		// order the images according to the 'order' array.
		// images without an order will be placed first (as they are likely to be recently-uploaded images)

		if( ownProps.settings.order ){
			modelArray.sort(function(a,b){				
				return ownProps.settings.order.indexOf(a.hash) < ownProps.settings.order.indexOf(b.hash) ? -1 : 1
			});
		}

		return {
			singleModel,
			modelArray
		}		
	}
)(
	Wallpaper
);
