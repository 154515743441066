// React/Preact requirements
import render from 'preact-render-to-string';
import { Provider } from 'react-redux';
import { StaticRouter, matchPath } from 'react-router';
import { Frontload, frontloadServerRender } from 'react-frontload';
// Our store, entrypoint, and manifest
import createStore from '../store';
import App from '../components/app';
import { actions } from '../actions';
import { paths } from '../routes';
import axios from 'axios';

axios.interceptors.request.use(function (config) {

	if(config.url) {

		// make sure URLs are always properly encoded, otherwise
		// we can run into ERR_UNESCAPED_CHARACTERS exceptions
		config.url = encodeURI(
			// decode first to prevent double encoding of URLs that 
			// contain parts that have been encoded previously
			decodeURI(config.url)
		);

	}

	return config;
});


const preloadedStateReplacer = (key, value) => {
	if (
		key === 'crdt_state'
		|| key === 'auth'
		|| key === 'editors'
		|| key === 'serverRenderedFontCSS'
	) {
		return undefined
	}

	return value
};

const applyPreloadedState = (baseHtml, state) => {
	// Determine if a specific page is to be rendered
	let activePageOrSet = undefined;
	if (
		state.frontendState.activePID
		&& state.frontendState.activePID !== state.site.homepage_id
		&& state.frontendState.activePID !== state.site.mobile_homepage_id
		&& state.frontendState.activePID !== 'root'
	) {
		if (state.pages.byId[state.frontendState.activePID]) {
			activePageOrSet = state.pages.byId[state.frontendState.activePID];
		} else if (state.sets.byId[state.frontendState.activePID]) {
			activePageOrSet = state.sets.byId[state.frontendState.activePID];
		}
	}

	// Determine public or private
	let privateAccess = false;
	if (
		(activePageOrSet && activePageOrSet.access_level === 'password')
		|| state.site.access_level === 'private'
		|| state.site.access_level === 'password'
	) {
		privateAccess = true;
	}

	// Set title to site, page-specific, or default
	let title
	if (state.site.website_title) {
		const pageTitle = (activePageOrSet?.title) ? `${activePageOrSet.title} — ` : '';
		title = `${pageTitle}${state.site.website_title}`;
	} else {
		title = baseHtml.match(/<title>(?<title>.*?)<\/title>/)?.groups?.title || '';
	}

	// server has no way of authing requests, let frontend handle loading of
	// content that is not set to public
	Object.keys(state.pages.byId).forEach(key => {
		if(state.pages.byId[key].access_level !== 'public') {
			delete state.pages.byId[key];
		}
	});

	Object.keys(state.sets.byId).forEach(key => {
		if(state.sets.byId[key].access_level !== 'public') {
			delete state.sets.byId[key];
		}
	});

	// Replace title and apply preloaded state to base HTML
	const preloadedState = JSON.stringify(state, preloadedStateReplacer).replace(/</g, '\\u003c');
	const metaNoIndex = (privateAccess) ? '<meta name="robots" content="noindex">' : '';
	const statefulHtml = baseHtml.replace(/<title>.*?<\/title>/, () => {
		return `
			<title>${title}</title>
			<script>window.__PRELOADED_STATE__=${preloadedState}</script>
			${metaNoIndex}`;
	});

	return {
		privateAccess: privateAccess,
		statefulHtml: statefulHtml,
		title: title
	};
};

const applyBody = (baseHtml, body) => {
	return baseHtml.replace(/<body.*?>/, (match) => {
		return `${match}${body}`;
	})
};

const applyHead = (html, title, state, url) => {
	let favicon = '';
	let social = '';
	let serverRenderedFontStyles = '';

	if (state.site.favicon_url) {
		favicon = `<link rel="icon" href="${state.site.favicon_url}" sizes="any">`;
	}

	if (state.site.has_site_description || state.site.site_preview_type !== 'none') {
		let site_preview_url = state.site.site_preview_url || '';

		// if this URL belongs to a page with a thumbnail, use that as the social image
		const pageRouteMatch = matchPath(url, {
			path: paths.PAGE_PATH,
			exact: true,
			strict: false
		});
		if (pageRouteMatch) {
			// @TODO: Revisit page discerning logic, ensure toLowerCase works for all URL cases
			let renderedPage;
			const lowerCaseUrl = pageRouteMatch.params.page?.toLowerCase();
			if (lowerCaseUrl) {
				for (const id of Object.keys(state.pages.byId)) {
					if (state.pages.byId[id].purl?.toLowerCase() === lowerCaseUrl) {
						renderedPage = state.pages.byId[id];
						break;
					}
				}
			}

			if (renderedPage && renderedPage.thumb_media_id) {
				const thumbnailModel = renderedPage.media?.find(image => image.id === renderedPage.thumb_media_id);

				// Only use if image is >= 200 x 200px as per facebook minimums
				if (thumbnailModel && thumbnailModel.width > 200 && thumbnailModel.height > 200) {
					site_preview_url = `https://freight.cargo.site/w/${Math.min(1200, thumbnailModel.width)}/i/${thumbnailModel.hash}/${thumbnailModel.name}`;
				}
			}
		}

		social = `
			<meta name="twitter:card" content="summary_large_image">
			<meta name="twitter:title" content="${title}">
			<meta name="twitter:description" content="${state.site.site_description}">
			<meta name="twitter:image" content="${site_preview_url}">
			<meta property="og:locale" content="en_US">
			<meta property="og:title" content="${title}">
			<meta property="og:description" content="${state.site.site_description}">
			<meta property="og:url" content="${state.site.direct_link}">
			<meta property="og:image" content="${site_preview_url}">
			<meta property="og:type" content="website">`;
	}

	if (state.frontendState.serverRenderedFontCSS) {
		serverRenderedFontStyles = state.frontendState.serverRenderedFontCSS.map(css => `<style>${css}</style>`).join('\n')
	}

	return html.replace(/<\/head>/, () => {
		return `
			${favicon}
			${state.site.meta_tags || ''}
			${social}
			${serverRenderedFontStyles}
			</head>`;
	});
};

const getStatusCodeForFrontendState = (state, url) => {
	if (state.frontendState.networkErrors.length < 1) {
		return 200;
	}

	// Frontend URLs do not begin with a '/', so for ease of matching we drop the url's slash
	const errorUrl = url.substring(1);

	let lastErrorCode;
	for (const error of state.frontendState.networkErrors) {
		if (error.id && error.id === errorUrl) {
			lastErrorCode = error.code || 500;
			break;
		}

		lastErrorCode = error.code;
	}

	// Can't avoid a 404 for a tag URL without a corresponding page URL; check if that's the case here and let it slide
	if (lastErrorCode === 404 && state.site.tags.length > 0) {
		for (const tag of state.site.tags) {
			if (tag.url === errorUrl) {
				return 200;
			}
		}
	}

	return lastErrorCode || 500;
};

export const ssr = async (hostname, url, indexHtml) => {
	const context = {};
	const { store } = createStore();

	// Set the current site
	store.dispatch(actions.updateFrontendState({
		hostname: hostname
	}));

	const body = await frontloadServerRender(() => {
		try {
			return render(
				<Provider store={store}>
					<StaticRouter location={url} context={context}>
						<Frontload isServer={true}>
							<App />
						</Frontload>
					</StaticRouter>
				</Provider>
			);
		} catch (error) {
			return error;
		}
	}, { maxNestedFrontloadComponents: 10 });

	if (typeof body !== 'string') {
		// HTTP 101 Switching Protocols, switch to uncached, full client-side rendering
		const error = (body instanceof Error) ? body : undefined;
		return { statusCode: 101, html: undefined, error: error };
	}

	const state = store.getState();
	const statusCode = getStatusCodeForFrontendState(state, url);
	if (statusCode !== 200) {
		return { statusCode: statusCode, html: undefined };
	}

	const { privateAccess, statefulHtml, title } = applyPreloadedState(indexHtml, state);
	if (privateAccess) {
		// Password protected and private sites fall back to cached, partial client-side rendering
		return { statusCode: 200, html: statefulHtml };
	}

	const renderedHeadHtml = applyHead(statefulHtml, title, state, url);
	return { statusCode: statusCode, html: applyBody(renderedHeadHtml, body) };
};
