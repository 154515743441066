import { Component , cloneElement, toChildArray, render, createRef} from "preact";
import _ from 'lodash';
import GalleryEditor from "./gallery-editor"
import { editorOverlayAPI } from "./editor-overlay-controller"
import { ADMIN_FRAME } from "../../globals";


class FreeformEditor extends Component {
	constructor(props){
		super(props);
		this.state = {
			pauseRealignment: false,
			waitingForRealignment: false,

			currentZoom: 100,
			currentHorizontalShift: 0,

			transform: {
				x: 0,
				y: 0,
			},
			previewShifts: [],
			previewingTransform: false,

			dragStartPosition: {
				x: 0,
				y: 0,
			},
			scrollDelta: {
				x: 0,
				y: 0
			},
			dragDelta: {
				x: 0,
				y: 0,
			},
			initialPosition: {
				x: 0,
				y: 0,
			},
			ghostRect: {
				significantShift: false,
				width: 0,
				height: 0,
				x: 0,
				y: 0,
			},

			minHeight: 0,
			overrideHeight: false,
			dragSrcIndex: -1,

			UIWindow: false,
			draggedItemIndex: -1,
			isDragging: false,

			showDropTarget: false,
			breakOutOfGallery: false,
			draggedMediaItem: null,
			timer: 0,
		}

		this.draggedItem = createRef();
		this.dragGhostRef = createRef();
		this.lastPosition = null;

	}

	render(props, state){

		const {
			showDropTarget,
			draggedMediaItem,
			isDragging,
			dragDelta,
			draggedItemIndex,
			initialPosition,
			dragSrcIndex,
			ghostRect,
		} = state;

		const {
			galleryInstance,
		} = props;

		return <>
			<style>{dragSrcIndex > -1 && isDragging ? `
			::slotted(media-item:nth-child(${dragSrcIndex+1})){
				pointer-events:none;
				opacity: 0;
			}

			.drag-ghost {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1001;	
				pointer-events: none;
				outline: 1px solid #ccc;
			}

			.drag-ghost.snapping {
				outline: 1px solid var(--baseColor-accent);
			}

			.drag-ghost img {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				opacity: 0.5;
				transition: none;
				object-fit: cover;
				transform:translateZ(0);
			}

			`: `

			#freeform-holder {
				transform: scale(${this.state.currentZoom}%) translateX(${this.state.currentHorizontalShift}%);
				transform-origin: center top;
			}

			`}{this.state.overrideHeight ? `
				:host {
					min-height: ${this.state.minHeight}px;
				}
			`: ``}{
				this.state.previewingTransform ? this.state.previewShifts.map(transform=>{
					return `::slotted(media-item[slot="${transform.slot}"]){
						--base-translate: translate(${transform.x}px , ${transform.y}px)!important;
					} `

				}) : ''

			}</style>
			<GalleryEditor
				{...props}
				gallerySpecificAttributes={this.props.gallerySpecificAttributes}			
				galleryInstance={galleryInstance}
				mediaItemEditorInfo={{
					disableResize: false,
					disableButton: false,
					afterScaleChange: this.afterScaleChange,
					whileScaleChange: this.whileScaling,
					beforeScaleChange: this.beforeScaleChange,
				}}
				onScrollWhileHovered={this.onScrollWhileHovered}
				onUIWindowSet={this.getUIWindow}
				onUIWindowRelease={this.releaseUIWindow}
				disableDragOver={true}
				onCleanUp={this.onCleanUp}								
			>
			</GalleryEditor>
			<div
				className={`drag-ghost ${ghostRect.significantShift? 'snapping': ''}`} 
				style={{
					display: isDragging && ghostRect.width > 0 && ghostRect.height > 0 ? 'block': 'none',
					transform: `translate3d(${ghostRect.x}px, ${ghostRect.y}px, 1001px)`,
					width: ghostRect.width+'px',
					height: ghostRect.height+'px',
				}}
				dangerouslySetInnerHTML={{
					__html: ''
				}}
				ref={this.dragGhostRef}
			></div>
		</>
	}

	onScrollWhileHovered =(scrollDelta)=>{
		
		this.setState((prevState)=>{

			if( !prevState.isDragging){
				return null;
			}

			return {
				scrollDelta: {
					x: 0,
					y: prevState.scrollDelta.y + scrollDelta
				}
			}
		})
	
	}

	getUIWindow = (UIWindow)=>{

		// init these values into the scrubbers when the ui window is loaded
		this.setState({UIWindow})
	}
	releaseUIWindow = (UIWindow)=>{
		this.setState({
			UIWindow: false,
		})
	}

	beforeScaleChange = (mediaItem, resizeDimensions)=>{

		let initialScale = mediaItem.hasAttribute('freeform-scale') ? mediaItem.getAttribute('freeform-scale') : 20;

		return {
			...resizeDimensions,
			scaleArray: [parseFloat(initialScale), '%'],
			scale: initialScale+''
		}
	}

	whileScaling = (mediaItem, resizeDimensions)=>{
		let {
			magneticSnap,
			magneticSnapMargin
		} = this.props

		const size = mediaItem._size || {
			width: 0,
			height: 0,
			mediaSize: {
				width: 0,
				height: 0,
				padSize: 0,
			},
			mediaItemSize: {
				width: 0,
				height: 0,
				padSize: 0,
			}					
		};
		if( !magneticSnap){
			return resizeDimensions;
		}

		let {
			width, height, scaleArray, overSize, atLimit, scale
		} = resizeDimensions;

		let {
			elWidth,
			attributeMap
		} = this.props;

		elWidth = elWidth +- size.mediaItemSize.padSize + -size.mediaSize.padSize;

		const magnetPxMargin = magneticSnapMargin*.01 * (elWidth)
		let imageRatio = width/height;

		let snapDimensions = {
			width: false,
			height: false,
		};

		let indexOf = Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==='MEDIA-ITEM').indexOf(mediaItem);

		let attribs = attributeMap[indexOf];


		let resizeRect = {
			x1: attribs.xPx,
			y1: attribs.yPx,
			x2: attribs.xPx+width,
			y2: attribs.yPx+height,
		};	

		const ignoreEdges = {
			top: true,
			left: true,
			right: false,
			bottom: false,
		}

		const snapShift = this.calculateMagnetism(mediaItem, resizeRect, ignoreEdges);


		if(
			Math.abs(snapShift.x) < Math.abs(snapShift.y) &&
			Math.abs(snapShift.x) > .1
		){
			snapDimensions.width = true;
		} else if (
			Math.abs(snapShift.y) > .1	
		){
			snapDimensions.height = true;
		}

		if( Math.abs(snapShift.x) > 15 ){
			snapDimensions.width = false;
			snapShift.x = 0;
		}
		if( Math.abs(snapShift.y) > 15 ){
			snapDimensions.height = false;
			snapShift.y = 0;
		}			
			
		if( snapDimensions.width ){
			let newWidth = width+snapShift.x;
			width = newWidth;
			height = width/imageRatio;

		} else if(snapDimensions.height) {
			let newHeight = height+snapShift.y;
			height = newHeight;
			width = height*imageRatio;
		}

		scaleArray[0] = (width/elWidth)*100

		return {
			...resizeDimensions,
			scale: scaleArray.join(''),
			width,
			height,
			scaleArray,
			overSize,
			atLimit,
		} 

	}

	afterScaleChange = (mediaItem, resizeDimensions)=>{

		let {
			elWidth
		} = this.props;

		const size = mediaItem._size || {
			width: 0,
			height: 0,
			mediaSize: {
				width: 0,
				height: 0,
				padSize: 0,
			},
			mediaItemSize: {
				width: 0,
				height: 0,
				padSize: 0,
			}					
		};

		elWidth = elWidth +- size.mediaItemSize.padSize + -size.mediaSize.padSize;

		const width= (100* (resizeDimensions.width / elWidth)).toFixed(2);
		mediaItem.setAttribute('limit-by', 'width');
		mediaItem.setAttribute('scale', width);

		mediaItem.setAttribute('freeform-scale', width);
		this.props.galleryComponent.onItemResize();		
	}

	onDragStart = (editor, e, dragData)=>{

		const draggedItem = dragData.draggedMediaItems?.find(el=>this.props.galleryInstance.contains(el)) || null;

		if( !draggedItem) {
			this.setState({
				draggedItemIsInsideGallery:false,
			})
			return	
		}

		const {
			attributeMap,
			elWidth
		} = this.props;

		this.draggedItem.current = draggedItem;

		Array.from(this.dragGhostRef.current?.children).forEach(child=>{
			child.remove();
		})

		const media = this.draggedItem.current?.shadowRoot?.querySelector('.sizing-frame [part="media"]')
		if( media ){

			// commenting out - let this be a red outline for now
			// this.dragGhostRef.current.appendChild(media.cloneNode(true));
		}
		
		const childArray = Array.from(this.props.galleryInstance.children);
		const dragSrcIndex = childArray.indexOf(draggedItem);
		const indexOf = childArray.filter(el=>el.tagName==='MEDIA-ITEM').indexOf(draggedItem);
		const attribs = attributeMap[indexOf];

		// void the native ghost image
		// commented out because it does not work on safari
		if ( e ){
			// ADMIN_FRAME.globalDragEventController.setCustomDragImage(e, {el: null});	
		}

	
		let initialPosition = {
			x: attribs.xPx,
			y: attribs.yPx
		}

		let minHeight = this.props.galleryInstance.offsetHeight;

		this.setState((prevState)=>{
			ADMIN_FRAME.globalDragEventController.setCustomDropTarget(this.props.galleryInstance)
			return{
				dragDelta: {
					x: 0,
					y: 0,
				},
				draggedItemIsInsideGallery:true,
				initialPosition,
				dragStartPosition: {
					x: dragData.cursorPosition.x,
					y: dragData.cursorPosition.y
				},
				scrollDelta: {
					x: 0,
					y: 0
				},
				ghostRect:{
					x: 0,
					y: 0,
					width: 0,
					height: 0
				},
				dragSrcIndex,
				overrideHeight: true,
				minHeight: minHeight,
				isDragging: true,
			}
		});				

	}



	onDragOver = (editor, e, dragData)=>{

		if(this.ticking){
			return;
		}

		this.ticking =true;
		this.dragFrame = requestAnimationFrame(()=>{
			this.ticking = false;
		})

		const {
			elWidth,
			elHeight,
			mediaItems,
			magneticSnapMargin,
			magneticSnap,
			attributeArray
		} = this.props;

		const draggingMediaItems = dragData.draggedMediaItems.length > 0;
		const isDraggingOverGallery = this.props.galleryInstance.contains(dragData.dropTarget);
		const customDropTargetIsAlreadyGallery = this.props.galleryInstance === dragData.customDropTarget;

		if( dragData.inAdmin  || dragData.fromOutside || !(isDraggingOverGallery || this.state.draggedItemIsInsideGallery) ){
			ADMIN_FRAME.globalDragEventController.releaseCustomDropTarget(this.props.galleryInstance)
		} else {
			this.setState((prevState)=>{

				ADMIN_FRAME.globalDragEventController.setCustomDropTarget(this.props.galleryInstance)				

				if( !prevState.isDragging ){

					let galleryRect = this.props.galleryInstance.getBoundingClientRect();

					let initialPosition = {
						x: dragData.cursorPosition.x - galleryRect.left,
						y: dragData.cursorPosition.y - galleryRect.top
					}

					this.draggedItem.current = dragData.draggedMediaItems[0];
					return{
						dragDelta: {
							x: 0,
							y: 0,
						},
						scrollDelta: {
							x: 0,
							y: 0,
						},
						initialPosition,
						dragStartPosition: {
							x: dragData.cursorPosition.x,
							y: dragData.cursorPosition.y
						},
						ghostRect:{
							x:0,
							y: 0,
							width: 0,
							height: 0
						},
						isDragging: true,
						dragSrcIndex: -1
					}					
				} else {

					const dragDelta = {
						x: dragData.cursorPosition.x + -prevState.dragStartPosition.x + prevState.scrollDelta.x,
						y: dragData.cursorPosition.y + -prevState.dragStartPosition.y + prevState.scrollDelta.y,
					}

					let nextPosition = {
						x: prevState.initialPosition.x + dragDelta.x,
						y: prevState.initialPosition.y + dragDelta.y
					}

					if( !this.lastPosition ){
						this.lastPosition = nextPosition;
					}

					let draggedRect = {
						width: 0,
						height: 0,
						x1: nextPosition.x,
						x2: nextPosition.x,
						y1: nextPosition.y,
						y2: nextPosition.y,
					}

					
					if( this.draggedItem.current ){
						const size = this.draggedItem.current._size || {
							width: 0,
							height: 0,
							mediaSize: {
								width: 0,
								height: 0,
								padSize: 0,
							},
							mediaItemSize: {
								width: 0,
								height: 0,
								padSize: 0,
							}					
						};

						draggedRect.x2 = draggedRect.x1 + size.mediaItemSize.padSize + size.mediaSize.padSize + size.mediaItemSize.width
						draggedRect.y2 = draggedRect.y1 + size.mediaItemSize.padSize + size.mediaItemSize.height
						draggedRect.width = size.mediaItemSize.padSize + size.mediaSize.padSize + size.mediaItemSize.width;
						draggedRect.height = size.mediaItemSize.padSize + size.mediaItemSize.height;
					}

					const snapShift = magneticSnap ? this.calculateMagnetism(this.draggedItem.current, draggedRect) : {x: 0, y: 0}
					const magnetPxMargin = magneticSnapMargin*.01 * elWidth

					if( Math.abs(snapShift.x) > Math.max(magnetPxMargin, 6)){
						snapShift.x = 0;
					} else if ( snapShift.x !== 0){
						nextPosition.x = (nextPosition.x + snapShift.x)*.5 + this.lastPosition.x*.5
					}

					if( Math.abs(snapShift.y) > Math.max(magnetPxMargin, 6)){
						snapShift.y = 0;
					} else if (snapShift.y !== 0){
						nextPosition.y = (nextPosition.y + snapShift.y)*.5 + this.lastPosition.y*.5
					}

					const significantShift = Math.abs(snapShift.x) > 1 || Math.abs(snapShift.y) > 1;



					this.lastPosition = nextPosition;
					return {
						ghostRect:{
							significantShift,
							x: nextPosition.x,
							y: nextPosition.y,
							width: draggedRect.width,
							height: draggedRect.height
						},
						dragDelta: {
							x: dragDelta.x + snapShift.x,
							y: dragDelta.y + snapShift.y
						}
					};
				}

			});

		}
	}


	calculateMagnetism = (draggedEl, draggedRect, ignoreEdges ={top: false, right: false, bottom: false, left: false})=>{
		const {
			mediaItems,
			galleryInstance,
			elWidth,
			elHeight,
			magneticSnapMargin,
			attributeMap
		} = this.props;

		const magnetPxMargin = Math.round(magneticSnapMargin*.01 * elWidth * 32)/32;

		const verticalValues = [];
		const horizontalValues = [];
		let tmp =[]
		let rect = {}

		mediaItems.forEach((el, index)=>{

			const attribs = attributeMap[index];

			// if the key is the item being dragged - don't compare item against itself
			if(el === draggedEl){
				return;
			}

			tmp.length = 0;

			const size = el._size;

			rect.x1 = attribs.xPx;
			rect.y1 =  attribs.yPx;

			rect.x2 = size.mediaItemSize.width+size.mediaItemSize.padSize+rect.x1;
			rect.y2 = size.mediaItemSize.height+size.mediaItemSize.padSize+rect.y1;

			if(
				draggedRect.x1 < rect.x2+magnetPxMargin*2 &&
				draggedRect.x2 > rect.x1-magnetPxMargin*2 
			) {

				// if it's in margin but not directly above or below... add with no margin
				if (
					rect.x2 < draggedRect.x1 ||
					rect.x1 > draggedRect.x2
				){
					tmp.push( rect.y1 );
					tmp.push( rect.y2 );
				} else{
					tmp.push( rect.y1-magnetPxMargin );
					tmp.push( rect.y2+magnetPxMargin );
				}

				tmp.forEach(val=>{
					if( verticalValues.indexOf(val) === -1 ){
						verticalValues.push(val)
					}
				})

			}

			tmp.length = 0;

			if(
				draggedRect.y1 < rect.y2+magnetPxMargin*2 &&
				draggedRect.y2 > rect.y1-magnetPxMargin*2
			) {

				if (
					rect.y2 < draggedRect.y1 ||
					rect.y1 > draggedRect.y2
				){
					tmp.push( rect.x1 );
					tmp.push( rect.x2 );

				} else{
					tmp.push( rect.x1-magnetPxMargin );
					tmp.push( rect.x2+magnetPxMargin );
				}

				tmp.forEach(val=>{
					if( horizontalValues.indexOf(val) === -1 ){
						horizontalValues.push(val)
					}
				})
			}			
		
		})

		if( verticalValues.indexOf(0) === -1 ){
			verticalValues.push(0)
		}

		if( verticalValues.indexOf(elHeight) === -1 ){
			verticalValues.push(elHeight)
		}

		if( horizontalValues.indexOf(0) === -1 ){
			horizontalValues.push(0)
		}

		if( horizontalValues.indexOf(elWidth) === -1 ){
			horizontalValues.push(elWidth)
		}

		const closestLineToTop = _.minBy(verticalValues, val=> Math.abs(val-(draggedRect.y1)));
		const closestLineToBottom = _.minBy(verticalValues, val=> Math.abs(val-(draggedRect.y2)));
		const distanceClosestLineToTop = closestLineToTop-(draggedRect.y1);
		const distanceClosestLineToBottom = closestLineToBottom-(draggedRect.y2);
		
		const closestLineToLeft = _.minBy(horizontalValues, val=> Math.abs(val-(draggedRect.x1)));
		const closestLineToRight = _.minBy(horizontalValues, val=> Math.abs(val-(draggedRect.x2)));
		const distanceClosestLineToLeft = closestLineToLeft-(draggedRect.x1);
		const distanceClosestLineToRight = closestLineToRight-(draggedRect.x2);

		const snapShift = {
			x: _.minBy([
				(ignoreEdges.left ? 9e9 : distanceClosestLineToLeft),
				(ignoreEdges.right ? 9e9 : distanceClosestLineToRight)
			], val=> val !== 0 ? Math.abs(val) : 9e9 ),
			y: _.minBy([
				(ignoreEdges.top ? 9e9 : distanceClosestLineToTop),
				(ignoreEdges.bottom ? 9e9 : distanceClosestLineToBottom)
			], val=> val !== 0 ? Math.abs(val) : 9e9 ),
		}

		return snapShift;
	}

	onDragDrop=(editor, e, dragData)=>{

		// set isDragging false first so that the transformation goes through
		this.setState({
			isDragging: false,
		}, ()=>{
			this.lastPosition = null;

			if( !dragData.inAdmin && dragData.customDropTarget === this.props.galleryInstance && CargoEditor?.mutationManager){
				CargoEditor.rangy.getSelection().removeAllRanges();
				editorOverlayAPI.deactivateHoverElement(this.props.galleryInstance);

				CargoEditor.mutationManager.execute(()=>{

					const mediaItems = Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==="MEDIA-ITEM");
					let itemsSortedByY = _.sortBy(mediaItems, (el)=>{
						return parseFloat(el.getAttribute('freeform-y')) || 0
					});

					const freeformX = ((this.state.initialPosition.x + this.state.dragDelta.x)/ this.props.elWidth)*100;
					const freeformY = ((this.state.initialPosition.y + this.state.dragDelta.y)/ this.props.elWidth)*100;
					const freeformScale = this.draggedItem.current?.getAttribute('freeform-scale') || 20;

					const insertBeforeTarget = itemsSortedByY.find(el=>parseFloat(el.getAttribute('freeform-y')) > freeformY );

					dragData.draggedMediaItems.forEach((mediaItem, i)=>{
						mediaItem.setAttribute('freeform-z', mediaItems.length+200 + i);
						if( mediaItem === this.draggedItem.current ){
							mediaItem.setAttribute('freeform-x', freeformX);
							mediaItem.setAttribute('freeform-y', freeformY);
						} else {
							mediaItem.setAttribute('freeform-x', (freeformX+i*5)%80 );
							mediaItem.setAttribute('freeform-y', freeformY+i*5);
						}

						if ( !mediaItem.hasAttribute('freeform-scale') ){
							mediaItem.setAttribute('freeform-scale', freeformScale);
						}

						this.props.galleryInstance.insertBefore(mediaItem, insertBeforeTarget);		
						mediaItem.flushMutationQueue();

					})


					this.stackZ();
					this.centerY();
					this.alignDomWithY();

					this.props.galleryInstance._editorInterface.flushMutationQueue();


				});
			
			}

		})

		this.unsetMinHeight();		

	}

	unsetMinHeight = _.debounce(()=>{
		this.setState({
			overrideHeight: false,
		})
	}, 100);

	onDragEnd =(editor, e, dragData)=>{
		this.setState({
			isDragging: false,
		}, ()=>{

			this.lastPosition = null;			
		})
		this.unsetMinHeight();
	}

	onCleanUp = (addedMediaItems, removedMediaItems, changedMediaItems, characterDataChanged)=>{
		if (CargoEditor && Object.keys(CargoEditor).length > 0 && (!_.isEqual(addedMediaItems, removedMediaItems) || changedMediaItems.length > 0 )){

			CargoEditor.mutationManager.execute(()=>{
				this.centerY();				
				this.alignDomWithY();	
			});
		}
		if( this.props.onCleanUp){
			this.props.onCleanUp(addedMediaItems, removedMediaItems, changedMediaItems, characterDataChanged)
		}

	}


	// after it's been appended, re-collect the gallery children and then use it to rewrite the z and y properties
	stackZ = ()=>{
		const mediaItems = Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==="MEDIA-ITEM");
		const itemsSortedByZ = _.sortBy(mediaItems, (el)=>{
			return parseFloat(el.getAttribute('freeform-z')) || 0
		});

		itemsSortedByZ.forEach((el, index)=>{
			el.setAttribute('freeform-z', index+1);
			el.flushMutationQueue();
		})						
	}

	centerY = ()=>{

		const mediaItems = Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==="MEDIA-ITEM");
		const itemsSortedByY = _.sortBy(mediaItems, (el)=>{
			return el.hasAttribute('freeform-y') ? parseFloat(el.getAttribute('freeform-y')) : 0
		});

		const minY = itemsSortedByY[0]?.hasAttribute('freeform-y') ? parseFloat(itemsSortedByY[0].getAttribute('freeform-y')) : 0;

		if( minY !== 0 ){
			itemsSortedByY.forEach((el, index)=>{
				const curY = el.hasAttribute('freeform-y') ? parseFloat(el.getAttribute('freeform-y')) : minY
				el.setAttribute('freeform-y', curY - minY);
				el.flushMutationQueue();
			})							
		}						
	}

	alignDomWithY = ()=>{

		const mediaItems = Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==="MEDIA-ITEM");
		const itemsSortedByY = _.sortBy(mediaItems, (el)=>{
			return el.hasAttribute('freeform-y') ? parseFloat(el.getAttribute('freeform-y')) : 0
		});	


		// function boiled down from preact's placeChild algorithm
		let oldDom = mediaItems[0];
		itemsSortedByY.forEach((el, index, thisArray)=>{

			if (
				el != oldDom 
			) {
		        for (
		            let sibDom = oldDom, j = 0;
		            (sibDom = sibDom.nextElementSibling) && j < thisArray.length;
		            j += 1
		        ) {
		            if (sibDom == el) {
		                break;
		            }
		        }

		        this.props.galleryInstance.insertBefore(el, oldDom);
			} else {
				oldDom = el.nextElementSibling;							
			}
		    el.flushMutationQueue();
		})


	}

	zoom = (val)=>{

		this.setState({
			currentZoom: val
		})
	}

	commitZoom = ()=>{
		const {
			magneticSnapMargin,
			attributeMap,
		} = this.props
		const {
			currentZoom
		} = this.state;


		Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==='MEDIA-ITEM').forEach((el,index)=>{
			const attribs = attributeMap[index];
			let x = attribs['freeform-x'];
			let y = attribs['freeform-y'];
			let scale = attribs['freeform-scale'];

			const dist = Math.sqrt(Math.pow(x - 50, 2) + Math.pow(y, 2));
			const angle = Math.atan2((y), (x-50));
			
			el.setAttribute('freeform-x', Math.cos(angle)*dist*(currentZoom*.01)+50);
			el.setAttribute('freeform-y', Math.sin(angle)*dist*(currentZoom*.01)+50*(currentZoom*.01));
			el.setAttribute('freeform-scale', (currentZoom*.01) * scale);
		})

		let newSnapMargin = Math.abs(currentZoom*.01*magneticSnapMargin);

		if (newSnapMargin > 0.1){	
			while (newSnapMargin >5 ){
				newSnapMargin*=0.5
			}
		}

		this.centerY();

		this.state.UIWindow?.onChange?.({
			zoom: 100,
			'magnetic-snap-margin': newSnapMargin
		})			

		this.props.galleryInstance._editorInterface.flushMutationQueue();		
		this.props.galleryComponent.onItemResize();	

	}

	previewTransform = (transform, elements)=>{

		const previewShifts = elements.map(el=>{
			const x = ((transform.x + parseFloat(el.getAttribute('freeform-x') || 0) ) * this.props.elWidth *.01)
			const y = ((transform.y + parseFloat(el.getAttribute('freeform-y') || 0) ) * this.props.elWidth *.01)

			return {
				slot: el.getAttribute('slot'),
				x,
				y
			}
		});
		this.setState({
			transform,
			previewShifts,
			previewingTransform: true,
		});

	}

	commitTransform = ()=>{

		CargoEditor.mutationManager.execute(()=>{

			this.state.previewShifts.forEach(shift=>{
				const el = this.props.galleryInstance.querySelector('[slot="'+shift.slot+'"]')
				if( el ){

					const curX = parseFloat(el.getAttribute('freeform-x') || 0);
					const curY = parseFloat(el.getAttribute('freeform-y') || 0);
	
					el.setAttribute('freeform-x', curX + this.state.transform.x );
					el.setAttribute('freeform-y', curY + this.state.transform.y );
				}

			});

			this.centerY();
			this.alignDomWithY();

			this.props.galleryInstance._editorInterface.flushMutationQueue();

		});

		const imageOptionsUIWindow = Array.from(this.props.galleryInstance.children).find(el=> el._editorInterface)?._editorInterface?.state?.UIWindow ||null;

		if( imageOptionsUIWindow ){
			imageOptionsUIWindow.onChange({
				'shift-x': 0,
				'shift-y': 0,
			})
		}	

		this.setState({
			transform: {
				x: 0,
				y: 0,
			},
			previewShifts: [],
			previewingTransform: false,
		}, ()=>{
			this.props.galleryInstance._editorInterface.flushMutationQueue();
			this.props.galleryComponent.onItemResize();				
		})
		
	}

	horizontalShift = (val)=>{
		this.setState({
			currentHorizontalShift: val
		})
	}


	commitHorizontalShift = ()=>{
		const {
			currentHorizontalShift
		} = this.state;

		const {
			attributeMap
		} = this.props;

		Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==='MEDIA-ITEM').forEach((el,index)=>{
			let attribs = attributeMap[index];
			let x = attribs['freeform-x'];
			el.setAttribute('freeform-x', x + currentHorizontalShift);

		})

		this.state.UIWindow?.onChange?.({
			horizontalShift: 0,
		})
		this.props.galleryComponent.onItemResize();			

	}


	fitToWidth=()=>{

		const {
			magneticSnapMargin,
			attributeMap
		} = this.props;

		CargoEditor.mutationManager.execute(()=>{

			const {
				elWidth,
				attributeMap
			} = this.props;

			const mediaItems = Array.from(this.props.galleryInstance.children).filter(el=>el.tagName==='MEDIA-ITEM');

			let leftEdge = 9e9;
			let rightEdge = -9e9;
			mediaItems.forEach((el, index)=>{
				const attribs = attributeMap[index];			
				leftEdge = Math.min( leftEdge, attribs['freeform-x'] )
				rightEdge = Math.max( rightEdge, attribs['freeform-x']  + attribs['freeform-scale'] )
			})

			const scaleShift = 100 / (rightEdge - leftEdge);


			const xMovePercent = leftEdge
		
			let newSnapMargin = Math.abs(scaleShift* magneticSnapMargin);

			if (newSnapMargin > 0.1){	
				while (newSnapMargin >5 ){
					newSnapMargin*=0.5
				}
			}

			mediaItems.forEach((el, index)=>{
				const attribs = attributeMap[index];			

				let curScale = attribs['freeform-scale'] || 30;
				let curX = attribs['freeform-x'];
				let curY = attribs['freeform-y'];

				let newX = (curX +-xMovePercent)*scaleShift;
				let newY = curY*scaleShift;

				el.setAttribute('freeform-x', newX);
				el.setAttribute('freeform-y', newY);			
				el.setAttribute('freeform-scale', scaleShift*curScale);

			})

			this.state.UIWindow?.onChange?.({
				zoom: 100,
				'magnetic-snap-margin': newSnapMargin
			})

		});		
	}


	componentDidMount(){

		ADMIN_FRAME.globalDragEventController.on('dragstart', this.onDragStart)
		ADMIN_FRAME.globalDragEventController.on('dragover', this.onDragOver)
		ADMIN_FRAME.globalDragEventController.on('drop', this.onDragDrop)
		ADMIN_FRAME.globalDragEventController.on('dragend', this.onDragEnd)

		this.props.galleryInstance._freeformEditor = this;
	}

	componentWillUnmount(){

		this.unsetMinHeight.cancel();
		cancelAnimationFrame(this.dragFrame);
		clearTimeout(this.breakOutTimer);	

		delete this.props.galleryInstance._freeformEditor;


		ADMIN_FRAME.globalDragEventController.off('dragstart', this.onDragStart)
		ADMIN_FRAME.globalDragEventController.off('dragover', this.onDragOver)
		ADMIN_FRAME.globalDragEventController.off('drop', this.onDragDrop)
		ADMIN_FRAME.globalDragEventController.off('dragend', this.onDragEnd)
	}

	componentDidUpdate(prevProps, prevState){

		if(
			prevState.pauseRealignment !== this.state.pauseRealignment &&
			!this.state.pauseRealignment &&
			this.state.waitingForRealignment
		){
			// this.realignContents();
		}
	}

}



export default FreeformEditor