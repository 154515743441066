import { Component } from "preact";
import axios from 'axios';
import { API_ORIGIN } from "@cargo/common";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, actionTypes } from '../../actions';
import _ from 'lodash';

class Password extends Component {

	constructor(props) {

		super(props);

		this.state = {
			error: false
		}

	}

	render(props){
		
		return (
			<>
			<style>
				{`
					.site-password,
					.page-password {
						text-align: center;
						margin: auto;
						height: 0;
						width: 350px;
						display: table;
					}

					.site-password {
						position: fixed;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}

					.error {
					    transform: translate(0, 20px);
					    position: absolute;
					    width: 100%;
					    text-align: center;
					    left: 0;
					    color: rgba(0,0,0,0.75);
					    user-select: none;
					}

					input[type="password"] {
						border-radius: 100px;
					    border: 1px solid rgba(0,0,0,0.75);
					    padding: 10px 20px;
					    font-size: 14px;
					    font-family: Helvetica;
					    font-size: 18px;
					    font-style: normal;
					    font-weight: 400;
					    line-height: 21px;
					    letter-spacing: -0.004em;
					    text-align: left;
						width: 340px;
					}

					input[type="password"]:placeholder {
		                color: rgba(0,0,0,0.75);
		            }
		            input[type="password"]::-webkit-input-placeholder {
		                color: rgba(0,0,0,0.75);
		            }
		            input[type="password"]::-moz-placeholder {
		                color: rgba(0,0,0,0.75);
		            }
		            input[type="password"]:-moz-placeholder {
		                color: rgba(0,0,0,0.75);
		            }
	            `}
			</style>

			<div className={this.props.target === "site" ? 'site-password' : 'page-password'}>

				<form onSubmit={this.submit}>
					<input 
						name="pw" 
						type="password"
						placeholder="Enter password..." 
						autofocus="true" 
						onKeyDown={this.onKeyDown}
					/>
				</form>

				{this.state.error && (
					<span class="error">Invalid password</span>
				)}

			</div>
			</>
		)
	}

	onKeyDown = e => {
		if (this.state.error) {
			this.setState({
				error: false
			});
		}
	}

	submit = e => {

		e.preventDefault();

		let PATH;

		// unlock the site
		if(this.props.target === 'site') {
			PATH = `/sites/${this.props.siteId}/validatepassword`;
		} else {
			PATH = `/pages/${this.props.siteId}/id/${this.props.target}/validatepassword`;
		}


		axios.post(API_ORIGIN + PATH, {
			[this.props.target === 'site' ? 'site_password' : 'password']: e.target.elements.pw.value
		}).then(({data: result}) => {

			if(!result.token) {
				this.setState({
					error: true
				});

				return;
			}

			this.setState({
				error: false
			});

			this.props.setAuthentication({
				authenticated: true,
				data: {
					access_token: result.token,
					id: null
				}
			});

			this.props.onSucces?.();

		}).catch((e) => {

			console.error(e)
			
			this.setState({
				error: true
			});

		})

	}

}

export default connect(
	(state, ownProps) => ({
		siteId: state.site.id
	}),
	dispatch => bindActionCreators({
		setAuthentication: actions.setAuthentication
	}, dispatch)
)(
	Password
)
