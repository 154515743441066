import React, {Component, useState, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

import _ from 'lodash';

import './tooltip.scss';

let previouslyShownTooltipCount = 0;

export const TooltipContext = React.createContext({
    innerText: null,
    shortcutText: null,
    icon: null,
    onOpen: () => null,
    onClose: () => null,   
});


export const ToolTip = (props) => {

    const initState = {
        innerText: null,
        shortcutText: null,
        icon: null,
        buttonPos: null,
        customOffsetX: null,
        customOffsetY: null,
        customZIndex: null,
        positionType: null,
        delay: null,
        preventTooltipRoll: null,
        onOpen: () => null,
        onClose: () => null,  
    }
    // State from "passed" props in unique instances.
    const [state, setState] = useState(initState);

    // openTip > set visible > set dimensions > set position 

    const [position, setPosition]     = useState({ x: 0, y: 0 })
    const [visible, setVisible]       = useState('none')
    const [config, setConfig]         = useState({arrowSide: 'right', arrowJustify: 'center'})
    const [dimensions, setDimensions] = useState({height: 0, width: 0})

    const toolTipRef = useCallback(node => {
        if (node !== null) {
            setDimensions({ height: node.offsetHeight, width: node.offsetWidth });
        } else {
            setDimensions({ height: null, width: null})
        }
    }, [visible]);

    let timeOut = null;

    const openTip = (opts) => {

        const options = _.defaults(opts);

        setState({ 
            innerText     : options.innerText,
            shortcutText  : options.shortcutText,
            icon          : options.icon,
            buttonPos     : options.buttonPos,
            customOffsetX : options?.buttonPos?.customOffsetX,
            customOffsetY : options?.buttonPos?.customOffsetY,
            customZIndex  : options?.customZIndex,
            positionType  : options?.positionType,
            delay         : options?.delay,
            preventTooltipRoll : options?.preventTooltipRoll
        })

        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

        if ( options.buttonPos ) {

            setVisible('measure')
            // setToolTipPosition(toolTipEl, window, options.buttonPos )
            // This has been replaced by useEffect. ^ 
        }

        if ( state.onOpen){
            state.onOpen();
        }


    }

    // Positions tooltip in the browser
    // requires tooltip dimensions, and a relative button position
    useEffect(() => {

        if( state.buttonPos === null ){ return }
        if( dimensions.height === null ){ return }

        if( state.positionType === 'mouse' ){
            setPosition({x: state.buttonPos.x, y: state.buttonPos.y});
            return
        }

        let toolTipWidth  = dimensions.width,
            toolTipHeight = dimensions.height,
            viewWidth     = window.document.documentElement.clientWidth,
            viewHeight    = window.document.documentElement.clientHeight,
            buttonX       = state.buttonPos.x,
            buttonY       = state.buttonPos.y,
            x             = null,
            y             = null,
            rowHeight     = Math.min(state.buttonPos.height, state.buttonPos.width),
            arrowBuffer   = 10;
            // arrowBuffer   = 6;

            if( viewWidth - buttonX <= rowHeight ){

                let toolTipCenter = toolTipHeight / 2;
                let buttonCenter = buttonY + ( rowHeight / 2 );

                // open left of button
                // x = rowHeight + arrowBuffer;
                x = viewWidth - rowHeight - toolTipWidth - arrowBuffer;
                y = buttonCenter - toolTipCenter;

                setConfig({
                    arrowSide: 'right',
                    arrowJustify: 'center'
                })

                if( buttonX + rowHeight == viewWidth && buttonY == 0 ){
                    y = ( rowHeight + arrowBuffer ); 
                    x = viewWidth - ( rowHeight + arrowBuffer + toolTipWidth );

                    setConfig({
                        arrowSide: 'right',
                        arrowJustify: 'corner'
                    })
                }

                if( toolTipHeight >= rowHeight ){
                    // Aligns tooltip to 33% instead of 50% to match arrow
                    // Should only happen with tooltips exceeding 41px in height, with a right facing arrow. 
                    y = buttonCenter - ( toolTipHeight / 3 );

                    setConfig({
                        arrowSide: 'right',
                        arrowJustify: 'top'
                    })
                }

            } else if( buttonY < rowHeight ){

                let toolTipCenter = toolTipWidth / 2;
                let buttonCenter = buttonX + ( state.buttonPos.width / 2 )
                // Open below the button
                x = buttonCenter - toolTipCenter;
                y = ( rowHeight + arrowBuffer ); 

                if( x <= 0 ){

                    x = arrowBuffer;

                    setConfig({
                        arrowSide: '',
                        arrowJustify: 'left'
                    })

                } else if ( ( toolTipWidth + buttonX > viewWidth - rowHeight ) && buttonY == 0 ) { 

                    x = viewWidth - ( rowHeight + arrowBuffer + toolTipWidth );
                    // top right corner
                    setConfig({
                        arrowSide: 'top',
                        arrowJustify: 'right-side'
                    })  

                } else {

                    // if( !this.props.isFirstChild ){
                        setConfig({
                            arrowSide: 'top',
                            arrowJustify: 'center'
                        })  
                    
                }

            } else if( buttonY > rowHeight && ( buttonY + rowHeight !== viewWidth ) ){

                // open left 
                // let toolTipCenter = toolTipHeight / 2;
                // let roundedButtonY = ( Math.floor( buttonY / 10 ) * 10 )
                // let buttonCenter = roundedButtonY + ( rowHeight / 2 )
                // x = buttonX - ( toolTipWidth + arrowBuffer )
                // y = buttonCenter - toolTipCenter

                // Open under
                let toolTipCenter = toolTipWidth / 2;
                let buttonCenter = buttonX + ( state.buttonPos.width / 2 )

                x = buttonCenter - toolTipCenter;
                y = buttonY + rowHeight + arrowBuffer;

                setConfig({
                    arrowSide: 'right',
                    arrowJustify: 'left'
                })
            }
            setPosition({x: x, y: y})

    }, [dimensions])

    // Manages delay between hover and showing tool tip.
    // Shows tool tip instantly if a tip is already visible
    // Else will wait a full second
    useEffect(() => {

        let delay = state.delay ? state.delay : 1000;

        if( previouslyShownTooltipCount > 0 && !state.preventTooltipRoll ) {
            delay = 0;
        }

        if( dimensions.width === null ){
            delay = null;
        }

        let toolTipTimeout = setTimeout(() => {
            if( dimensions.width === null ){
                setVisible('none')
            } else {
                setVisible('visible')
            }
        }, delay);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
          clearTimeout(toolTipTimeout);
        };

    },[dimensions]);


    const closeTip = (e) =>{

        if( visible === 'visible') {
            previouslyShownTooltipCount++;
            timeOut = setTimeout(() => {
                previouslyShownTooltipCount--;
            }, 300);
        }

       setVisible('none')

        if ( state.onClose){
            state.onClose();
        }

    }

    let positionStyles = { top: position.y, left: position.x }

    if( state.customOffsetX ){
        positionStyles.left = positionStyles.left + state.customOffsetX 
    }

    if( state.customOffsetY ){
        positionStyles.top = positionStyles.top + state.customOffsetY
    }

    if( state.customZIndex ){
        positionStyles.zIndex = state.customZIndex;
    }

    return (
        <TooltipContext.Provider value={{
                openTip : openTip,
                closeTip: closeTip,
            }}>
            <>
                { visible !== 'none' && state.innerText !== '' && state.innerText  ? (
                    <div 
                        className={`tool-tip ${visible} ${config.arrowSide} ${config.arrowJustify}`}
                        style={ positionStyles }
                        ref={ toolTipRef }
                    >
                        <div className="tooltip-content">
                            <span dangerouslySetInnerHTML={{ __html: state.innerText }}></span>
                            <div className="shortcut">{ state.shortcutText }</div>        
                        </div>
                    </div> 
                ) : (null)}
                { props.children }
            </>
        </TooltipContext.Provider>
    )
        
}
