import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { deviceType } from "@cargo/common/helpers";
import { helpers } from '@cargo/common';
import _ from "lodash";

let currentDeviceType = deviceType();

const defaultFrontendState = {
	hasSiteModel: false,
	hasScaffolding: false,
	hasSiteDesign: false,
	hasSiteCSS: false,
	hasShopModel: false,
	hasSitePackage: false,
	quickView: {
		mode: 'default',
		inited: false,
		autoScrolling: false,
		elementArray: false,
		activeIndex: 0,
		startingIndex: 0,
	},
	contactForm: { 
		transition: false,
		inited: false
	},
	// when we're the admin frame the default mode is adminMode enabled. Only
	// when in preview this will be set to false
	adminMode: helpers.isAdminEdit,
	// just to check and see if we're contained by the admin or standalone
	inAdminFrame: helpers.isAdminEdit,
	renderedPages: [],
	networkErrors: [],
	fontsLoaded: [],
	deviceType: currentDeviceType
}

export default function(state = defaultFrontendState, action) {

	if(!action.payload) {
		return state;
	}

	let newRenderedPages;

	switch(action.type) {
		
		case actionTypes.UPDATE_FRONTEND_STATE: 

			return _.mergeWith({}, defaultFrontendState, state, action.payload, (objValue, srcValue, key, object, source) => {

				// a value was explicitly set to undefined. Delete it from the new object
				if(srcValue === undefined && source.hasOwnProperty(key)) {
					delete object[key];
				}

			});

		case actionTypes.FETCH_SITE_PACKAGE_FULFILLED: 

			return {
				...state,
				hasSiteModel: true,
				hasScaffolding: true,
				hasSiteDesign: true,
				hasSiteCSS: true,
				hasSitePackage: true,
			}

		case actionTypes.FETCH_SCAFFOLDING_FULFILLED: 

			return {
				...state,
				hasScaffolding: true
			}

		case actionTypes.FETCH_SITE_MODEL_FULFILLED: 

			return {
				...state,
				hasSiteModel: true
			}

		case actionTypes.FETCH_SHOP_MODEL_FULFILLED: 

			return {
				...state,
				hasShopModel: true
			}

		case actionTypes.FETCH_SITE_CSS_FULFILLED: 

			return {
				...state,
				hasSiteCSS: true
			}

		case actionTypes.FETCH_SITEDESIGN_MODEL_FULFILLED: 

			return {
				...state,
				hasSiteDesign: true
			};
		
		case actionTypes.PAGES_MOUNTED: 

			newRenderedPages = [...state.renderedPages, ...action.payload.pages];

			return {
				...state,
				renderedPages: newRenderedPages
			};


		case actionTypes.PAGES_UNMOUNTED: 

			return {
				...state,
				renderedPages: _.difference(state.renderedPages, action.payload.pages)
			};

		case actionTypes.FETCH_CONTENT_REJECTED: 

			return {
				...state,
				networkErrors: [
					...state.networkErrors, 
					{
						...action.payload.config?.contentIdentifier, 
						code: action.payload.response?.status
					}
				]
			}

	}

	return state;

}