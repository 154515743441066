import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Field, Form } from 'formik';
import FormikChangeListener from "../basic-formik-change-listener";
import { API_ORIGIN, HOMEPAGE_ORIGIN } from "@cargo/common";
import axios from "axios";
import cargoFetch from '@cargo/fetch';
import _ from 'lodash';
import { MenuContext, TooltipContext } from "@cargo/common";
import EditorsContextUI from "./editors-context-ui";
import { UserRow } from './editor-user-row';

import { AutofillInput } from "@cargo/ui-kit/autofill-input/autofill-input";
import { Button } from "@cargo/ui-kit/button/button";
import { MoreActions } from "@cargo/ui-kit/more-actions/more-actions";
import { ProcessingAnimation } from "@cargo/ui-kit/processing/processing";


class EditorsWindowClass extends Component {

	constructor(props) {

		super(props);

		let defaultAccess = parseInt( window.store.getState().auth.data.id ) === 1 ? 'Admin' : 'Editor';

		this.state = {
			newAccess: defaultAccess,
			inputError: false,
			sendingInvite: false,
			pendingUsers: [],
		}

		this.sendingInvite = false;

		this.interfaceRef = React.createRef();
	}

	setInvitePermissions = ( user, setting ) => {
		this.setState({
			newAccess: setting
		})

		window.requestAnimationFrame(()=>{
			let input = document.querySelector('.uiWindow form .user.editable .text-input input[type="text"]');
		    let inputLength = input.value.length
		    if( input && inputLength !== undefined ){
		        input.focus();
		        input.setSelectionRange(inputLength, inputLength+1)
		    }
			
		})
	}

	inviteUser = (e, props) => {

		if( !this.sendingInvite ){

			this.sendingInvite = true;

			let validated = true;
			let values = props.values;

			if( values.email === '' || values.email.length <= 0 ){
				validated = false;
			}

			_.each( props.editors, (editor)=> {
				if( editor.email === values.email ){
					validated = false;
					this.setState({inputError: true})
				}
			})

			if( validated ){

				this.setState({
					sendingInvite: true 
				}, ()=> {

					(cargoFetch.hasAuthorization() ? cargoFetch : axios).post(`${API_ORIGIN}/sites/${this.props.siteId}/invite/send`, 
						{
							email: values.email, 
							role: this.props.siteVersion !== 'Cargo2' ? this.state.newAccess : 'Admin'
						}, 
						{use_auth: true}
					).then(response => {

						this.setState({pendingUsers: [...this.state.pendingUsers, {email: values.email, id: 'pending', role: 'Pending'}]})
						props.setFieldValue('email', '')
						this.sendingInvite = false;

					}).catch((err) => {

						this.setState({inputError: true})
						this.sendingInvite = false;

						// support both axios & cargofetch errors
						if( err?.message?.includes('422') || err?.status === 422 ) { return };

						// axios & cargofetch return different error formats
						const data = err.response?.data || err.data;

						let message = _.values(data?.errors)?.[0]?.[0] || 'Something went wrong';

						this.props.alert.openModal({
							header: message,
							type: 'notice',
							HotKeyProxy: this.props.hotkeys,
							ignoreUnmount: true,
							onConfirm: (options) => {
							}
						});				

					}).finally(() => {

						this.setState({
							sendingInvite: false 
						});
						this.sendingInvite = false;

					})

				});


			} else {
				this.setState({ sendingInvite: false })
				this.sendingInvite = false;
			}
		}

	}

	removeUser = (user) => {
		// /v1/permissions/{userId}/site/{siteId}
		(cargoFetch.hasAuthorization() ? cargoFetch : axios).delete(`${API_ORIGIN}/permissions/${user.id}/site/${this.props.siteId}`, { use_auth: true } ).then((res) => {

			// Only refetch if you are removing someone else from a site.
			if( user.id !== this.props.user.id ){
				this.props?.reFetchData();
			}
			// If removing yourself, and we're on the homepage,
			// spoof a site delete so it appears we left immediately. 
			// Then re-fetch the entire user object (including site list)
			// which should no longer contain the site.
			if( user.id === this.props.user.id && window.__c3_admin__ !== true ){
				// manually remove site from list...
				this.props?.fetchAccount( this.props.siteId );
			}
			
		}).then(response => {

			// Redirect when removing self and in admin
			if( user.id === this.props.user.id && window.__c3_admin__ === true ){
				window.location.href = HOMEPAGE_ORIGIN;
			}

			// Remove site from redux when on homepage.
			if( user.id === this.props.user.id && window.__c3_admin__ !== true ){
				this.props.removeWindow();
			}

		}).catch((err) => {

			let message = _.values(err.response?.data?.errors)?.[0]?.[0] || 'Something went wrong';

			this.props.alert.openModal({
				header: message,
				type: 'notice',
				HotKeyProxy: this.props.hotkeys,
				ignoreUnmount: true,
				onConfirm: (options) => {
				}
			});	

			// alert(_.values(err.response?.data?.errors)?.[0]?.[0] || 'Something went wrong');

		})
	}

	editPermission = (user, newRole) => {
		// https://api.newcargo.site/v1/permissions
		// expects, site_id -- user_id -- role
		// /v1/permissions/{userId}/site/{siteId}
		(cargoFetch.hasAuthorization() ? cargoFetch : axios).put(`${API_ORIGIN}/permissions/${user.id}/site/${this.props.siteId}`,{ role: newRole }, {use_auth: true}
		).then((res) => {
			this.props?.reFetchData();
		}).catch((err) => {
			
			// alert(_.values(err.response?.data?.errors)?.[0]?.[0] || 'Something went wrong');

			let message = _.values(err.response?.data?.errors)?.[0]?.[0] || 'Something went wrong'

			this.props.alert.openModal({
				header: message,
				type: 'notice',
				HotKeyProxy: this.props.hotkeys,
				ignoreUnmount: true,
				onConfirm: (options) => {
				}
			});	

		})

	}

	componentDidUpdate = (prevProps) => {

		// Remove user from pending state when they accept
		if( this.props.editors !== prevProps.editors &&
		    this.props?.editors?.length > prevProps?.editors?.length &&
		    this.state.pendingUsers.length > 0 &&
		    prevProps.editors
		 ){
		 	// Get newly added user
			const prevSet = new Set(prevProps.editors.map(o => o.id))
			const added = this.props.editors.filter(o => !prevSet.has(o.id))
			// Find this user in the pending users state obj.
			let indexInStateObj = this.state.pendingUsers.findIndex(obj => { return obj.email === added[0].email });
			// If it exists...
			if( indexInStateObj !== -1 ){
				// Remove pending user from state arr.
				let newState = [...this.state.pendingUsers];
				newState.splice(indexInStateObj, 1);
				// Set state.
				this.setState({ pendingUsers: newState });
			}
		}

	}

	render() {

		// let siteAdmins = _.filter(this.props.editors, {role : 'Admin'});
		let suggestionsArray = this.props.emails;
		// String.fromCharCode('U+2060') Creates a word joiner character, breaks up placeholder keywords that otherwise activate autofill in safari.

		let isTemplateAdmin = parseInt( window.store.getState().auth.data.id ) === 1;

		return (
			<>
				<window-header>
					<window-label label-size="large">
						Users
					</window-label>
				</window-header>

				<window-content ref={ this.interfaceRef }>
					{ this.props.role === 'Admin' ? (
						<>
						<Formik
							initialValues={{
								email: '',
								access: isTemplateAdmin ? 'Admin' : 'Editor'
							}}
							onSubmit={(values) => { 
								//we handle this elsewhere 
							}}
						>
							{props => (
								<Form>

								<FormikChangeListener 
									onChange={()=> { 
										if( this.state.inputError ){
											this.setState({inputError: false})
										}
									}}
								/>
									<div className="ui-group">
										<div className={`user editable${this.state.inputError ? ' error' : ''}`}>
											<Field
												name="email"
												autoComplete="off"
												component={AutofillInput}
												barLeft="true"
												focusOnInit={true}
												placeholder={`Enter ema${String.fromCharCode('U+2060')}il add${String.fromCharCode('U+2060')}ress...`}
												suggestionArray={ suggestionsArray }
												onKeyPress={(e)=> { 
													// if( e && e?.key === 'Enter' ) {
													// 	e.preventDefault();
													// 	e.stopPropagation();
													// 	if( props.values.email.length === 0 ) { return }
													// 	this.inviteUser(null, props);
													// } 
												}}
												onEnter={(e)=> {
													if( props.values.email.length === 0 ) { return }
													this.inviteUser(null, props);
												}}
												parentClassName={`${this.props.siteVersion === 'Cargo2' ? 'no-label' : ''}`}
											/>
											{ this.props.siteVersion === 'Cargo2' ? ( null ) : (
												<MenuContext.Consumer>
													{(Menu) => 
														<Button
															label={this.state.newAccess}
															className={`user-role-button${isTemplateAdmin ? ' disabled' : ''}`}
															// id={site.direct_link}
															onPointerDown = { e => {
																	e.preventDefault();
																	Menu.openMenu({
																		innerUI: <EditorsContextUI type="new" hotkeys={this.props.hotkeys} setInvitePermissions={ this.setInvitePermissions } editor={{email: this.state.newEmail, access: this.state.newAccess}} role={this.props.role} />,
																		type: 'mouse',
																		event: e
																	}) 
																}
															}
														/>
													}
												</MenuContext.Consumer>
											)}
										</div>
									</div>
									<MoreActions className="left">
										<Button 
											className={`rounded${props.values.email.length === 0 ? ' disabled' : ''}`} 										
											type="submit"
											label="Invite"
											onClick={(e) => { 

												if( props.values.email.length === 0 ) { return }

												let hintEl = document.querySelector('.visible-hint', this.interfaceRef);
												let hintText = hintEl ? hintEl.innerText : '';
								
												if( hintText.length > 0 ){
													let fullValue = props.values.email+hintText;
													props.setFieldValue('email', fullValue );
													props.values.email = fullValue;

													const setAutofillValue = new CustomEvent('set-autofill-from-parent', {
													detail: {
														message: fullValue,
													}
													});

													document.dispatchEvent( setAutofillValue );
												}

												setTimeout(()=>{
													this.inviteUser(e, props)
												},1)
												
											}}
										>
										{ this.state.sendingInvite ? (
											<ProcessingAnimation className="accent" />  
										) : ( null )}
										
										</Button>
									</MoreActions>
									
								</Form>
							)}
						</Formik>
						<div className="uiWindow-spacer"></div>
						<div className="uiWindow-spacer"></div>
						</>
					) : ( null )}
					<Formik
						initialValues={{...this.props.editors}}
					>
						<Form>
							<div className="ui-group">
								{this.state.pendingUsers.map((user, index) => {
									return (
										<UserRow
											key            = { 'new'+index }
											user           = { user }
											index          = { index }
											editors        = { this.props.editors }
											role           = { this.props.role }
											editPermission = { this.editPermission }
											siteVersion    = { this.props.siteVersion }
											removeUser     = { this.removeUser }
											currentEditor  = { this.props.user }
											setInvitePermissions = { this.setInvitePermissions }
											hotkeys={this.props.hotkeys}
										/>
									)
								})}

								{this.props.editors.map((user, index) => {
									// Don't show the templates user, unless you ARE the templates user.
									if( user.id === 1 && (this.props.user.id !== 1 && this.props.user.id !== 14494300)) { return null }
									return ( 
										<UserRow
											key            = { user.id }
											user           = { user }
											index          = { index }
											editors        = { this.props.editors }
											role           = { this.props.role }
											editPermission = { this.editPermission }
											siteVersion    = { this.props.siteVersion }
											removeUser     = { this.removeUser }
											currentEditor  = { this.props.user }
											setInvitePermissions = { this.setInvitePermissions }
											hotkeys        = {this.props.hotkeys}
										/>
									)
								})}
							</div>
						</Form>
					</Formik>
				</window-content>
			</>
		);

	}

}

export const EditorsWindow = EditorsWindowClass;

