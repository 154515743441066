import { createRef } from 'preact';
import _ from 'lodash';
import { helpers } from "@cargo/common";
import { actions } from "../../actions";
import { Component, createContext } from "preact";
import { PureComponent } from 'preact/compat';
import { bindActionCreators } from 'redux';
import { connect} from 'react-redux';
import windowInfo from "./../window-info";
import { subscribe, unsubscribe, dispatch } from '../../customEvents';


const scrollContextData = {
	scrollUid: 'default',
	scrollingElement: null,
	getScrollPosition: ()=>{
		return {
			scrollHeight: 0,
			scrollWidth: 0,
			x: 0,
			y: 0,
			w: 0,
			h: 0
		}
	},
	scrollIntoView: ()=>{},
	removeElementFromScrollContext: ()=>{},
	addElementToScrollContext: ()=>{},
	updateScrollPosition: ()=>{}
}


const ScrollContext = createContext(_.cloneDeep(scrollContextData));

const lazyLoadIntersectionObservers = {};
const viewportIntersectionObservers = {};
const observedChildren = {'default': new Set() };


let resizeObserver;
let observedScrollElements = {};

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(function(entries){
						
		entries.forEach(function(entry){

			dispatch(entry.target, 'elementResize', null, {
				bubbles: false
			});

		});

	});


}

const scrollMonitor = (entries, intersectionEventType)=>{

	entries.forEach(function(entry){

		let position = 'inside'
		if(entry.boundingClientRect.bottom < 0 && !entry.isIntersecting){
			position = 'above'
		} else if (entry.boundingClientRect.top >= 0 && !entry.isIntersecting){
			position = 'below'
		}

		dispatch(entry.target, intersectionEventType, {
			hasLayout: entry.boundingClientRect.width > 0 && entry.boundingClientRect.height > 0,
			visible: entry.isIntersecting,
			position
		}, {
			bubbles: false,
		});

	});
}

class ScrollContextProvider extends PureComponent {

	constructor(props){
		
		super(props);

		this.state = {
			scrollUid: 'default',
			scrollingElement: null,
			getScrollPosition: this.getScrollPosition,
			scrollIntoView: this.scrollIntoView,
			updateScrollPosition: this.updateScrollPosition,
			addElementToScrollContext: this.addElementToScrollContext,
			removeElementFromScrollContext: this.removeElementFromScrollContext
		};

		this.scrollData = {
			x: 0,
			y: 0,
			w: 0,
			h: 0,
		}

	}

	addElementToScrollContext = (el, scrollUid)=>{

		if(scrollUid =='default' ){
			return;
		}

		// bad hack for elements whose visibility needs to be managed manually
		// stacking scroll contexts not working as expected currently
		const inQuickView = el.classList.contains('quick-view-item');
		const inSlideshow = el.parentNode?.nodeName === 'GALLERY-SLIDESHOW'
		const isInMarquee = el.parentNode.nodeName ==='MARQUEE-INNER';
		
		if(
			inQuickView || inSlideshow || isInMarquee
		){

			// slideshow triggers visibility manually, quickview needs to be always visible
			if( inQuickView ){
				dispatch(el, 'lazyLoadIntersectionChange', {
					hasLayout: true,
					visible: true,
					position: 'inside'
				}, {
					bubbles: false,
				});
				dispatch(el, 'viewportIntersectionChange', {
					hasLayout: true,
					visible: true,
					position: 'inside'
				}, {
					bubbles: false,
				});
			} 


		} else if( !observedChildren[scrollUid].has(el) ){

			observedChildren[scrollUid].add(el);
			viewportIntersectionObservers[scrollUid].observe(el);
			lazyLoadIntersectionObservers[scrollUid].observe(el);		
		
		}	

	}

	removeElementFromScrollContext=(el, scrollUid)=>{

		viewportIntersectionObservers[scrollUid]?.unobserve(el);
		lazyLoadIntersectionObservers[scrollUid]?.unobserve(el);
		observedChildren[scrollUid]?.delete(el)

	}	

	updateScrollPosition = (scrollPosition) =>{

		if( helpers.isServer || !this.state.scrollingElement ){
			return;
		}


		let x = scrollPosition.x || this.scrollData.x;
		let y = scrollPosition.y || this.scrollData.y;

		if (this.state.scrollingElement === window){
			document.scrollingElement.scrollTo(x, y)

		} else {
			this.state.scrollingElement.scrollTo(x, y)
		}
	}

	onScrollRequest = (e)=>{

		if( e.defaultPrevented){
			return;
		}

		e.stopPropagation();
		e.preventDefault();

		this.scrollIntoView(e.target, e.detail?.transitionTime, e.detail?.preventQuickViewClose, e.detail?.lazyScroll, e.detail.callback || null, e.detail.verticalAlign);
	}

	scrollIntoView = (targetEl, transitionTime=1000, preventQuickViewClose=true, lazyScroll=false, callback = null, verticalAlign ='center')=>{

		let scrollingElement = this.state.scrollingElement === window ? document.scrollingElement : this.state.scrollingElement;
		if ( !scrollingElement.contains(targetEl) || !targetEl ){
			if(callback){
				callback();
			}			
			return;
		}

		let rect = targetEl.getBoundingClientRect();

		// the element might be hidden ( eg inactive slide in a slideshow )
		while(rect.width === 0 && rect.height == 0 && targetEl){
			targetEl = targetEl.parentElement
			rect = targetEl.getBoundingClientRect();
		}

		let elX = rect.left + rect.width*.5;
		let elY = rect.top + rect.height*.5;

		switch(verticalAlign){
			case "center":
				elY = rect.top + rect.height*.5;
				break;
			case "bottom":
				elY = rect.top + rect.height;
				break;
			case "top":
				elY = rect.top;
				break;
		}

		let windowWidth = document.documentElement.clientWidth;
		let windowHeight = document.documentElement.clientHeight;


		// if the center is in the viewport, don't scroll
		if(
			lazyScroll &&
			rect.bottom >= 20 &&
			rect.right >= 20 &&
			rect.top <= windowHeight-20 &&
			rect.left <= windowWidth-20 		

		){
			if(callback){
				callback();
			}			
			return;
		}

		let initialScrollX = this.scrollData.x;
		let initialScrollY = this.scrollData.y;

		let scrollToY = Math.max(0, Math.min( this.scrollData.scrollHeight - (windowHeight), initialScrollY - ((windowHeight*.5) - elY )));
		let scrollToX = Math.max(0, Math.min( this.scrollData.scrollWidth - (windowWidth), initialScrollX - ((windowWidth*.5) - elX )));

		let scrollDeltaY = scrollToY - initialScrollY;
		let scrollDeltaX = scrollToX - initialScrollX;


		// assume 60fps and speed in ms
		// so 1000ms at 60fps = 60 frames
		let progress = 0;
		let lastTimestamp = 0;
	
		cancelAnimationFrame(this.scrollAnimationFrame);

		var endScrollTo = ()=>{
			
			cancelAnimationFrame(this.scrollAnimationFrame);			
			this.state.scrollingElement.removeEventListener('mousewheel', endScrollTo)
			this.props.updateFrontendState({
				quickView: {
					autoScrolling: false,
				}
			});

			if(callback){
				callback();
			}
		}

		this.state.scrollingElement.addEventListener('mousewheel', endScrollTo)

		let incrementScroll = (timestamp)=>{

			const delta = lastTimestamp=== 0 ? 0 : timestamp - lastTimestamp;
			lastTimestamp = timestamp;
			progress = progress+delta;

			let easedValue = helpers.easeInOutCubic(progress/transitionTime);

			if( progress <= transitionTime){

				this.updateScrollPosition({
					x: initialScrollX + scrollDeltaX*easedValue,
					y: initialScrollY + scrollDeltaY*easedValue,
				})

				this.scrollAnimationFrame = requestAnimationFrame(incrementScroll);

			} else {

				this.updateScrollPosition({
					x: initialScrollX + scrollDeltaX,
					y: initialScrollY + scrollDeltaY,
				})				

				endScrollTo();

			}
		
		}

		this.props.updateFrontendState({
			quickView: {
				autoScrolling: preventQuickViewClose,
			}
		});

		requestAnimationFrame(()=>{
			requestAnimationFrame(incrementScroll);
		});
		

	}

	render(){

		return (
			<ScrollContext.Provider value={
				this.props.passthrough ? this.context : this.state
			}>
				{this.props.children}
			</ScrollContext.Provider>
		)
	}


	componentDidUpdate(prevProps, prevState, prevContext){

		if (helpers.isServer){
			return;
		}
		this.checkScrollElement();

		// do cleanup of old scrollElement stuff
		if(
			this.state.scrollingElement != prevState.scrollingElement
		){	
			this.removeListeners(prevState);
		}


	}

	setNewElement =(newElement)=>{


		const scrollUid= _.uniqueId();
		observedChildren[scrollUid] = new Set();
		
		if( newElement == window){

			subscribe(document.scrollingElement, 'elementResize', this.onResize);

			resizeObserver.observe(document.scrollingElement);			
			window.addEventListener('scroll', this.onScroll, {passive: false});
			window.addEventListener('request-scroll', this.onScrollRequest, {passive: false});

		} else {

			subscribe(newElement, 'elementResize', this.onResize);

			newElement.addEventListener('request-scroll', this.onScrollRequest, {passive: false})
			resizeObserver.observe(newElement);
			newElement.addEventListener('scroll', this.onScroll, {passive: false})
		}


		viewportIntersectionObservers[scrollUid] = new IntersectionObserver((entries)=>scrollMonitor(entries, 'viewportIntersectionChange'), {
			root: newElement == window ? document : newElement,
			rootMargin: '0px',
			threshold: 0
		});

		lazyLoadIntersectionObservers[scrollUid] = new IntersectionObserver((entries)=>scrollMonitor(entries, 'lazyLoadIntersectionChange'), {
			root: newElement == window ? document : newElement,
			rootMargin: (window.screen.height*1.5) +'px ' + (window.screen.width*1.5) +'px',
			threshold: 0
		});

		this.setState({
			scrollUid: scrollUid,
			scrollingElement: newElement
		},()=>{
			this.onResize();
		})

	}

	removeListeners=(prevState)=>{

		if(prevState.scrollingElement){

			if( prevState.scrollingElement == window){

				window.removeEventListener('resize', this.onResize);
				window.removeEventListener('scroll', this.onScroll, {passive: true})
				resizeObserver.unobserve(document.scrollingElement);
				window.removeEventListener('request-scroll', this.onScrollRequest, {passive: false})


			} else {

				unsubscribe(prevState.scrollingElement, 'elementResize', this.onResize);

				prevState.scrollingElement.removeEventListener('request-scroll', this.onScrollRequest, {passive: false});
				prevState.scrollingElement.removeEventListener('scroll', this.onScroll, {passive: true});
			
				resizeObserver.unobserve(prevState.scrollingElement);
			}

			Object.keys(observedChildren[prevState.scrollUid]).forEach((el)=>{
				lazyLoadIntersectionObservers[prevState.scrollUid].unobserve(el);
				viewportIntersectionObservers[prevState.scrollUid].unobserve(el);
				observedChildren[prevState.scrollUid].delete(el);
			});

		}

		if( prevState.scrollUid != 'default'){

			lazyLoadIntersectionObservers[prevState.scrollUid].disconnect();
			viewportIntersectionObservers[prevState.scrollUid].disconnect();
			delete lazyLoadIntersectionObservers[prevState.scrollUid];
			delete viewportIntersectionObservers[prevState.scrollUid];
			delete observedChildren[prevState.scrollUid]
		}
	}

	checkScrollElement =()=> {

		if(
			!helpers.isServer &&
			this.props.scrollingElement.current &&
			this.props.scrollingElement.current != this.state.scrollingElement &&
			!this.props.passthrough
		){
			this.setNewElement(this.props.scrollingElement.current);
		} else if(this.state.scrollingElement !== null && this.props.passthrough) {
			this.setState({
				scrollingElement: null,
				scrollUid: 'default'
			})
		}

	}

	componentDidMount(){
		if( helpers.isServer){
			return;
		}

		this.checkScrollElement();
	}

	componentWillUnmount() {

		if ( helpers.isServer ){
			return
		}
		
		cancelAnimationFrame(this.scrollAnimationFrame);
		cancelAnimationFrame(this.onScrollFrame)			
		 this.removeListeners(this.state);
	
	}

	onResize =(e)=>{

		if (helpers.isServer || !this.state.scrollingElement ){
			return;
		}

		if (e){
			e.stopPropagation();
			e.stopImmediatePropagation();
			e.preventDefault();			
		}

		if (this.state.scrollingElement === window){

			this.scrollData.scrollHeight = document.scrollingElement.scrollHeight;
			this.scrollData.scrollWidth = document.scrollingElement.scrollWidth;
			this.scrollData.h = document.documentElement.clientHeight;
			this.scrollData.w = document.documentElement.clientWidth;

		} else {

			this.scrollData.scrollHeight = this.state.scrollingElement.scrollHeight;
			this.scrollData.scrollWidth = this.state.scrollingElement.scrollWidth;
			this.scrollData.h = this.state.scrollingElement.clientHeight;
			this.scrollData.w = this.state.scrollingElement.clientWidth;

		}

		this.onScroll();	
	}

	onScroll = () => {

		if (helpers.isServer || !this.state.scrollingElement || this.props.passthrough){
			return;
		}

		if (this.state.scrollingElement === window){
			
			this.scrollData.x = window.pageXOffset || document.scrollingElement.scrollLeft  || document.documentElement.scrollLeft || 0;
			this.scrollData.y = window.pageYOffset || document.scrollingElement.scrollTop || document.documentElement.scrollTop ||  0;

		} else {
			
			this.scrollData.x = this.state.scrollingElement.scrollLeft;
			this.scrollData.y = this.state.scrollingElement.scrollTop;

		}


	}

	getScrollPosition = ()=>{
		return {...this.scrollData}
	}

}


ScrollContextProvider.contextType = ScrollContext


function withScroll(WrappedComponent){

	class ScrollHOC extends Component {
		constructor(props) {
			super(props);
			this.lastScrollUIDRef = createRef();

		}

		render() {
			return <WrappedComponent {...this.props}  scrollContext={this.context} />;
		}

		componentDidMount(){

			if(this.context && this.props.baseNode ){
				this.context.addElementToScrollContext(this.props.baseNode, this.context.scrollUid);
				this.lastScrollUIDRef.current = this.context.scrollUid;
			}

			if( this.props.baseNode){
				// subscribe(this.props.baseNode, 'custom-element-connected', this.refreshScrollContext)				
			}
		}

		refreshScrollContext= (evt)=>{
			if( evt.element == this.props.baseNode && this.props.baseNode){

				this.context.removeElementFromScrollContext(this.props.baseNode, this.context.scrollUid);

				setTimeout(()=>{
					this.context.addElementToScrollContext(this.props.baseNode, this.context.scrollUid);
					this.lastScrollUIDRef.current = this.context.scrollUid;												
				}, 0);
			}			
		}

		
		componentWillUnmount(){


			if( this.props.baseNode ){
				if(this.context ){
					this.context.removeElementFromScrollContext(this.props.baseNode, this.context.scrollUid)		
				}
				// unsubscribe(this.props.baseNode, 'custom-element-connected', this.refreshScrollContext)				
			}

		}

		componentDidUpdate(prevProps, prevState){

			if( this.props.baseNode !== prevProps.baseNode){

				if( prevProps.baseNode){
					this.context.removeElementFromScrollContext(prevProps.baseNode, this.context.scrollUid);					
				}

				if( this.props.baseNode){
					this.context.addElementToScrollContext(this.props.baseNode, this.context.scrollUid);					
				}

				this.lastScrollUIDRef.current = this.context.scrollUid;

			} else if ( this.lastScrollUIDRef.current !== this.context.scrollUid && this.props.baseNode ){

				if( this.lastScrollUIDRef.current){

					this.context.removeElementFromScrollContext(this.props.baseNode, this.lastScrollUIDRef.current);					
				}

				this.context.addElementToScrollContext(this.props.baseNode, this.context.scrollUid);
				this.lastScrollUIDRef.current = this.context.scrollUid;
			}			

		}

	}

	ScrollHOC.contextType = ScrollContext

	return ScrollHOC;
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFrontendState: actions.updateFrontendState
	}, dispatch);
}

export default connect(
    (state, ownProps) => {
        return {
        	quickView: state.frontendState.quickView
        };
    }, mapDispatchToProps
)(ScrollContextProvider);

export {ScrollContext, withScroll};
