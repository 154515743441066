import { Component, createRef } from "preact";
import { createPortal } from "preact/compat";
import { useState, useEffect } from "preact/hooks";
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import _ from "lodash";

// Mobile resize handler
const contactFormInstance = {};
let mediaQuery = window.matchMedia('(max-aspect-ratio: 1/1)');
let isMobile = mediaQuery.matches;

const matchListener = mediaQuery.addEventListener('change', (e) => {

    isMobile = e.matches;
    if( contactFormInstance['1'] ) {
        contactFormInstance['1'].setState({ isMobile })
    }

});


class ContactFormComponent extends Component {

    constructor(props){

        super(props);

        this.state = {
            loaded: false,
            showContents: false,
            animate: false,
            sent: false,
            loading: false,
            emailValue: '',
            messageValue: '',
            emailError: false,
            messageError: false,
            isMobile: isMobile,
            shadowRoot: null,
        };

        this.cssLinkRef = createRef();
        this.contactFormRef = createRef();

        this.startTime = null;
        this.inputRef = createRef();

        this.throttledResize = _.throttle(this.resizeHandler, 200);

    }

    componentDidMount() {

        if( this.contactFormRef.current && !this.contactFormRef.current.shadowRoot ){
            this.contactFormRef.current.attachShadow({mode: 'open'})
        }

        this.setState({
            shadowRoot:this.contactFormRef.current?.shadowRoot
        })

        if( !this.props.contactForm.inited ){
            this.props.updateFrontendState({
                contactForm: { 
                    transition: false,
                    inited: true
                }
            });
        }

    }

    resizeHandler = () => {
        // Set VW / VH - 30px manually for mobile form 
        let vh = ( window.innerHeight - 30 ) * 0.01;
        let vw = ( window.innerWidth - 30 ) * 0.01;

        document.documentElement.style.setProperty('--contact-form-vh', `${vh}px`);
        document.documentElement.style.setProperty('--contact-form-vw', `${vw}px`);
    }

    handleEmailChange = (event) => {
        this.setState({
            emailValue: event.target.value,
            emailError: false
        });
    }

    handleMessageChange = (event) => {
        this.setState({
            messageValue: event.target.value,
            messageError: false
        });
    }

    submitForm = () => {

        if( this.validate() ){

            this.setState({
                loading: true
            }, () => {

                this.startTime = new Date();

                let payload = {
                    from    : this.state.emailValue,
                    site_id : this.props.siteId,
                    message : this.state.messageValue
                }

                let url = CARGO_ENV !== 'production' ?
                    'https://dev.cargo.site/dispatch/notifications/sendContactMessage' : 
                    'https://cargo.site/dispatch/notifications/sendContactMessage';

                axios.post( 
                    url, 
                    payload, {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    }
                })
                .then((response) => {
                    // Get response time.
                    let responseTime = new Date();
                    let fullTime = responseTime - this.startTime;
                    // Allow form to close at any time after response.
                    this.startTime = null;
                    // Delay form close to 3.5s duration.
                    if( fullTime <= 2500 ){
                        let timeout = 2500 - fullTime;
                        setTimeout(() => {
                            this.closeForm(null, 'submit');
                        }, timeout)
                    } else {
                        this.closeForm(null, 'submit');
                    }
                    
                })
                .catch((e) => {
                    // window.alert("Message failed to send, please try again.");
                    window.store.dispatch({
                        type: 'UPDATE_FRONTEND_STATE', 
                        payload: {
                            alertModal: { message: 'Message failed to send, please try again.', type: 'notice' }
                        }
                    }); 
                    // Allow form to close at any time after response.
                    this.startTime = null;
                    this.handleFormError();
                });

            });

        }

    }

    closeForm = ( e, type ) => {

        // Don't close form while submitting.
        if( this.startTime !== null ){
            return 
        }

        document.body.style.overflow = '';

        window.removeEventListener('resize', this.throttledResize);

        if( !type ){
            // Start close animation for CANCEL
            window.requestAnimationFrame( () => { 
                this.setState({ animate: false }, ()=> {
                    this.resetForm();
                });
            });

            

        } else {
            // Start close animation for SENDING
            window.requestAnimationFrame( () => { 
                this.setState({ sent: true }, () =>{
                    this.resetForm();
                });
            });

        }

    }

    resetForm = () => {

        delete contactFormInstance['1'];

        // Wait for animation complete  
        setTimeout(() => { 

            // close the overlay
            this.props.closeOverlay();

        }, 280)

    }

    componentDidUpdate = (prevProps) => {
        if( this.props.contactForm.transition !== prevProps.contactForm.transition &&
            !prevProps.contactForm.transition &&
            this.props.contactForm.transition &&
            this.startTime === null && 
            this.state.animate
        ){
            this.closeForm();
        }
    }

    componentWillUnmount = () => {

        if( this.props.contactForm.inited ){
            this.props.updateFrontendState({
                contactForm: { 
                    transition: false,
                    inited: false
                }
            });
        }
        
        document.body.style.overflow = '';

        document.documentElement.style.removeProperty('--contact-form-vh');
        document.documentElement.style.removeProperty('--contact-form-vw');

    }

    handleFormError = () => {

        this.setState({
            loading: false
        })

    }

    handleTransitionEnd = () => {
        // this.setState({ showContents: false });
    }

    focusInput = () => {
        this.inputRef?.current?.focus()
    }

    validate = () => {
        /**
         * https://github.com/scottgonzalez/sane-email-validation
         */
        let localAddr = /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+$/i;
        let domain = /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i;
        let emailValid = true;
        let messageValid = true;
        let email = this.state.emailValue;
        let message = this.state.messageValue;

        if( email.length <= 0 ){
            emailValid = false;
        }

        if( message.length <= 0 ){
            messageValid = false;
        }

        let parts = email.split( "@" );

        if ( parts.length !== 2 ) {
            emailValid = false;
        }

        if ( !localAddr.test( parts[ 0 ] ) ) {
           emailValid = false;
        }

        if ( !domain.test( parts[ 1 ] ) ) {
            emailValid = false;
        }

        if( !emailValid ){
            this.setState({ emailError: true });
        }

        if( !messageValid ){
            this.setState({ messageError: true })
        }

        if( !emailValid || !messageValid ){
            return false;
        }

        return true;

    }

    onCSSLoad = () => {

        // animate form
        this.setState({
            showContents: true,
            isMobile: mediaQuery.matches
        }, ()=> {

            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = ( window.innerHeight - 30 ) * 0.01;
            let vw = ( window.innerWidth - 30 ) * 0.01;
            // Then we set the value in the --contact-form-vh custom property to the root of the document
            document.documentElement.style.setProperty('--contact-form-vh', `${vh}px`);
            document.documentElement.style.setProperty('--contact-form-vw', `${vw}px`);

            window.addEventListener('resize', this.throttledResize);

            document.body.style.overflow = 'hidden';

            contactFormInstance['1'] = this;

            setTimeout(() => {
                 this.setState({ 
                    animate: true 
                })
             }, 33);

        });

    }

    render() {

        return (
            <div className="contact-form" ref={this.contactFormRef}> {/* The shadow root will be attached to this DIV */}
                {this.state.shadowRoot && createPortal(<>
                    <link rel="stylesheet" onLoad={this.onCSSLoad} ref={this.cssLinkRef} type="text/css" href={`${PUBLIC_URL}/css/front-end/contact-form.css`} />
                    {/* Background */}
                    { this.state.showContents === true ? (
                    <div className={`background${this.state.animate ? " active" : ""}${this.state.sent ? " sent" : ""}`}>
                        {/* MODAL */}
                        <div 
                            className={`modal${this.state.animate ? " active" : ""}${this.state.sent ? " sent" : ""}${this.state.isMobile ? " mobile" : ""}`}
                            onTransitionEnd={(e) => { 
                                if( this.state.sent === true ){
                                    this.handleTransitionEnd(e)
                                }
                            }}
                        > 
                            {/* <div className="modal-content"> */}
                                <div className="modal-inner">

                                    {/* HEADER */}
                                    <div className="header"> 
                                        <div className="title"></div>
                                        <div 
                                            className="exit"
                                            onPointerUp={(e) => { this.closeForm(e) }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <circle opacity="0.25" cx="10" cy="10" r="10" fill="black"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10.0002 11.0607L14.3099 15.3703L15.3705 14.3096L11.0609 10L15.3705 5.69036L14.3099 4.6297L10.0002 8.93934L5.69054 4.62964L4.62988 5.6903L8.93958 10L4.62988 14.3097L5.69054 15.3704L10.0002 11.0607Z" fill="white"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <hr />
                                    {/* FROM EMAIL */}
                                    <div 
                                        onPointerUp={(e)=> { this.focusInput(e); }} 
                                        className="email"
                                    >
                                        <label 
                                            htmlfor="email"
                                        >
                                        From:{String.fromCharCode('160')}
                                        </label>

                                        <div className={`text-input${this.state.emailError ? " error" : ""}`}>
                                            <input 
                                                ref={this.inputRef}
                                                type="email"
                                                name="email" 
                                                autocomplete="email"
                                                value={this.state.emailValue} 
                                                onChange={ (e) => { this.handleEmailChange(e) }}
                                                placeholder="(enter your email address...)"
                                            />
                                        </div>

                                    </div>
                                    <hr />
                                    {/* Text Area */}
                                    <div className={`text-area${this.state.messageError ? " error" : ""}`}> 
                                        <textarea 
                                            name="message"                                    
                                            type="text"
                                            placeholder={'Write your message...'}
                                            rows={'1'}
                                            resize="false"
                                            value={ this.state.messageValue }
                                            onChange={(e) => { this.handleMessageChange(e) }} 
                                        >
                                        </textarea> 
                                    </div>
                                    <hr />
                                    {/* Button group */}
                                    <div className="button-area">
                                         {!this.state.loading ? (  
                                             <> 
                                                 <button 
                                                     className="cancel" 
                                                     onPointerUp={(e) => { this.closeForm(e) }} 
                                                 > 
                                                     Cancel 
                                                 </button> 
                                                 <button 
                                                     className="send" 
                                                     onPointerUp={(e) => { this.submitForm(e) }} 
                                                 > 
                                                     Send 
                                                 </button> 
                                             </> 
                                         ) : (   
                                            <>
                                                <button className="send">
                                                    Sending<ProcessingAnimation/>
                                                    {/* <SendingAnimation/> */}
                                                </button>
                                            </>
                                         )}
                                    </div>

                            </div>
                        </div>

                    </div>
                    ) : (null)}
                </>, this.state.shadowRoot)}
            </div>
        );

    }
}

const SendingAnimation = () => {
  const [index, setIndex] = useState(0);
    useEffect(() => {
    const animationTimer = window.setInterval(() => {
        setIndex(prevIndex => prevIndex >= 9 ? 0 : prevIndex + 1 );
    }, 175);
    return () => {
      window.clearInterval(animationTimer);
    };
  }, []);
    
  switch( index ){
    case 0:
        return( <><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span></> )
    case 1: 
        return( <><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span></> )
    case 2:
        return( <><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span></> )
    case 3:
        return( <><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span></> )
    case 4:
        return( <><span>.</span><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span></> )
    case 5:
        return( <><span>.</span><span>.</span><span style={{visibility:'hidden'}}>.</span></> )
    case 6:
        return( <><span>.</span><span>.</span><span>.</span></> )
    case 7:
        return( <><span style={{visibility:'hidden'}}>.</span><span>.</span><span>.</span></> )
    case 8:
        return( <><span style={{visibility:'hidden'}}>.</span><span style={{visibility:'hidden'}}>.</span><span>.</span></> )
        break
  }
}

const ProcessingAnimation = () => {
    return (
        <div className={`caution-processing`}>
        </div>
    )
}


function mapReduxStateToProps(state, ownProps) {
    return {
        siteId: state.site.id,
        contactForm: state.frontendState.contactForm
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateFrontendState: actions.updateFrontendState
    }, dispatch);
}


const ContactForm = connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(
    ContactFormComponent
)

export default ContactForm