import { Component, createRef } from "preact";
import _ from 'lodash';
import { helpers } from "@cargo/common";
import { CustomElementHost } from '../../page/register'

// import available backdrops
import BackboneViewController from './modes/legacy/backboneViewController';
import WallPaperBackdrop from './modes/wallpaper/main';
import GradientBackdrop from './modes/gradient/main';

// model for how the backdrop setup works
// https://jsfiddle.net/omkL2xtz/

let intersectionObserver;
let resizeObserver;
let backdropInstances = {};

if(!helpers.isServer) {

	intersectionObserver = new IntersectionObserver(function(entries) {
						
		entries.forEach(function(entry){
			let position = 'inside'
			if(entry.boundingClientRect.top - entry.boundingClientRect.height < 0 && !entry.isIntersecting){
				position = 'above'
			} else if (entry.boundingClientRect.top > 0 && !entry.isIntersecting){
				position = 'below'
			}

			entry.target.dispatchEvent(new CustomEvent('intersectionChange', {
				detail: {
					visible: entry.isIntersecting,
					position: position
				}
			}));

		});

	}, {
		root: document,
		rootMargin: '0px 0px',
		threshold: 0
	});

	resizeObserver = new ResizeObserver(entries => {

		entries.forEach(entry => {

			entry.target.dispatchEvent(new CustomEvent('contentResize', {
				detail: {
					width: entry.contentRect.width,
					height: entry.contentRect.height
				},
				composed: true,
				bubbles: false				
			}));
		});
		
	});	

}

const backdropNames = [
	'wallpaper',
	'gradient',
	'legacy/ripple',
	'legacy/slitscan',
	'legacy/badtv',
	'legacy/gmaps',
	'legacy/halftone',
	'legacy/kaleidoscope',
	'legacy/morphovision',
	'legacy/parallax',
	'legacy/pixelation',
	'legacy/polygon_engine',
	'legacy/refraction',
	'legacy/ripple',
	'legacy/slitscan',
	'legacy/video',
	'legacy/wave'
];

const imports = {
	'wallpaper': WallPaperBackdrop,
	'gradient': GradientBackdrop
}

const Backdrops = {};

backdropNames.forEach((name)=> {

	if(name.startsWith('legacy')) {
		Backdrops[name] = BackboneViewController
	} else {
		Backdrops[name] = imports[name];
	}


});

class Backdrop extends Component {

	constructor(props){
		super(props);

		this.backdropRef = createRef();
		this.backdropWrapperRef = createRef();

		this.state = {

			loaded: 0,

			wrapperDimensions : {
				width: 800,
				height: 800,
			},

			dimensions: {
				width: 800,
				height: 800,
			},

			visibility: {
				visible: false,
				position: 'inside'
			}
		}

	}

	onIntersectionChange =(event)=>{
		this.setState({
			visibility: event.detail
		})
	}
	onWrapperResize = (event)=>{
		this.setState({
			wrapperDimensions: event.detail
		})	
	}
	onResize = (event)=>{
		this.setState({
			dimensions: event.detail
		})	
	}

	render(props, state){
		
		const {
			settings,
			id
		} = this.props;

		const BackdropMode = Backdrops[settings.activeBackdrop];

		if ( !BackdropMode ){

			if(settings.activeBackdrop !== 'none'){
				console.warn(`Error: Selected Backdrop mode "${settings.activeBackdrop}" not found`)
			}

			return null;
		}

		const activeBackdropSettings = settings.backdropSettings?.[settings.activeBackdrop] || {};
		const clipping = activeBackdropSettings.clipScroll == true;
		
		return <div className={`backdrop ${clipping ? 'clip' :''}`} ref={this.backdropWrapperRef}>
			<div
				className={`backdrop-contents ${this.state.visibility.visible ? 'visible' : ''} ${this.state.visibility.position} ${this.state.loaded > 0 ? 'loaded' : ''}`}
				ref={this.backdropRef}
				style={{
					'--backdrop-width':this.state.wrapperDimensions.width+'px',
					'--backdrop-height':this.state.wrapperDimensions.height+'px',
				}}
			>
				<BackdropMode
					{...this.props}
					wrapperDimensions={this.state.wrapperDimensions}
					backdropRef={this.backdropRef}
					id={this.props.id}
					path={settings.activeBackdrop}
					settings={activeBackdropSettings}
					dimensions={this.state.dimensions}
					visibility={this.state.visibility}
					onLoad={this.onLoad}
				/>	
			</div>
			<CustomElementHost baseNodeRef={this.backdropWrapperRef} />
		</div>
	}

	onLoad =(e)=>{

		// ripple (and likely others) will initially render with an inline opacity and depend on the Cargo.Event
		// callback to set that opacity to 1. Since we're handling all this with CSS now, we remove the styles
		const legacyBackdropEl = this.backdropRef.current.querySelector('[data-backdrop]');
		if( legacyBackdropEl){
			legacyBackdropEl.removeAttribute('style');
		}

		if(e){
			e.preventDefault();
			e.stopPropagation();
		}

		this.setState((prevState)=>{
			return {
				loaded: prevState.loaded +1
			}
		})

		setTimeout(() => {
			this.fireBackdropLoadComplete();
		});

	}

	fireBackdropLoadComplete = () => {

		if(!this.backdropLoadCompleteFired) {

			this.backdropLoadCompleteFired = true;

			window.dispatchEvent(new CustomEvent('backdrop-load-complete', {
				bubbles: true,
				composed: true,
				detail: {}
			}));

		}

	}

	componentDidUpdate(prevProps){
		if( prevProps.settings?.activeBackdrop !== this.props.settings?.activeBackdrop){
			this.setState((prevState)=>{
				return {
					loaded: prevState.loaded -1
				}
			})
		}
	}

	componentDidMount(){

		if( !this.backdropRef.current){
			return;
		}

		window.dispatchEvent(new CustomEvent('backdrop-load-start', {
			bubbles: true,
			composed: true,
			detail: {}
		}));

		// don't wait for more than 3 seconds for a backdrop to load
		setTimeout(() => {
			this.fireBackdropLoadComplete();
		}, 3000);

		this.backdropWrapperRef.current.addEventListener('legacy-backdrop-loaded', this.onLoad)
		this.backdropWrapperRef.current.addEventListener('contentResize', this.onWrapperResize)
		this.backdropRef.current.addEventListener('contentResize', this.onResize)
		this.backdropRef.current.addEventListener('intersectionChange', this.onIntersectionChange)
		resizeObserver.observe(this.backdropRef.current)
		resizeObserver.observe(this.backdropWrapperRef.current)

		intersectionObserver.observe(this.backdropRef.current)
	}

	componentWillUnmount(){
		if( !this.backdropRef.current){
			return;
		}

		this.backdropWrapperRef.current.removeEventListener('legacy-backdrop-loaded', this.onLoad)		
		this.backdropWrapperRef.current.removeEventListener('contentResize', this.onWrapperResize)		
		this.backdropRef.current.removeEventListener('contentResize', this.onResize)
		this.backdropRef.current.removeEventListener('intersectionChange', this.onIntersectionChange)
		resizeObserver.unobserve(this.backdropRef.current)
		resizeObserver.unobserve(this.backdropWrapperRef.current)		
		intersectionObserver.unobserve(this.backdropRef.current)		
	}

}

export default Backdrop;
