import _ from 'lodash';
import { Observable } from 'lib0/observable';
import { helpers } from "@cargo/common"

let currentAdminViewport;
let currentAdminMode;

class WindowInfo extends Observable {

	constructor(){
		super();

		this.data = {

			baseUnit: -1,

			mobile: {
				active: false,
				chrome: 0, // the size of the user interface, calculated by subtracting window height from maxheight
				maxHeight: 720, // the size of the viewport when the chrome is hidden, caused by scrolling
			},

			window: {
				w: 1280,
				h: 720,
			}

		}

		if(!helpers.isServer) {


			this.SvhSupported = CSS.supports("height", "100svh");
			if( this.SvhSupported ){

				this.minHeightMeasuringStick = document.createElement('div');
				this.minHeightMeasuringStick.setAttribute('style', 'position: fixed; top: 0;left: 0;z-index: -99; visibility: hidden; height: 100svh;');
				this.minHeightMeasuringStick.setAttribute('tabindex', -1);
				this.minHeightMeasuringStick.setAttribute('aria-hidden', true);
				document.body.appendChild(this.minHeightMeasuringStick);				
			}

			this.maxHeightMeasuringStick = document.createElement('div');
			this.maxHeightMeasuringStick.setAttribute('style', 'position: fixed; top: 0;left: 0;z-index: -99; visibility: hidden; height: 100vh;');
			this.maxHeightMeasuringStick.setAttribute('tabindex', -1);
			this.maxHeightMeasuringStick.setAttribute('aria-hidden', true);
			document.body.appendChild(this.maxHeightMeasuringStick);

			// todo: set this up to listen to prop watcher - if the matchMediaString changes, run setupMobileListener
			this.matchMediaString = `(max-aspect-ratio: 4/5)`;
			this.mediaQuery = window.matchMedia(this.matchMediaString);

			this.setupMobileListener();
			this.onWindowResize();

			window.addEventListener('resize', this.onWindowResize, {passive: true});

		}

	}

	setupStore(store) {

		store.subscribe(() => {

			const state = store.getState();

			if(state.frontendState && state.frontendState.adminMode !== currentAdminMode) {
				
				// handle going in and out of preview
				setTimeout(()=>{this.onWindowResize()}, 150);

				currentAdminMode = state.frontendState.adminMode;

			}

			if(state.adminState && state.adminState.viewport !== currentAdminViewport) {

				// set the new admin viewport
				currentAdminViewport = state.adminState.viewport;

				// trigger a change
				this.onMobileChange()

			}

		})

	}

	setupMobileListener(){

		this.mediaQuery.removeEventListener('change', this.onMobileChange);

		// trigger immediately to test current state
		this.onMobileChange();

		// bind listener for future changes
		const matchListener = this.mediaQuery.addEventListener('change', this.onMobileChange);

	}

	onMobileChange = () => {

		let isMobile;

		// admin viewport overrides mediaQuery results
		if(currentAdminViewport) {
			isMobile = currentAdminViewport === 'mobile';
		} else {
			isMobile = this.mediaQuery.matches;
		}

		if(isMobile !== this.data.mobile.active) {
			this.data.mobile.active = isMobile;
			this.emit('mobile-change', [isMobile]);
		}

	}

	getBaseUnit(options) {
		options = options || {};

		var baseUnit = 0;
		options = _.extend(true, {
			height: this.data.window.h,
			width : this.data.window.w,
			min   : 11,
			max   : false,
			weight: 5,
		}, options);

		// Start height or width, depending upon which is smallest
		baseUnit = options.width < options.height ? options.width : options.height;

		// Set as a percentage of the width/height
		baseUnit /= (100 / options.weight);

		// Minimums and maximums
		if (options.min && baseUnit < options.min) baseUnit = options.min;
		if (options.max && baseUnit > options.max) baseUnit = options.max;

		// Return the calculated unit
		return baseUnit;
	};


	onWindowResize =() =>{

		const {data} = this;

		let w = document.documentElement.clientWidth;
		let h = 0;
		let chrome = 0;


		let minHeight = 0;
		let maxHeight = this.maxHeightMeasuringStick.getBoundingClientRect().height;

		if( this.SvhSupported ){

			minHeight = this.minHeightMeasuringStick.getBoundingClientRect().height;		

		} else {

			let increment = 100;
			minHeight = 0;
			let lastResult;

			while(true) {

				const result = window.matchMedia.call(window, '(min-height:' + (minHeight) + 'px)')['matches'];

				if(lastResult !== undefined && result !== lastResult) {
					// we've overshot the value. Increase granularity
					increment /= 2;
					// start searching below the current value
					increment *= -1;
				}
				
				minHeight += increment;
				lastResult = result;

				// we've honed in on a value with 2 decimal precision
				if(Math.abs(increment) < 0.01) {
					minHeight = Math.round(minHeight)
					break;
				}

			}

		}


		chrome = maxHeight-minHeight;
		h = minHeight;

		let _diff   = 0;
		let _scale  = 5;
		let _size   = 0;
		let _weight = 9; // default value

		// Is this portrait?
		if (h+chrome > w) {
			_diff = ((h+chrome) / w) - 1;
		}

		// Ratio
		_diff = (_diff / 0.777777778);

		// iPhone vertical orientation ratio
		if (_diff > 1) {
			_diff = 1;
		}

		// Base unit
		_size = this.getBaseUnit({
			weight: _weight + (_scale * _diff),
			min: 20,
			width: w,
			height: h
		});

		// console.log('resize', _size, _weight, _diff, _scale, w, h)
		
		if ( _size !== data.size ) {
			document.documentElement.style.setProperty('--base-size', _size + '%'); 
			document.documentElement.style.setProperty('--viewport-height', h+'px'); 
		}

		data.window.h = h;
		data.window.w = w;
	
		data.mobile.maxHeight = maxHeight;
		data.mobile.chrome = data.mobile.maxHeight - data.window.h;
		data.baseUnit= _size;

		this.data = data;
		this.emit('window-resize', []);	
	}

}

export default new WindowInfo();

