import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';

    // "scaleX": 20,
    // "scaleY": 29,
    // "target_speed": 9,
    // "mouse_sensitivity": 100,
    // "direction": 245,
    // "color": "#fff",
    // "hex": "#fff",
    // "alpha": "0",
    // "mouse_interaction": true,
    // "image_url": "//static.cargo.site/assets/backdrop/default_1024.jpg",
    // "image_size": 2048,
    // "image": '',
    // "plugin_id": 8,
    // "scale": 1,

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'ripples',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},			
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},	
		{
			labelName: "Distortion X",
			name: "scaleX",
			type: "range-input",
			value: 20,
			min: 0,
			max: 100,
			step: 1,
		},

		{
			labelName: "Distortion Y",
			name: "scaleY",
			type: "range-input",
			value: 20,
			min: 0,
			max: 100,
			step: 1,
		},
		{
			labelName: "Scale",
			name: "scale",
			type: "range-input",
			value: 1,
			min: 1,
			max: 10,
			step: .01,
		},		

		{
			labelName: "Speed",
			name: "target_speed",
			type: "range-input",
			value: 9,
			min: 0,
			max: 100,
			step: .1,
		},

		{
			labelName: "Direction",
			name: "direction",
			type: "scrubber",
			numberOnlyMode: true,
			value: 245,
			min: 0,
			max: 360,
			loopValues: true,
			suffix: '°',
			step: 1,
		},
		{
			labelName: "Mouse Interaction",
			name: "mouse_interaction",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Mouse Sensitivity",
			requires: 'mouse_sensitivity',
			name: "mouse_sensitivity",
			type: "range-input",
			value: 100,
			min: 0,
			max: 100,
			step: 1,
		},
		{
			labelName: "Overlay Color",
			name: "color",
			type: "color",
			value: '#ffffff00',
			excludeFromShuffle: true,
		},	
	]
}
settings.transformChanges= (settings, changes)=>{

	if(changes.color) {
		const color = tinycolor(changes.color);

		changes['hex'] = color.toHexString();
		changes['alpha'] = color.getAlpha();
	}

	return changes;
}
settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.alpha = {
	value: 0
}
settings.defaults.hex = {
	value: '#fff'
}

export default settings;
export {onChangeCustom}
