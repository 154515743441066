import _ from 'lodash'

export const isLocalEnv = (/^local.*/.test(CARGO_ENV));
export const isDevEnv = (/^local.*/.test(CARGO_ENV));

// A nice helper to tell us if we're on the server
export const isServer = !(
	typeof window !== 'undefined' &&
	window.document &&
	window.document.createElement
);

export const isServerRendered = !isServer && window.hasOwnProperty('__PRELOADED_STATE__');

let isInAdminFrame = false;

try {
	if(
		window.parent !== window
		&& window.location.host === window.parent.location.host
		&& window.parent.location.pathname.startsWith('/edit')
	) {
		isInAdminFrame = true;
	}
} catch(e) {
	if (! isServer || ! isLocalEnv) {
		console.log('Unable to determine if in admin.')
	}
}

export const isAdminEdit = isInAdminFrame;

// If we are in site preview at u.cargo
let isInSitePreviewFrame = false;
let sitePreviewMatchHostname = undefined;

try {
	if(
		typeof window !== 'undefined' 
		&& window.location.pathname.match('site.preview') !== null
	) {
		const sitePreviewMatch = window.location.pathname.match(/^\/site\.preview\/(?<hostname>[^\/]+)/i);
		if (sitePreviewMatch?.groups?.hostname) {
			sitePreviewMatchHostname = sitePreviewMatch.groups?.hostname;
			isInSitePreviewFrame = true;
		}
	}
} catch(e) {
	console.log('Unable to determine if in site preview')
}

export const isSitePreview = isInSitePreviewFrame;
export const sitePreviewHostname = sitePreviewMatchHostname;


export const isMobile = (function(){

	if(isServer) {
		return false;
	}

	var check = false,
		agent = (window.navigator.userAgent||window.navigator.vendor||window.opera);

	(function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))check = true})(agent);
	check = (check || (!check && /(android)/i.test(agent.substr(0,40)) ));

	return check;

})();

export const viewportOrientation = () => {

	const maxSquareAspect = window.matchMedia(`(max-aspect-ratio: 1:1)`);

	if( isServer ) {
		return false;
	}

	if( maxSquareAspect ){
		// return 'portrait'
		return 'portrait'
	} else {
		return 'landscape'
	}

}

export const deviceType = () => {
	
	if( isServer ){
		return 'mouse'
	}

	const isHover         = window.matchMedia(`(hover:hover)`);
	const isHoverNone     = window.matchMedia(`(hover:none)`)
	const isPointerFine   = window.matchMedia(`(any-pointer:fine)`); //should catch ipad with mouse.
	const isPointerCoarse = window.matchMedia(`(pointer:coarse)`);
	const isPointerNone   = window.matchMedia(`(pointer:none)`);

	if( isHoverNone.matches && isPointerCoarse.matches ){
		return 'touch'
	}

	if( isHover.matches && isPointerFine.matches ){
		return 'mouse'
	}

}

export const isMac = () => {
	return navigator?.userAgent?.includes('Mac');
}


export const isFirefox = () => {
	return (/Firefox\/\d+\.\d+/.test(navigator.userAgent));
}

export const isSafari = () => {
	return (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1);
}

export const isChrome = () => {
	return (window.chrome !== null
			&& navigator.userAgent.indexOf('Chrome') != -1 
			&& navigator.vendor === "Google Inc." 
			&& typeof window.opr === "undefined" 
			&& navigator.userAgent.indexOf("Edg") == -1
	);
}

export const isIE = () => {	
	return (/MSIE (\d+\.\d+);/.test(navigator.userAgent));
}

export const isEdge = () => {	
	return /Edge/.test(navigator.userAgent);
}
	
export const isIE8 = () => {	
	var rv = -1; // Return value assumes failure.
	if (isIE())  {
		var re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
		if (re.exec(navigator.userAgent) != null) rv = parseFloat( RegExp.$1 );
	}
	
	return (rv < 9 && rv > 0) ? true : false;
}

export const isIOS = () => {	
	if (navigator.userAgent.match(/i(Phone|Pod|Pad)/i)) {
		return true;	
	} else {
		return false;
	}
}

export const isIOSWebView = () => {	
	// http://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
	var standalone = window.navigator.standalone,
	    userAgent = window.navigator.userAgent.toLowerCase(),
	    safari = /safari/.test( userAgent ),
	    ios = /iphone|ipod|ipad/.test( userAgent );

	if( ios ) {
	    if ( !standalone && safari ) {
	        //browser
	        return false;
	    } else if ( standalone && !safari ) {
	        //standalone
	        return false;
	    } else if ( !standalone && !safari ) {
	        //uiwebview
	        return true;
	    };
	}

	return false;
}

export const stringReplaceMultiple = (str, replacements) => {
	for (var x in replacements) {
		str = str.replace(new RegExp(x, 'g'), replacements[x]);
	}
	return str;
};

// return true if the data passed belongs to an indexable 
// page or set.
export const contentIsIndexable = (content) => {

	if(!content) {
		return false;
	}

	// sets are always indexable
	if(content.page_type === "set") {
		return true;
	}

	// pages are more complicated
	return !(
		content.display === false 
		|| content.pin 
	)

}


export const isMobileWindowSize = width => {
	return width < 770
}

// turn css transform matrix into an object that outlines each transformation specifically
export const parseMatrixStyleString = (matrixString) =>{

	let is2dMatrix = matrixString.indexOf('matrix(') > -1;
	let is3dMatrix = matrixString.indexOf('matrix3d(') > -1;
	let matrix, decomposed;
	if ( is2dMatrix ){
		matrix = matrixString.replace('matrix(', '').replace(')').split(', ').map((val)=>parseFloat(val));
		decomposed = decompose(matrix[0],matrix[1],matrix[2],matrix[3],matrix[4],matrix[5], false);		
	} else if (is3dMatrix){
		matrix = matrixString.replace('matrix3d(', '').replace(')').split(', ').map((val)=>parseFloat(val));
		decomposed = decompose(matrix[0],matrix[1],matrix[4],matrix[5],matrix[12],matrix[13], false);
		decomposed.translate.z = matrix[14];
	} else {
		decomposed = decompose(0, 0, 0, 0, 0, 0, false);
	}

	return decomposed

}

// get transformation object (from decompose) and convert it into rotations + scale
// ignore skew for now, the least-loved of all transforms
// no rotation other than around z
// rotations in radians
export const convertTransformIntoMatrixStyleString = (transforms) => {

	const hasTranslate = transforms.hasOwnProperty('translate');
	const hasScale = transforms.hasOwnProperty('scale');

	const scaleX = hasScale ? transforms.scale.x || 1 : 1;
	const scaleY = hasScale ? transforms.scale.y || 1 : 1;
	const xTranslate = hasTranslate ? transforms.translate.x || 0 : 0;
	const yTranslate = hasTranslate ? transforms.translate.y || 0 : 0;
	const zTranslate = hasTranslate ? transforms.translate.z || 0 : 0;
	const rotation = transforms.rotation || 0;

	const is3d = hasTranslate && transforms.translate.hasOwnProperty('z');
	// write this out in row-major order for sanity's sake, then remap to the column-major css version
	// https://pages.mtu.edu/~shene/COURSES/cs3621/NOTES/geometry/geo-tran.html
	// https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix
	let matrixString
	if ( is3d ) {
		// no zScale, assumed 1
		const matrix = [
			Math.cos(rotation)/scaleX,	-Math.sin(rotation),		0,				xTranslate,
			Math.sin(rotation),			Math.cos(rotation)/scaleY,	0,	 			yTranslate,
			0,							0,							1,				zTranslate,
			0,							0,							0,				1
		]

		matrixString = `matrix3d(${matrix[0]}, ${matrix[4]}, ${matrix[8]}, ${matrix[12]}, ${matrix[1]}, ${matrix[5]}, ${matrix[9]}, ${matrix[13]}, ${matrix[2]}, ${matrix[6]}, ${matrix[10]}, ${matrix[14]}, ${matrix[3]}, ${matrix[7]}, ${matrix[11]}, ${matrix[15]})`

	} else {

		const matrix = [
			Math.cos(rotation)/scaleX,	-Math.sin(rotation),		xTranslate,
			Math.sin(rotation),			Math.cos(rotation)/scaleY,	yTranslate,
			0,							0,							1
		]		

		matrixString = `matrix(${matrix[0]}, ${matrix[3]}, ${matrix[1]}, ${matrix[4]}, ${matrix[2]}, ${matrix[5]})`

	}

	return matrixString

}

export const decompose = (a = 0, b = 0, c = 0, d = 0, e = 0, f = 0, useLU = false) => {

		var acos = Math.acos, // caching for readability below
				atan = Math.atan,
				sqrt = Math.sqrt,
				pi   = Math.PI,

				translate = {x: e, y: f},
				rotation  = 0,
				scale     = {x: 1, y: 1},
				skew      = {x: 0, y: 0},

				determ = a * d - b * c;   // get determinant

		if (useLU) {
				if (a) {
						skew =  {x: atan(c / a), y: atan(b / a)};
						scale = {x: a,           y: determ / a};
				}
				else if (b) {
						rotation = pi * 0.5;
						scale    = {x: b, y: determ / b};
						skew.x   = atan(d / b);
				}
				else { // a = b = 0
						scale  = {x: c, y: d};
						skew.x = pi * 0.25;
				}
		}
		else {
				// Apply the QR-like decomposition.
				if (a || b) {
						var r = sqrt(a*a + b*b);
						rotation = b > 0 ? acos(a / r) : -acos(a / r);
						scale    = {x: r, y: determ / r};
						skew.x   = atan((a*c + b*d) / (r*r));
				}
				else if (c || d) {
						var s = sqrt(c*c + d*d);
						rotation = pi * 0.5 - (d > 0 ? acos(-c / s) : -acos(c / s));
						scale    = {x: determ/s, y: s};
						skew.y   = atan((a*c + b*d) / (s*s));
				}
				else { // a = b = c = d = 0
						scale = {x:0, y:0};     // = invalid matrix
				}
		}

		return {
				scale    : scale,
				translate: translate,
				rotation : rotation,
				skew     : skew
		};
}

// parse CSS value
// cssValueString: string representing CSS value, which can be unitless or a properly-formatted string
// defaultUnit: string representing CSS unit (cm, px, rem, %, etc).
export const getCSSValueAndUnit = (cssValueString, defaultUnit) => {

	cssValueString = cssValueString?.toString() || '';
	cssValueString = cssValueString.replace(/^\.(\d+)/, '0.$1');

	let cssNum = parseFloat(cssValueString.replace(/[^\d.-]/g, ''));
	let cssUnit = cssValueString.replace((cssNum+''), '').trim();
	
	// if the value is unitless, append the unit to the value when it's returned
	if( cssUnit === '' && defaultUnit !== undefined){
		cssUnit = defaultUnit;

	// return the entire string unchanged if we detect a var() or calc() there
	} else if ( cssUnit.indexOf('var(') > -1 || cssUnit.indexOf('calc(') > -1 ){
		cssNum = '';
		cssUnit = cssValueString;
	}

	return [cssNum, cssUnit];

}

export const easeInOutCubic = (x)=> {
	return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
export const easeOutCubic = (x)=> {
	return 1 - Math.pow(-1 * x + 1, 3);
}

export const easeInOutSine = (x)=>{
	if( x >=1){
		return 1
	}
	return -(Math.cos(Math.PI * x) - 1) / 2;
}

// turn values of Gallery/Backdrop options into a easily-used defaults with ranges


			// type: 'checkbox-color',
			// checkbox: {
			// 	labelName: "Color Three",
			// 	name: "color-three-active",
			// 	type: "check-box",
			// 	value: true,
			// },		
			// color: {
			// 	type: 'color',
			// 	labelName: 'Color Three',
			// 	name: 'color-three',
			// 	value: 'rgba(0,0,255,0)',
			// 	requirements: [
			// 		{
			// 			option: 'color-three-active',
			// 			shouldBe: true
			// 		}
			// 	]
			// },



export const collapseOptions = (galleryOptions)=>{
	const optionsObject = {};
	
	const optLoop = (opts)=>{

		opts.forEach((opt)=>{


			if(opt.type == 'color' && opt.tabs){
				opt.tabs.forEach(tab=>{
					optionsObject[tab.name] = tab.value;
				})
			} else if (opt.type == 'checkbox-color'){
				optLoop([opt.checkbox, opt.color]);
			}

			if (opt.value != undefined && opt.value != null){

				optionsObject[opt.name] = {
					value: opt.value
				}

				if( opt.values && (opt.type === 'radio' || opt.type === 'select')){
					optionsObject[opt.name].values = opt.values.map(obj=>obj.value);
				}

				optionsObject[opt.name].type = opt.type;

				if ( opt.excludeFromShuffle !== undefined ){
					optionsObject[opt.name].excludeFromShuffle = opt.excludeFromShuffle
				}

				let defaultUnit;
				if ( opt.defaultUnit !== undefined ){
					defaultUnit = optionsObject.unit = opt.defaultUnit;
				}

				if ( opt.min !== undefined ){
					if (typeof opt.min === 'object'){
						optionsObject[opt.name].min = opt.min[defaultUnit] ?? opt.min['etc'] ?? 0;
					} else {
						optionsObject[opt.name]['min'] = opt.min						
					}

				}
				if ( opt.max !== undefined){
					if (typeof opt.max === 'object'){
						optionsObject[opt.name].max = opt.max[defaultUnit] ?? opt.max['etc'] ?? 0;
					} else {
						optionsObject[opt.name]['max'] = opt.max						
					}
				}
				if (typeof opt.step === 'object'){
					optionsObject[opt.name].step = opt.step[defaultUnit] ?? opt.step['etc'] ?? 1;
				} else {
					optionsObject[opt.name]['step'] = opt.step						
				}
				if( opt.inheritsFrom ){
					optionsObject[opt.name].inheritsFrom = opt.inheritsFrom
				}

				if( opt.unit ){
					optionsObject[opt.name]['unit'] = opt.unit
				}
			}

			if(opt.toggle) {
				optLoop([opt.toggle])
			}

			if ( opt.children ){
				optLoop(opt.children)
			}

		})
	}

	optLoop(galleryOptions);

	return optionsObject;
}


// allows you to assing two or more refs to a single component:
// ref: mergeRefs(refA, refB, ...)
export const mergeRefs = (...refs) => {
	const filteredRefs = refs.filter(Boolean);
	if (!filteredRefs.length) return null;
	if (filteredRefs.length === 0) return filteredRefs[0];
		return inst => {
			for (const ref of filteredRefs) {
				if (typeof ref === 'function') {
				ref(inst);
			} else if (ref) {
				ref.current = inst;
			}
		}
	};
};

let computedStyle;

export const getWidth = el => {

	var computedStyle = getComputedStyle(el, null);

	if ( computedStyle.getPropertyValue('box-sizing') === 'border-box' ) {

		return parseFloat(computedStyle.width)	- (
				(parseFloat(computedStyle.paddingLeft) || 0)
				+ (parseFloat(computedStyle.paddingRight) || 0)
				+ (parseFloat(computedStyle.borderLeftWidth) || 0)
				+ (parseFloat(computedStyle.borderRightWidth) || 0)
			)
		

	} else {
		return parseFloat(computedStyle.width)
	}
	
}

export const getHeight = el => {

	var computedStyle = getComputedStyle(el, null);

	if ( computedStyle.getPropertyValue('box-sizing') === 'border-box' ) {

		return parseFloat(computedStyle.height)	- (
				(parseFloat(computedStyle.paddingTop) || 0)
				+ (parseFloat(computedStyle.paddingBottom) || 0)
				+ (parseFloat(computedStyle.borderTopWidth) || 0)
				+ (parseFloat(computedStyle.borderBottomWidth) || 0)
			)
		

	} else {
		return parseFloat(computedStyle.height)
	}
	
}


export const closest = (el, fn) => {

	var r = el && (fn(el) ? el : closest(el.parentNode, fn));

	if(!r) {
		return null
	}

	return r;

};

export const constrain = (val, min, max) => {
	return val > max ? max : val < min ? min : val;
}

export const isPromoCodeTransitionPeriod = (date) => {
	const currentDate = new Date();
	const givenDate = new Date(date);
  
	// Calculate the difference in milliseconds between the two dates
	const differenceInMilliseconds = givenDate.getTime() - currentDate.getTime();
  
	// Calculate the difference in days
	const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
	// Check if the given date is after the current date and within 15 days
	return differenceInDays > 0 && differenceInDays <= 15;
}

export const filterZoomLinks = (zoomItems) => {

	zoomItems = _.filter(zoomItems, (item) => {
		
		var href = item.getAttribute('href');

		if ( href ){

			if (
				href === '' ||
				href == '#' ||
				href.indexOf('mailto:') == 0 ||
				href.indexOf('tel:') == 0
			){
				return false;
			} 

		}

		return true;
	});

	return zoomItems;
}
