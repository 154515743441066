import { Component, createElement, toChildArray, createRef } from "preact";
import { forwardRef } from 'preact/compat';

import _ from 'lodash';

let queuedYoutubePlayers = new Set();

let youtubeAPIStatus = 'unloaded';
let loadYoutubeAPI = function(component){

	queuedYoutubePlayers.add(component)		

	if( youtubeAPIStatus === 'unloaded'){
		youtubeAPIStatus = 'loading';
		var tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		window.onYouTubeIframeAPIReady = ()=> {
			youtubeAPIStatus = 'loaded';
			queuedYoutubePlayers.forEach((component)=>{
				component.initYoutubePlayer();
			});
		}		
	}



	if( youtubeAPIStatus === 'loaded'){
		component.initYoutubePlayer();
	}


}

let queuedVimeoPlayers = new Set();

let vimeoAPIStatus = 'unloaded';
let loadVimeoAPI = function(component){

	if( vimeoAPIStatus === 'unloaded'){

		vimeoAPIStatus = 'loading';
		var tag = document.createElement('script');
		tag.onload = ()=>{
			vimeoAPIStatus = 'loaded';
			queuedVimeoPlayers.forEach((component)=>{
				component.initVimeoPlayer();
			});
		}
		tag.src = 'https://player.vimeo.com/api/player.js';		
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}
	queuedVimeoPlayers.add(component)

	if( vimeoAPIStatus === 'loaded'){
		component.initVimeoPlayer();
	}

}



class IframeVideo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			apiPlayer: null,
			nativeSize: {
				width: 1,
				height: 1,
			}
		}

		this.html = ''
		this.divRef = createRef();
	}

	render(props){


		const {
			src,
			width,
			height,
		} = props;

		return <div

			onPointerEnter={this.props.onPointerEnter}
			onPointerOut={this.props.onPointerOut}
			dangerouslySetInnerHTML={{__html: this.html}}
			style={{
				position: 'absolute',
				inset: 0,
			}}
			ref={this.divRef}
		>
		</div>		
	}

	setIframeStyles= ()=>{
		let iframeEl = this.divRef.current.querySelector('iframe');
		if( !iframeEl){
			if( this.props.fileType.startsWith('youtube:')){
				// loadYoutubeAPI(this);
			} else if ( this.props.fileType.startsWith('vimeo:')){
				iframeEl = this.state.apiPlayer.element;
			}			
		}

		if(!iframeEl){

			return;
		}

		let style = 'margin: auto; position: absolute; ';


		// if( this.props['image-fit'] === 'fill'){
		// 	const renderRatio = parseFloat(this.props.width)/parseFloat(this.props.height);
		// 	const nativeRatio = this.state.nativeSize.width / this.state.nativeSize.height;
		// 	const diff = renderRatio - nativeRatio;
		// 	const diffRatio = renderRatio/nativeRatio;

		// 	if( diff > 0){
		// 		style+= `inset: ${Math.min(diff*-50, 0)}% 0; height: ${Math.max(100, 100 + diff*100)}%; width: 100%; `;
		// 	} else {
		// 		style+= `inset: 0 ${Math.min(diff*50, 0)}%; width: ${Math.max(100, 100 + -diff*100)}%; height: 100%; `;	
		// 	}

		// }  else {
			style+='width: 100%; height: 100%; inset: 0;'
		// }

		iframeEl.setAttribute('part', 'iframe iframe'+this.props.statusPartAppendix);
		iframeEl.setAttribute('style', style);
	}

	loadAPIPlayer = ()=>{
		if( this.props.fileType.startsWith('youtube:')){
			loadYoutubeAPI(this);
		} else if ( this.props.fileType.startsWith('vimeo:')){
			loadVimeoAPI(this);
		}
	}

	initVimeoPlayer = ()=>{
		if(!queuedVimeoPlayers.has(this)){
			return;
		}
		queuedVimeoPlayers.delete(this);

		while( this.divRef.current.childNodes[0] ){
			this.divRef.current.childNodes[0].remove();
		}


		let videoId = this.props.fileType.replace('vimeo:', '').split('/');
	    // Will create inside the made-in-ny div:
	    const player = new window.Vimeo.Player(this.divRef.current, {
	    	id: videoId.length > 1 ? this.props.src : videoId[0],
	    	h: videoId[1] || undefined,
	    	width: this.props.width || 640,
	    	height: this.props.height || 480,
	    	loop: this.props.loop,
	    	autopause: false,
	    	autoplay: false,
	    	volume: this.props.autoplay || this.props.muted ? 0 : 1,
	    	background: this.props['browser-default'] ? false : true,
	    	pip: false,
	    	speed: true,
	    	portrait: false,
	    	muted: this.props.autoplay || this.props.muted,
	    });
	    player.on('loaded', ()=>{


			player.element?.setAttribute('id', 'vimeo-player');
			player.element?.setAttribute('frameborder', "0");
			this.setIframeStyles();
	    });
	    player.on('play', ()=>{
			this.props.onPlay?.();
	    });	    
	    player.on('pause', ()=>{
			this.props.onPause?.();
	    });	    	    


		this.setState({
			apiPlayer: player
		}, ()=>{

			Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then((dimensions)=>{
		    	player.pause().then(player.setCurrentTime(0));				
			    var width = dimensions[0];
			    var height = dimensions[1];

				this.setState({
					nativeSize: {
						width: width,
						height: height
					}
				})

				if(this.props.onNewPlayer){
					this.props.onNewPlayer({
						play: ()=>{
							return new Promise((resolve, reject)=>{

								this.state.apiPlayer.getPaused((status)=>{
								})
								if( this.props.muted || this.props.autoplay ){
									this.state.apiPlayer.setVolume(0).then(()=>{
										this.state.apiPlayer.play().then(()=>{
											resolve(true)
										}).catch((e)=>{
											reject(e)
										})
									}).catch((e)=>{
										reject(e)
									})
								} else {
									this.state.apiPlayer.play().then(result=>{
										resolve(true)
									}).catch((e)=>{
										reject(e)
									})			
								}

							});
						},
						pause: ()=>{
							return new Promise((resolve, reject)=>{
								this.state.apiPlayer.pause().then(result=>{
									resolve(true)
								})
							});
						},

						setCurrentTime: (val)=>{
							return new Promise((resolve, reject)=>{
								this.state.apiPlayer.setCurrentTime(val).then(result=>{
									resolve(true)
								})
							});
						},
						getCurrentTime: ()=>{
							return new Promise((resolve, reject)=>{
								this.state.apiPlayer.getCurrentTime().then(result=>{
									resolve(result)
								})
							});
						},
						setVolume: (val)=>{
							return new Promise((resolve, reject)=>{
								this.state.apiPlayer.setVolume(val).then(result=>{
									resolve(true)
								})
							});
						},
						getVolume: ()=>{
							return new Promise((resolve, reject)=>{
								this.state.apiPlayer.getVolume().then(result=>{
									resolve(result)
								})
							});
						},
						getDuration: ()=>{
							return new Promise((resolve, reject)=>{
								this.state.apiPlayer.getDuration().then(result=>{
									resolve(true)
								})
							});
						},
						stop: ()=>{							
							return new Promise((resolve, reject)=>{
								Promise.all([player.pause(), player.setCurrentTime(0)]).then(()=>{
									resolve(true)
								})
							})
						},
						setPlaybackRate: (val)=>{
							return new Promise((resolve, reject)=>{
								this.state.apiPlayer.setPlaybackRate(val).then(result=>{
									resolve(true)
								})
							});
						},								
						size: {
							width, height
						},						
					})
				}	

			});			
		})

		


	}

	initYoutubePlayer = ()=>{

		if(!queuedYoutubePlayers.has(this)){
			return;
		}
		queuedYoutubePlayers.delete(this);

		while( this.divRef.current.childNodes[0] ){
			this.divRef.current.childNodes[0].remove();
		}

		// for some reason YT breaks silently when we try to get it to use a pre-made iframe
		// so we let it cast the thing to a div
		const iframe = document.createElement('div');
		
		iframe.setAttribute('id', "youtube-player");
		iframe.setAttribute('allow', "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share");
		iframe.setAttribute('allowfullscreen', '');
		iframe.setAttribute('frameborder', "0");
		iframe.setAttribute('enablejsapi', "true");
		iframe.setAttribute('style', 'display:none;')
		

		this.divRef.current.appendChild(iframe);

		const src = this.props.src.indexOf('?') > -1 ? this.props.src.split('?')[0] : this.props.src;
		
		const ytID = this.props.fileType?.replace('youtube:', '') || null;

		let oembedURL = 'https://cargo.site/_api/v2/proxy/url?url=https://www.youtube.com/oembed?url='+src;
		if( ytID && ytID.length > 0){
			oembedURL = 'https://cargo.site/_api/v2/proxy/url?url=https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v='+ytID;
		}
		
		fetch(oembedURL,{
			mode: 'cors'
		}).then((response) => {
			response.json().then(json=>{

				const width = (json.width*2) || 640;
				const height = (json.height*2) || 480;
				this.setState({
					nativeSize: {
						width: width,
						height: height
					}
				});

				const player = new YT.Player(iframe, {
				    videoId: this.props.fileType.replace('youtube:', ''),
					playerVars:
					{
						autoplay: 0,
						width: width,
						height: height,
						modestbranding: 1,
						loop: this.props.loop,

						// inputting 1 instead of undefined for controls will (for some reason) cause an infinite loader to appear
						controls: (this.props['browser-default'] || !this.props.autoplay) ? undefined : 0,
					    "enablejsapi":1,
					    "origin":document.domain,
					    "rel":0
					},
					events:
					{
					    "onReady":()=>{
							this.setState({
								nativeSize: {
									width: width,
									height: height
								}
							})

							if(this.props.onNewPlayer){
								this.props.onNewPlayer({
									play: ()=>{
										return new Promise((resolve, reject)=>{
											this.state.apiPlayer.playVideo();
											resolve(true);
										})
										
									},
									pause: ()=>{
										return new Promise((resolve, reject)=>{
											this.state.apiPlayer.pauseVideo();
											resolve(true);
										});
									},
									stop: ()=>{
										return new Promise((resolve, reject)=>{
											this.state.apiPlayer.stopVideo()
											esolve(true);
										});
									},
									size: {
										width: width,
										height: height										
									},
									setCurrentTime: (val)=>{
										return new Promise((resolve, reject)=>{
											this.state.apiPlayer.seekTo(val, false);
											resolve(true);
										});
									},
									getCurrentTime: ()=>{
										return new Promise((resolve, reject)=>{
											resolve(this.state.apiPlayer.getCurrentTime() );
										});
									},
									setVolume: (val)=>{
										return new Promise((resolve, reject)=>{					
											this.state.apiPlayer.setVolume(val*100);
											resolve(true);
										});
									},
									getVolume: (val)=>{
										return new Promise((resolve, reject)=>{					
											resolve(this.state.apiPlayer.getVolume()/100);
										});
									},
									getDuration: ()=>{
										return new Promise((resolve, reject)=>{
											resolve(this.state.apiPlayer.getDuration());
										});
									},
									setPlaybackRate: (val)=>{
										return new Promise((resolve, reject)=>{
											this.state.apiPlayer.setPlaybackRate(val);
											resolve(true);
										});
									}				
								})
							}

							// if the iframe is sized to its container initially it'll read the parent element instead of an actual size with an aspect ratio
							// apparently there is no way other to get the actual size of the video.
							// so we make a measurement and then snap the iframe to its correct size
							this.setIframeStyles()					    	
					    },
					    "onStateChange": (event)=>{

						    switch(event.data){
								case YT.PlayerState.ENDED:
								case YT.PlayerState.PAUSED:
									this.props.onPause?.();
									break;

								case YT.PlayerState.PLAYING:
									this.props.onPlay?.();
									break;
						    }
					    }
					}
				})

				this.setState({
					apiPlayer: player
				})


				
			})
		});


	}



	componentDidUpdate(prevProps, prevState){
		if(
			this.props.width !== prevProps.width ||
			this.props.height !== prevProps.height ||
			this.state.nativeSize !== prevState.nativeSize ||
			this.props['image-fit'] !== prevProps['image-fit'] 
		){
			this.setIframeStyles();
		}


		if( this.props.statusPartAppendix !== prevProps.statusPartAppendix){
			const iframe = this.divRef.current?.querySelector('iframe');
			if(iframe){
				iframe.setAttribute('part', 'iframe iframe'+this.props.statusPartAppendix)
			}
		}

		if(
			prevProps.src !== this.props.src ||
			prevProps.autoplay !== this.props.autoplay ||
			prevProps['browser-default'] !== this.props['browser-default'] ||
			prevProps.loop !== this.props.loop ||
			prevProps.muted !== this.props.muted 
		){

			if( prevProps.src ){
				this.props.onNewPlayer(null);				
			}

			clearTimeout(this.initPlayerTimeout);

			// then after the promises clear, unbind everything and load a new player
			this.initPlayerTimeout = setTimeout(()=>{
				if( prevProps.fileType.startsWith('youtube') ){
					this.state.apiPlayer?.destroy();
					this.loadAPIPlayer();
				}
				if( prevProps.fileType.startsWith('vimeo') ){				

				    this.state.apiPlayer.off('loaded');
				    this.state.apiPlayer.off('play');
				    this.state.apiPlayer.off('pause');
					this.state.apiPlayer?.destroy().then(()=>{
						this.loadAPIPlayer();
					});
				}					
			}, 0)
		

		}

	}


	componentDidMount() {

		this.loadAPIPlayer();

	}

	componentWillUnmount(){
		queuedYoutubePlayers.delete(this);
		queuedVimeoPlayers.delete(this);

	}



}


export default 	forwardRef((props, ref) => {
	return <IframeVideo {...props} mediaRef={ref} />;
})

