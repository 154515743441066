import { Component, createRef } from "preact";
import { createPortal } from 'preact/compat';
import EditorOverlay from "./editor-overlay";
import { editorOverlayAPI } from "./editor-overlay-controller";
import { ADMIN_FRAME } from "../../globals";
import { FRONTEND_DATA } from "../../globals";

class EditPageButton extends Component {
	
	constructor(props){
		super(props);
		this.state={
			hover: false,
			buttonHovered: false,
		}
		this.editPageButtonRef = createRef();
		this.layoutButtonRef = createRef();

		if( CargoEditor && Object.keys(CargoEditor || {}).length > 0 ){
			this.bindEditorEvents();
		} else {
			window.addEventListener('CargoEditor-load', this.bindEditorEvents);
		}
	}

	editorIsEmpty = (editor,summary)=>{
		requestAnimationFrame(() => {
			this.setState({
				showEmpty: this.props.isEditingPage
			});
		})
		// let isEmpty = editor.checkIfEmpty();
		// let isFocused = editor.getElement()?.contains(activeRange?.commonAncestorContainer);
	}

	editorHasContent = (editor,summary)=>{
		this.setState({
			showEmpty: false
		})
		// let isEmpty = editor.checkIfEmpty();
		// let isFocused = editor.getElement()?.contains(activeRange?.commonAncestorContainer);
	}

	bindEditorEvents =()=>{
		window.removeEventListener('CargoEditor-load', this.bindEditorEvents);
		this.CargoEditor = CargoEditor;
		this.CargoEditor.events.on('editor-no-content', this.editorIsEmpty);	
		this.CargoEditor.events.on('editor-has-content', this.editorHasContent);		
	}	

	componentWillUnmount=()=>{
		if( this.CargoEditor){
			this.CargoEditor.events.off('editor-no-content', this.editorIsEmpty);
			this.CargoEditor.events.off('editor-has-content', this.editorHasContent);					
		}
	}

	onButtonEnter=()=>{
		this.setState({
			hover: true
		})
	}

	onButtonLeave=()=>{
		this.setState({
			hover: false
		})
	}

	launchUIWindow = (e)=>{
		
		if( e){

			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
		}

		const svgRect = this.layoutButtonRef.current.getBoundingClientRect();
		// const colRect = this.props.columnInstance.getBoundingClientRect();
		const svgPosition = {
			x: svgRect.x + 0,
			y: svgRect.y + -212,
			left: svgRect.left + 0,
			right: svgRect.right + 0,
			top: svgRect.top + 0 + -212,
			bottom: svgRect.top + 27 + -212,
			width: svgRect.width,
			height: svgRect.height
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'layouttemplate',
			// windowAlias: 'columns',
			supportsMobile: true,
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: false,
			// props: {columnNodes: [this.props.columnInstance]}
		});	
	}

// Incomplete: measure a copy of bodycopy with 'Lorem Ipsum...' in it to use the width for measuring and placing the (+) layout template button after

// 	componentDidMount() {
// 		let bodycopyMeasurer = document.createElement('bodycopy');
// 		bodycopyMeasurer.setAttribute('id', 'lorem-ipsum-measurer');
// 		bodycopyMeasurer.innerHTML = 'Lorem Ipsum...';
// 		bodycopyMeasurer.style.position = 'absolute';
// 		bodycopyMeasurer.style.top = '-1000px';
// 		bodycopyMeasurer.style.left = '-1000px';
// 		bodycopyMeasurer.style.display = 'inline-block'
// 		this.props.bodycopyRef.current.parentElement.appendChild(bodycopyMeasurer)
// 
// 		this.domEL = document.getElementById('lorem-ipsum-measurer')
// 	}
// 
// 	componentDidUpdate() {
// 		if (this.domEL) {
// 			this.setState({'lorem-ipsum-width': this.domEL?.getBoundingClientRect().width});
// 		}
// 	}

	render(props){

		const isEditingPage = this.props.isEditingPage;
		const canShowOutlines = this.props.pageIsPin;
		const isEmpty = this.state.showEmpty;
		const buttonHeight = 23;
		
		return <EditorOverlay
			trackResize={true}
			noPointerEvents={true}
			buttonHeight={buttonHeight}
			buttonMode="outside"
			baseNode={this.props.bodycopyRef.current}
			render={(overlayProps)=>{
				const {
					pointerAttention,
					overlayPosition
				} = overlayProps;

				// default position is just above the overlay borders
				let dip = -buttonHeight;
				let xDip = -2;

				// if there isn't enough room for the button
				if( overlayPosition.y <= buttonHeight ){

					// let the button get shifted down into the overlay
					dip = Math.max(0, -overlayPosition.y);
					xDip = 0;

					if( dip > overlayPosition.height ){
						xDip = -2
						dip = overlayPosition.height+1;
					}
				}				

			    return (
			    <>
		    	    {isEditingPage && isEmpty ? 

	    	    	    <div
	    	    	    	className={`editor-overlay editor-outline page-outline hover empty`}
	    	    	    	style={{
	    	    				display: 'block',
	    	    				width: overlayPosition.width+'px',
	    	    				height: overlayPosition.height+'px',
	    	    				transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
	    	    	    	}}			    	
	    	    	    ></div>

		    	    : !isEditingPage ? 

			    	    <div
			    	    	className={`editor-overlay editor-outline page-outline${pointerAttention ? ' hover button-hover': ''}${this.state.showEmpty ? ' hover empty': ''}`}
			    	    	style={{
			    				display: pointerAttention ? 'block': 'none',
			    				width: overlayPosition.width+'px',
			    				height: overlayPosition.height+'px',
			    				transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
			    	    	}}			    	
			    	    >
			    	    	<div 
			    		    	className="in-editor-buttons page"
			    		    	style={{
			    		    		transform: `translate(${xDip}px, ${dip}px)`
			    		    	}}
			    	    	>
				    	    	<div className="pointer-mask">
				    			    <button
				    					className="edit-page text"
				    					onMouseDown={(e)=>{
				    						if( e.button == 2 || e.metaKey){
				    							return;
				    						}
				    						this.props.editPage(e) 
				    					}}
				    					ref={this.editPageButtonRef}
				    				>
				    					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				    						<path fill-rule="evenodd" clip-rule="evenodd" d="M15 16H6V4H10.9875V8.09705H15V16ZM16 7.64491L11.4226 3H5V17H16V7.64491Z" fill="black"/>
				    					</svg>
				    					{this.props.pageTitle}
				    				</button>
				    			</div>
			    			</div>
			    	    </div>

			    	: null }
			    </>
		    	)


			   

		}}/>

	}


}

export default EditPageButton;

