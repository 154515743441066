import _ from 'lodash';
import { helpers } from '@cargo/common';

const embedsNS = {

	embeds: [],

	embedScriptsCache: {},

	loadEmbedScript: (url, onload) => {

		if(embedsNS.embedScriptsCache[url]) {
			return embedsNS.embedScriptsCache[url];
		}

		const promise = new Promise(resolve => {

			const script = document.createElement('script');
			script.setAttribute('src', url);
			script.onload = resolve;

			document.head.appendChild(script);

		});


		embedsNS.embedScriptsCache[url] = promise;

		return promise;

	},

	initializeBandsInTown: function(callback, version){

		var url = 'https://widget.bandsintown.com/main.min.js';

		if(version === 'v3') {
			url = 'https://widgetv3.bandsintown.com/main.min.js'
		}

		embedsNS.loadEmbedScript(url).then(function(){

			if(BIT.widgetInitializer) {
				callback();
			} else {
				BIT.onReady = callback;
			}

		});

	},

	findEmbedsIn: function(node){

		if(!node) {
			return;
		}

		_.each(node.querySelectorAll('cargo-embed'), embedsNS.add, embedsNS);

	},

	add: function(node){

		if(!node || node.nodeType !== Node.ELEMENT_NODE) {
			return;
		}

		var type = node.getAttribute('data-type');

		if(
			!type
			|| !node 
			|| node.nodeType !== Node.ELEMENT_NODE
			|| (embedsNS.embeds.includes(node) && node.children.length > 0)
		) {
			return;
		}

		switch(type) {
			case 'bandsintown': 
				embedsNS.handleBandsIntownEmbed(node);
				break;
		}

	},

	handleBandsIntownEmbed: function(node){

		/**
		<script charset="utf-8"
		src="https://widget.bandsintown.com/main.min.js"></script><a
		class="bit-widget-initializer" data-artist-name="Traverse Town"
		data-display-local-dates="false" data-display-past-dates="true"
		data-auto-style="false" data-text-color="#000000" data-link-color="#2F95DE"
		data-popup-background-color="#FFFFFF" data-background-color="#FFFFFF"
		data-display-limit="15" data-link-text-color="#FFFFFF"></a>
		 */
		
		embedsNS.initializeBandsInTown(() => {

			node.innerHTML = '';

			var options = {};
			var widgetOptions = {};

			_.each(node.attributes, function(attr) {
				if (/^data-/.test(attr.name)) {
					if(attr.name !== "data-type") {

						var underscoredValue = attr.name.substr(5).replace(/-/g, '_');
						options[underscoredValue] = attr.value;

						var camelCasedValue = underscoredValue.split('_').map(function(valuePart, i) {
							if(i > 0) {
								return valuePart.charAt(0).toUpperCase() + valuePart.slice(1);
							}
							return valuePart;
						}).join('');

						widgetOptions[camelCasedValue] = attr.value;
					}
				}
			});

			var target = document.createElement('a');

			target.setAttribute('id', 'bit-widget-' + _.uniqueId());
			target.setAttribute('class', 'bit-widget-initializer');

			_.each(options, function (value, key) {
				target.setAttribute('data-' + key.replace(/_/g, '-'), value);
			});

			node.appendChild(target);

			if(helpers.isAdminEdit) {

				// prevent mouse events and editing of the widget in the admin.
				var observer = new MutationObserver(function(changes){

					_.each(changes, function(mutation){

						if (mutation.type == 'childList') {
							
							mutation.addedNodes.forEach(function(child){
								
								if(child && child.nodeType === Node.ELEMENT_NODE) {
									child.setAttribute('contenteditable', false);
									
									if(child.classList.contains('bit-widget-container')) {
										child.style.pointerEvents = 'none';
										observer.disconnect();
									}
								}

							});

						}
					});

				});

				observer.observe(node, { childList: true });
				
			}

			BIT.widgetInitializer.append(target, widgetOptions);

			embedsNS.embeds.push(node);
			
		}, node.getAttribute('data-bit-widget-version'));

	},

	remove: function(){

	}

}

export default embedsNS;