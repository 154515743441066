import { Component } from "preact";
import { matchPath } from "react-router";
import { bindActionCreators } from 'redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { helpers } from "@cargo/common";
import { actions } from "./actions";
import selectors from "./selectors"
import PinManager from "./components/pin-context";
import Content from "./components/content";
import SiteImages from "./components/site-images"
import _ from 'lodash';

export const paths = {
	ROOT_PATH: '/',
	IMG_PATH: '/siteimages',
	SITE_PREVIEW_PROXY_PATH: '/site.preview/*',
	ADMIN_CLIENT_SIDE_RENDERING_PATH: '/client-side-rendering.html',
	PAGE_PATH: '/:page',
	PAGE_BY_ID_PATH: '/pid/:pid'
}

const overlayPaths = {
	CART: '/cart/:section?/:order_id?',
	CONTACT: '/contact-form'
}

let ContactForm;
let Cart;

if(!helpers.isServer){ 
	ContactForm = require('./components/contact-form').default;
	Cart = require('./components/cart').default;
}

class Routes extends Component {

	constructor(props) {
		
		super(props);
		
		this.checkForOverlayPath(this.props.location);

	}

	shouldComponentUpdate(nextProps) {

		if (this.props.location !== nextProps.location) {
			this.checkForOverlayPath(nextProps.location);
		}

		return true;
	}

	checkForOverlayPath = (nextLocation) => {

		for( const key in overlayPaths ) {

			const path = overlayPaths[key]

			const match = matchPath(nextLocation.pathname, {
				path
			})

			if(match) {

				if(_.isEqual(match, this.lastMatch)) {
					return;
				}

				this.lastMatch = match;

				// lock the underlay location
				if(!this.lockedUnderlayLocation) {
					this.lockedUnderlayLocation = {...this.props.location};

					// if the locked location is our overlay url, load root
					if(this.lockedUnderlayLocation.pathname === match.url) { 
						this.lockedUnderlayLocation.pathname = '/'
					}

				}

				// bail
				return;

			} else {
				
				delete this.lastMatch;

			}

		}

		// if we made it here it means there's no overlay active.
		// If we're still locking the underlay, unlock it and ensure
		// remaining overlays are closed
		if(this.lockedUnderlayLocation) {

			this.lockedUnderlayLocation = null;

		}

	}

	// this will keep rendering old content until new content is available.
	// It's a flawed workaround but will work until react suspense is available.
	deferredContentRenderer(newPageId, idType) {

		// Ignore this on the server
		if(helpers.isServer){
			return <Content id={newPageId} idType={idType} />;
		}

		// use this to keep track of what pageID was last requested in case 
		// we get concurrent async requests.
		this.lastRequestedId = newPageId;
		this.lastRequestedIdType = idType;

		if(!this.currentId) {

			// if we're not currently rendering anything, just render the 
			// content as normal.
			this.currentId = this.lastRequestedId;
			this.currentIdType = this.lastRequestedIdType;

		} else if(
			this.currentId !== this.lastRequestedId
			|| this.currentIdType !== this.lastRequestedIdType
		) {

			window.__deferred_page_load_promise__ = new Promise(resolve => {

				// we are rendering something new, but something else has rendered before.
				// run a content fetch in a deferred function to take it out of the render scope
				// and keep the calling function (render) pure
				_.defer(deferredPageID => {

					this.props.fetchContent(deferredPageID, {
						idType
					})
					.catch(() => {})
					.finally(() => {

						// we have the new content (or not). Make sure it's our most up to date request and render it. 
						if(this.lastRequestedId === deferredPageID) {
							this.currentId = this.lastRequestedId;
							this.currentIdType = this.lastRequestedIdType;
							this.forceUpdate();
						}

						delete window.__deferred_page_load_promise__;
						resolve();

					})

				}, this.lastRequestedId);

			});

			// continue and render the old content again while loading
		}

		return (
			<PinManager>
				<Content 
					id={this.currentId} 
					idType={this.currentIdType} 
					hashPurl={this.props.hashPurl} 
				/>
			</PinManager>
		)
	}

	componentDidUpdate = () => {

		if (this.props.location?.pathname === '/' || this.props.location?.pathname.includes( this.props.homepageId ) ) {
			document.querySelector('body').classList.add('home')
		} else {
			document.querySelector('body').classList.remove('home')
		}
	}

	render() {

		if(this.props.adminMode && this.props.adminInitialized === false) {
			// don't attempt to do any routing before the admin has initialized. This
			// is because we don't want to render content before the CRDT has been loaded
			// and run the chance of rendering stale live data.
			return null;
		} 

		if(!this.props.hasSite) {
			return null;
		}

		let overlay = null;
		let overlayLocation = null;
		let underlayLocation = this.props.location;

		if(this.lockedUnderlayLocation) {

			underlayLocation = this.lockedUnderlayLocation;
			overlayLocation = this.props.location;

			overlay = <div class="overlay">
				<Switch location={overlayLocation}>
					
					<Route exact path={overlayPaths.CART} render={({match})=> {
						return <Cart closeOverlay={() => {
							this.props.history.push(this.lockedUnderlayLocation.pathname, {preventScrollReset: true})
						}}/>;
					}}/>

					<Route exact path={overlayPaths.CONTACT} render={({match})=> {
						return <ContactForm closeOverlay={() => {
							this.props.history.push(this.lockedUnderlayLocation.pathname, {preventScrollReset: true})
						}} />;
					}}/>

				</Switch>
			</div>

		}

		return(
			<>

				{overlay}

				<Switch location={underlayLocation}>
					<Route exact path={paths.ROOT_PATH} render={({match})=> {

						if(this.props.isFeed) {
							return this.deferredContentRenderer('root', 'pid');
						}

						return this.deferredContentRenderer(this.props.homepageId || 'root', 'pid');
					}}/>

					<Route exact path={paths.IMG_PATH} render={({match})=> {

						return <SiteImages />

					}}/>

					<Route exact path={paths.SITE_PREVIEW_PROXY_PATH} render={({match})=> {

						if(this.props.isFeed) {
							return this.deferredContentRenderer('root', 'pid');
						}

						return this.deferredContentRenderer(this.props.homepageId || 'root', 'pid');
					}}/>

					<Route exact path={paths.ADMIN_CLIENT_SIDE_RENDERING_PATH} render={({match})=> {
						// Avoid a flash of content when loading admin edit links directly
						return null;
					}}/>

					<Route exact path={paths.PAGE_PATH} render={({match})=> {
						return this.deferredContentRenderer(match.params.page.toLowerCase(), 'purl');
					}}/>

					<Route exact path={paths.PAGE_BY_ID_PATH} render={({match})=> {
						return this.deferredContentRenderer(match.params.pid, 'pid');
					}}/>

					<Route path="*" render={({location}) => {
						return this.deferredContentRenderer(location.pathname.substring(1), 'purl');
					}} />
				</Switch>
			</>
		)

	}

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchContent: actions.fetchContent,
		updateFrontendState: actions.updateFrontendState
	}, dispatch);

}


export default withRouter(connect(
	(state, ownProps) => {

		const hashPurl = helpers.isServer ? null : window.location.hash.substring(1).toLowerCase();

		return {
			hasSite: !!state.site,
			homepageId: selectors.getHomepageId(state),
			isFeed: state.sets.byId.root.stack,
			adminInitialized: state.adminState?.initialized || false,
			adminMode: state.frontendState.adminMode,
			hashPurl
		};
	}, 
	mapDispatchToProps
)(Routes));
