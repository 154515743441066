import axios from 'axios';
import { each, merge, isArray} from 'lodash';

import { PENDING, FULFILLED, REJECTED } from "../middleware/api";

import { helpers, API_ORIGIN } from "@cargo/common";

import selectors from "../selectors";
import _ from 'lodash';

const actions = {};

const API_REQUEST_TIMEOUT = helpers.isServer ? 30000 : 10000;

const RESTfulActionTypes = {
	AUTHENTICATE_USER: 'AUTHENTICATE_USER',
	FETCH_SITEDESIGN_MODEL: 'FETCH_SITEDESIGN_MODEL',
	FETCH_SCAFFOLDING: 'FETCH_SCAFFOLDING',
	FETCH_SITE_PACKAGE: 'FETCH_SITE_PACKAGE',
	FETCH_SITE_MODEL: 'FETCH_SITE_MODEL',
	FETCH_SITE_CSS: 'FETCH_SITE_CSS',
	FETCH_MEDIA: 'FETCH_MEDIA', 
	FETCH_CONTENT: 'FETCH_CONTENT',
	DELETE_CONTENT: 'DELETE_CONTENT',
	FETCH_COMMERCE_PRODUCTS: 'FETCH_COMMERCE_PRODUCTS',
	FETCH_SHOP_MODEL: 'FETCH_SHOP_MODEL'
}

// extend default action types with FULFILLED, PENDING and REJECTED statuses
each(RESTfulActionTypes, function(key, val, obj){

	obj[key + '_' + FULFILLED] 	= val + '_' + FULFILLED;
	obj[key + '_' + PENDING] 	= val + '_' + PENDING;
	obj[key + '_' + REJECTED] 	= val + '_' + REJECTED;

});

const actionTypes = merge({
	ADD_COMMERCE_PRODUCT_TO_CART: 'ADD_COMMERCE_PRODUCT_TO_CART',
	RESET_COMMERCE_CART: 'RESET_COMMERCE_CART',
	SET_INITIAL_AUTH: 'SET_INITIAL_AUTH',
	PAGES_MOUNTED: 'PAGES_MOUNTED',
	PAGES_UNMOUNTED: 'PAGES_UNMOUNTED',
	UPDATE_FRONTEND_STATE: 'UPDATE_FRONTEND_STATE',
	COMMERCE_PRODUCT_DESTROYED: 'COMMERCE_PRODUCT_DESTROYED',
	COMMERCE_PRODUCT_UPDATED: 'COMMERCE_PRODUCT_UPDATED',
	SHOP_MODEL_UPDATED: 'SHOP_MODEL_UPDATED'
}, RESTfulActionTypes);

actions.getAuthToken = function( options = {} ) {

	const promise = new Promise((resolve, reject) => {

		const AUTH_PATH = CARGO_ENV !== "production" ?
			'https://dev.cargo.site/accesstoken' :
			'https://cargo.site/accesstoken';

		if(options.reverse_bounce_jwt) {
			// bounce back to cargo.site to login there as well
			parent.location.href = AUTH_PATH + '/bounce?jwt=' + options.reverse_bounce_jwt;
			// don't resolve the promise. Let it sit while we navigate away.
			return;
		}


		if(options.bounce) {
			// bounce the parent frame to grab login from cargo.site
			parent.location.href = AUTH_PATH + '/bounce?site_url=' + options.site_url;
			// don't resolve the promise. Let it sit while we navigate away.
			return;
		}

		const urlParams = {};

		if(options.site_url) {
			urlParams.site_url = options.site_url;
		}

		if(options.site_id) {
			urlParams.site_id = options.site_id;
		}

		if(options.jwt) {
			// run a jwt token based auth call
			urlParams.jwt = options.jwt;
		}

		const headers = {}
		if (options.nonce) {
			headers['x-cargo-access-token-nonce'] = options.nonce;
		}

		axios.get(AUTH_PATH, {
			use_auth: false,
			withCredentials: true,
			params: urlParams,
			headers: headers,
			timeout: options?.timeout || API_REQUEST_TIMEOUT
		})
		.then(function(response) {

			if(!response || response.token === null) {
				return reject('No token found.');
			}

			resolve(response)

		})
		.catch(e => {
			reject(e);
		});

	})

	return {
		type: actionTypes.AUTHENTICATE_USER,
		payload: promise
	}

}

actions.setAuthentication = authData => {

	return {
		type: actionTypes.AUTHENTICATE_USER_FULFILLED,
		payload: authData
	}

}

actions.rejectAuthentication = () => {

	return {
		type: actionTypes.AUTHENTICATE_USER_REJECTED,
		payload: {}
	}

}

actions.fetchScaffolding = function() {

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/pages/${state.site.id}/scaffolding`;

		return dispatch({
			type: actionTypes.FETCH_SCAFFOLDING,
			payload: axios.get(API_ORIGIN + PATH)
		});

	}

}

actions.fetchFiles = function() {

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/sites/${state.site.id}/media/files`;

		return dispatch({
			type: actionTypes.FETCH_MEDIA,
			payload: axios.get(API_ORIGIN + PATH)
		});

	}

}

actions.fetchImageLibrary = function(options = {}) {

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/sites/${state.site.id}/media/images`;

		return dispatch({
			type: actionTypes.FETCH_MEDIA,
			payload: axios.get(API_ORIGIN + PATH, {
				use_cache: options.force === true ? false : true
			})
		});

	}

}

actions.fetchCommerceProducts = function(options = {}) {

	options = _.defaults(options, {
		orderBy: 'date_added',
		orderDir: 'asc',
		idArray: null,
		onlyNew: true
	})

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/commerce/${state.site.id}/products`;
		let result;

		if(options.idArray) {

			// different path
			PATH += '/list'

			const formData = new FormData();

			if(options.idArray) {

				const idsToLoad = options.idArray.filter(id => {

					if(
						options.onlyNew === true 
						&& state.commerce.products.hasOwnProperty(id)
					) {
						// product already exists, don't load it.
						return false
					}

					return true;
				});

				if(idsToLoad.length === 0) {
					// do nothing
					return Promise.resolve();
				}

				idsToLoad.forEach(id => {
					formData.append('ids[]', id);
				});

			}

			result = axios.post(API_ORIGIN + PATH, formData, {
				// this is public data. No auth required
				use_auth: false
			});

		} else {

			result = axios.get(API_ORIGIN + PATH, {
				// this is public data. No auth required
				use_auth: false,
				params: {
					order_by: options.orderBy,
					order_dir: options.orderDir,
					limit: options.limit
				}
			});

		}


		return dispatch({
			type: actionTypes.FETCH_COMMERCE_PRODUCTS,
			payload: result,
			meta: {
				options
			}
		});

	}

}

actions.fetchShopModel = function(options = {}) {

	return function(dispatch, getState) {

		const state = getState();
		let PATH = `/commerce/${state.site.id}/shop/${state.site.shop_id}`;

		return dispatch({
			type: actionTypes.FETCH_SHOP_MODEL,
			payload: axios.get(API_ORIGIN + PATH, {
				use_cache: options.force === true ? false : true,
			})
		});

	}

}

actions.resetCommerceCart = function() {
	
	return {
		type: actionTypes.RESET_COMMERCE_CART,
		payload: {}
	}

}

actions.addCommerceProductToCart = function(productId, variantId, options = {}){

	return function(dispatch, getState) {

		const state = getState();
		const product = state.commerce.products[productId];
		const variant = product?.variants.find(variant => variant.variant_id == variantId);


		if(!product || !variant) {
			// Product not found -- pop alert modal
			options.failureCallback?.('Product not found');
			return;
		}

		if(options.amount === undefined || isNaN(options.amount)) {
			options.amount = 1;
		}

		if(variant.inventory !== null && options.amount > variant.inventory) {

			if(variant.inventory <= 0) {
				// Nothing available -- pop alert modal
				options.failureCallback?.('Product is out of stock');
				// don't continue
				return;

			} else {
				// lower the amount to what's available
				options.amount = variant.inventory;
			}

		}

		if (typeof options.callback === 'function') {
			options.callback();
		}
	
		return dispatch({
			type: actionTypes.ADD_COMMERCE_PRODUCT_TO_CART,
			payload: {
				product_id: variant.product_id,
				variant_id: variant.variant_id,
				quantity: options.amount
			}
		});

	}

}

let debouncedPageMountPromise = null;
let debouncingPageUnmounted = false;

let pendingMountedPages = [];
let pendingUnMountedPages = [];

actions.pageMounted = function(pageEl){

	// page was pending to be marked as unmounted but got mounted again before the queue got flushed.
	if(pendingUnMountedPages.includes(pageEl)) {
		pendingUnMountedPages = pendingUnMountedPages.filter(page => page !== pageEl)
	}

	if(pendingMountedPages.includes(pageEl)) {
		// already pending mounting. Don't handle further
		return;
	}

	return (dispatch, getState) => {

		pendingMountedPages.push(pageEl);

		if(!debouncedPageMountPromise) {
			
			debouncedPageMountPromise = new Promise(resolve => {

				requestAnimationFrame(() => {

					dispatch({
						type: actionTypes.PAGES_MOUNTED,
						payload: {
							pages: [...pendingMountedPages]
						}
					});

					resolve();

					debouncedPageMountPromise = null;
					pendingMountedPages = [];

				});

			});

		}

		return debouncedPageMountPromise;

	}

}

actions.pageUnMounted = function(pageEl){

	// Page was being mounted but got unmounted before the queue was flushed.
	if(pendingMountedPages.includes(pageEl)) {
		pendingMountedPages = pendingMountedPages.filter(page => page !== pageEl)
	}

	if(pendingUnMountedPages.includes(pageEl)) {
		// already pending unmounting. Don't handle further
		return;
	}

	return (dispatch, getState) => {

		// instantly report unmounting of currently edited page
		const state = getState();

		if(state.frontendState.PIDBeingEdited === pageEl?.id) {
			return dispatch({
				type: actionTypes.PAGES_UNMOUNTED,
				payload: {
					pages: [pageEl]
				}
			});
		}

		pendingUnMountedPages.push(pageEl);

		if(!debouncingPageUnmounted) {
			
			debouncingPageUnmounted = true;
			
			requestAnimationFrame(() => {

				dispatch({
					type: actionTypes.PAGES_UNMOUNTED,
					payload: {
						pages: [...pendingUnMountedPages]
					}
				});

				debouncingPageUnmounted = false;
				pendingUnMountedPages = [];

			});

		}

	}

}


actions.fetchSiteModel = function() {

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/sites/${state.site.id}`;

		const request = axios.get(API_ORIGIN + PATH);

		return dispatch({
			type: actionTypes.FETCH_SITE_MODEL,
			payload: request
		});

	}

}

actions.fetchSiteCSS = function() {

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/sites/${state.site.id}/css`;

		const request = axios.get(API_ORIGIN + PATH);

		return dispatch({
			type: actionTypes.FETCH_SITE_CSS,
			payload: request
		});

	}

}

actions.fetchSitePackage = function() {

	return function(dispatch, getState) {

		const state = getState();

		let hostname;

		if(!helpers.isServer) {
			// helpers will have the corresponding hostname when in a site preview, otherwise default to window location
			hostname = (helpers.isSitePreview) ? helpers.sitePreviewHostname : window.location.hostname;
		} else {
			// server process will inject the request hostname here
			hostname = state.frontendState.hostname
		}

		// handle localcargo.site for local development
		if (helpers.isLocalEnv) {
			// yoursite.local.dev.cargo.site -> yoursite.dev.cargo.site
			hostname = hostname.replace('local.dev.cargo.site', 'dev.cargo.site');
		}

		const request = axios.get(API_ORIGIN + `/package/${hostname}`);

		return dispatch({
			type: actionTypes.FETCH_SITE_PACKAGE,
			payload: request
		});

	}

}


actions.fetchSiteDesignModel = function() {

	return function(dispatch, getState) {

		const state = getState();

		let PATH = `/sites/${state.site.id}/design`;

		const request = axios.get(API_ORIGIN + PATH);

		return dispatch({
			type: actionTypes.FETCH_SITEDESIGN_MODEL,
			payload: request
		});

	}

}

actions.fetchPagesByTag = function(tagUrl, options = {}) {

	return function(dispatch, getState) {

		const state = store.getState();
		const request = axios.get(API_ORIGIN + `/pages/${state.site.id}/tag/${tagUrl}`, {
			withCredentials: true,
		});

		return dispatch({
			type: actionTypes.FETCH_CONTENT,
			payload: request
		});

	};

}

actions.fetchContent = function(id, options = {}) {

	if(!options.idType) {
		options.idType = 'pid';
	}

	return function(dispatch, getState) {

		const state = getState();
		let newIndexMap;

		// when in admin mode, remap indexes 
		if(options.indexes && state.frontendState.inAdminFrame === true) {

			// create a map with the id / index pairs for all contents of the set as it appears
			// in the draft store
			const draftIndexesForParent = state.structure.byParent[id]?.reduce((acc, id) => {
				acc[state.structure.indexById[id]] = id;
				return acc;
			}, {});

			if(draftIndexesForParent) {

				newIndexMap = {};
				
				options.indexes.forEach(index => {

					// get the PID that belongs to the index we are trying to load
					const pidBelongingToDraftIndex = draftIndexesForParent[index];

					// grab the live parent / index pair for this PID
					const liveParentAndIndexPair = state.structure.liveIndexes[pidBelongingToDraftIndex];

					if(liveParentAndIndexPair) {

						const [setId, index] = liveParentAndIndexPair.split('/');

						if(!newIndexMap[setId]) {
							newIndexMap[setId] = [];
						}

						// store the live location in a map
						newIndexMap[setId].push(parseInt(index));

					}

				});

				// map the result into an array of objects for the api
				// [{"set_id":"xxx":"index":[0,1,2]}, ...]
				newIndexMap = _.map(newIndexMap, (index, set_id) => ({
					set_id, 
					index
				}));

			}


		}
		
		// if the id already exists, just return a successful fetch
		if(
			options.force !== true
			&& (
				options.idType === 'purl' ?
					selectors.getContentByPurl(state, id) 
					: selectors.getContentById(state, id)
				|| id === 'root'
			)
			&& (options.indexes === undefined || options.indexes.length === 0)
			&& (options.prev !== true && options.next !== true)
		) {
			
			return dispatch({
				type: actionTypes.FETCH_CONTENT,
				payload: Promise.resolve()
			});

		}

		// default to fetching content by purl
		let PATH = `/pages/${state.site.id}/url/${id}`;

		if(options.next === true) {
			// fetch the page next to this pid (used for prev/next links)
			PATH = `/pages/${state.site.id}/next/${id}`
		} else if(options.prev === true) {
			// fetch the page previous to this pid (used for prev/next links)
			PATH = `/pages/${state.site.id}/prev/${id}`
		} else if(options.idType === 'pid') {
			// fetch by id
			PATH = `/pages/${state.site.id}/id/${id}`
		}

		const requestParams = {};

		if(isArray(options.indexes)) {
			
			PATH = `/pages/${state.site.id}/filter/`;

			if(newIndexMap && Object.keys(newIndexMap).length > 0) {
				
				// console.log('actions.fetchContent: using remapped index pairs', newIndexMap);
				requestParams.indexBySet = JSON.stringify(newIndexMap)

			} else {

				requestParams.indexBySet = JSON.stringify([{
					set_id: id,
					index: options.indexes
				}]);
	 			
	 		}

		}

		const request = axios.get(API_ORIGIN + PATH, {
			withCredentials: true,
			use_cache: options.force === true ? false : true,
			params: requestParams,
			validateStatus: function (status) {
				return status >= 200 && status < 300 && status !== 204;
			},
			contentIdentifier: {
				idType: options.idType,
				id
			}
		});

		const promise = new Promise((resolve, reject) => {

			request.then(result => {

				if(!isArray(result.data)) {
					result.data = [result.data]
				}

				options.cb?.(result);
				
				resolve(result);
			
			}).catch(reject);

		});

		return dispatch({
			type: actionTypes.FETCH_CONTENT,
			payload: promise
		});
	}

}

actions.updateFrontendState = function(changes){

	return function(dispatch, getState) {
		return dispatch({
			type: actionTypes.UPDATE_FRONTEND_STATE,
			payload: changes
		})

	}

}


actions.deleteContent = function(contentID){

	return function(dispatch, getState) {
	
		const request = new Promise().resolve({
			id: contentID
		});

		return {
			type: actionTypes.DELETE_CONTENT,
			payload: request
		}
	}
}

if(!helpers.isServer) {
	window.actions = actions;
}

export {
	actionTypes,
	actions
}