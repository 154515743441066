import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { TooltipContext } from "@cargo/common";
import './badge.scss';
import { letters } from "@cargo/common/users";

export class Badge extends Component {

	constructor(props) {
		super(props);
		this.badgeRef = React.createRef();
	}

	render() {

		const {
			label,
			key,
			user,
			tooltip,
			toolTipIcon,
			onPointerDown,
			onPointerEnter,
			onPointerLeave,
			...otherProps
		} = this.props;

		return !user.role ? null : (
			<TooltipContext.Consumer>
			    {(Tooltip) => 
					<button
						{...otherProps}
						className={`badge ${ this.props.className ? this.props.className : ''}`}
						ref={this.badgeRef}
						onPointerEnter={(e) => {

							if (onPointerEnter) {
								onPointerEnter(e)
							}

							if( !this.props.tooltip ){ return }


							let buttonPos = this.badgeRef.current.getBoundingClientRect();

							Tooltip.openTip?.({
							    innerText: `<div class="tooltip-email" style="padding-top: 2px; padding-bottom: 1px;">${user.full_name ? user.full_name : user.email }</div> <div style="padding-bottom: 2px; font-style: italic;">${user?.role}</div>`,
							    shortcutText: null,
							    icon: toolTipIcon,
							    buttonPos: {
							    	x: buttonPos.x,
							    	y: buttonPos.y,
							    	width: buttonPos.width,
							    	height: buttonPos.height,
							    },
							    event: e,
							})
							
						}}
						onPointerLeave={(e) => { 

							if (onPointerLeave) {
								onPointerLeave(e)
							}

							if( !this.props.tooltip ){ return } 
							Tooltip.closeTip?.(e) 


						}}
						onPointerDown={(e) => { 

							if (this.props.tooltip) {
								Tooltip.closeTip?.(e)
							}

							// run the button's original pointerDown method
							if (onPointerDown) {
								onPointerDown(e)
							}

						}}
					>
						<>
							<span 
								className={`icon${user.color ? ' filled' : ''}${onPointerDown ? ' clickable' : ''}`}
								user-role={user?.role?.toLowerCase()}
								style={{'color': user.color}}
							>
								{user.full_name ? 
									letters[user.full_name.charAt(0).toLowerCase()]
									: letters[user.email?.charAt(0).toLowerCase()]
								}
							</span>
						</>
					</button>
				}
			</TooltipContext.Consumer>
		);

	}

};