import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';


const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'slitscan',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},		
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},			
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},		
		{
			labelName: undefined,
			name: "orientation",
			type: "radio",
			value: "horizontal",
			values: [
				{
					labelName: 'Horizontal',
					value: 'horizontal'
				},
				{
					labelName: 'Vertical',
					value: 'vertical'
				}				
			],
		},
		{
			labelName: "Scan Size",
			name: "scan_size",
			type: "range-input",
			value: 90,
			min: 0,
			max: 100,
			step: .1,
		},	
		{
			labelName: "Short Wave",
			name: "scan_cycles",
			type: "range-input",
			value: 1,
			min: 0,
			max: 20,
			step: .1,
		},	
		{
			labelName: "Wiggle",
			name: "wiggle",
			type: "range-input",
			value: 20,
			min: 0,
			max: 100,
			step: .1,
		},			

		{
			labelName: "Speed",
			name: "target_speed",
			type: "range-input",
			value: 17,
			min: -100,
			max: 100,
			step: 1,
		},	
		{
			labelName: "Mouse Interaction",
			name: "mouse_interaction",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Scroll Interaction",
			name: "scroll_interaction",
			type: "check-box",
			value: false,
		},		
		{
			labelName: "Overlay Color",
			name: "color",
			type: "color",
			value: 'transparent',
			excludeFromShuffle: true,
		}	
	]
}
settings.transformChanges= (settings, changes)=>{

	if(changes.color) {
		const color = tinycolor(changes.color);

		changes['hex'] = color.toHexString();
		changes['alpha'] = color.getAlpha();
	}

	return changes;
}
settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.alpha = {
	value: 0
}
settings.defaults.hex = {
	value: '#000000'
}

export default settings;
export {onChangeCustom}
