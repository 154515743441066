import React, {Component, Fragment, useState, useRef, useEffect } from 'react';
import { deviceType } from "@cargo/common/helpers";
import _ from 'lodash';


export const AutofillInput = ({field, className, parentClassName, form, label, placeholder, type, icon, iconClass, noBars, barLeft, barRight, followText, focusOnInit, suggestionArray, onKeyPress, onEnter, ...props }) => {

		let dataValue = !field.value || field.value === "" || field.value.length === 0 ? placeholder : field.value;

		const inputRef = useRef();
		const measureRef = useRef();

		const isTouch = deviceType() === 'touch';
		const [focused, setFocus ] = useState(false);

		const [suggestion, setSuggestion ] = useState('');
		const [visibleHint, setHint ] = useState('');
		const [style, setStyle ] = useState();
		const [measureValue, setMeasureValue ] = useState();

		useEffect(() => {
			if( focusOnInit ){
				window.requestAnimationFrame(()=>{
					focusInputEnd();
				})
			}
		},[])

		// Track previous value prop
		const prevValueRef = useRef();
		// This use Effect tracks current value vs. previous value
		// On submit or clearing of the input, we remeasure and make sure the 
		// input field isn't too short / not accomidating the width of our placeholder value.
		useEffect(() => {
			// Check prev value against current value
			if( field?.value?.length === 0 && prevValueRef?.current?.length >= 1 ){
				setMeasureValue('')
				setSuggestion('')
				setHint('')
				manualRemeasure()
			}

			// field?.value === prevValueRef?.current+visibleHint

			//assign current value to prev value ref
			prevValueRef.current = field.value;
		}, [field.value]); //run when the value of field changes

		// Manage event binding
		useEffect(() => {
			// Sets autofill input from outside. 
			function setAutofillValue(e){
				measureRef.current.innerText = e.detail.message

				const rect = measureRef?.current.getBoundingClientRect();
				let setWidth = rect.width + 1;
				if( isTouch ){
					setWidth += 1;
				}
			 	setStyle({ width: setWidth+'px' })

				form.setFieldValue(
					field.name, 
					e.detail.message, 
					false
				)

				focusInputEnd()
				setSuggestion('')
				setHint('')
			}

			document.addEventListener('set-autofill-from-parent', setAutofillValue)
		  return () => {
		  	// cleanup
		  	document.removeEventListener('set-autofill-from-parent', setAutofillValue);
		  };
		}, []);

		const manualRemeasure = () => {
			if( inputRef?.current.value.length === 0 ) {
				measureRef.current.innerText = placeholder
			}

			if( inputRef?.current.value.length >= 1 ){
				const rect = measureRef?.current.getBoundingClientRect();
				let setWidth = rect.width + 1;
				if( isTouch ){
					setWidth += 1;
				}
			 	setStyle({ width: setWidth+'px' })
			} else { 
				setStyle({undefined}) 
			}

		}

		//Activates scroll behavior on successful type to search match
		const deriveSuggestion = ( value ) => {
			//Returns first match found in list...
			let suggestion = _.find(suggestionArray, function(suggestionString){

				let inputValLength = value.length;
				let matchThis = suggestionString.substring(0, inputValLength);

				if ( matchThis.toUpperCase() == value.toUpperCase() ){
					return suggestionString
				}
			});

			if( suggestion ){
				let visibleHint = suggestion.substring(value.length, suggestion.length );
				setHint( visibleHint )
				setSuggestion( suggestion )
			} else {
				setHint( '' )
				setSuggestion( '' )
			}

		}

		const focusInputEnd = () => { 
		    let inputLength = inputRef?.current?.value?.length || 0;
		    if( inputRef?.current && inputLength !== undefined ){
		        inputRef?.current.focus();
		        inputRef?.current.setSelectionRange(inputLength, inputLength+1)
		    }
		}

		const inputEl = (
			<div 
				className={`text-input autosize suggest${parentClassName ? ' '+parentClassName : ''}`} 
				onPointerUp={(e)=>{ 
					if( inputRef.current && !isTouch ){
         				inputRef.current.focus() 
        			}
        		}}
        		onClick={(e)=>{
        			if( inputRef.current && isTouch ){
        				inputRef.current.focus()
        			}
        		}}
			>
				<label 
					className={`input-auto-size${focused ? ' focus' : ''}`} 
					data-value={ dataValue }
				>
					<input 
						type="text" 
						onChange={e => { 
							measureRef.current.innerText = e.currentTarget.value;

							if( inputRef?.current.value.length === 0 ) {
								measureRef.current.innerText = placeholder
							}

							if( inputRef?.current.value.length >= 1 ){
								const rect = measureRef?.current.getBoundingClientRect();
								let setWidth = rect.width + 1;
								if( isTouch ){
									setWidth += 1;
								}
							 	setStyle({ width: setWidth+'px' })
							} else { 
								setStyle({undefined}) 
							}

							if( e.target.value.trim() === '' || e.target.value.length === 0 ){
								setSuggestion('');
								setHint('');
							} else {
								deriveSuggestion(e.currentTarget.value);
							}

							if ( form ) {
								form.setFieldValue(
									field.name, 
									e.currentTarget.value, 
									false
								)
							}

						}} 
						value={field.value}
						size="1" 
						placeholder={placeholder}
						style={ style }
						ref={ inputRef }
						onFocus={() => setFocus(true)}
						onBlur={()=> setFocus(false)}
						onKeyDown={(e)=>{

							if( e.key === 'Tab' ){
								e.preventDefault();
								if ( form && suggestion && suggestion.length > 0 ) {


									measureRef.current.innerText = suggestion

									const rect = measureRef?.current.getBoundingClientRect();
									let setWidth = rect.width + 1;
									if( isTouch ){
										setWidth += 1;
									}
								 	setStyle({ width: setWidth+'px' })

									form.setFieldValue(
										field.name, 
										suggestion, 
										false
									)

									focusInputEnd()
									setSuggestion('')
									setHint('')
								}
							}

							if( e.key === 'Enter' && form && suggestion && suggestion.length > 0 ){
								e.preventDefault();
								// e.stopPropagation();
								measureRef.current.innerText = suggestion

								const rect = measureRef?.current.getBoundingClientRect();
								let setWidth = rect.width + 1;
								if( isTouch ){
									setWidth += 1;
								}
							 	setStyle({ width: setWidth+'px' })

								form.setFieldValue(
									field.name, 
									suggestion, 
									false
								)

								setMeasureValue('')
								setSuggestion('')
								setHint('')

							}

							if( e.key === 'Enter' 
								&& form 
								&& onEnter 
								&& ( !suggestion || suggestion?.length === 0 )
							){
								onEnter(e)
							}

							
						}}
						onKeyPress={(e) => { onKeyPress(e) }}

					/>

					<span>
						<div className="visible-hint">
							{ visibleHint }
						</div>
					</span>
				</label>
				<span 
					ref={ measureRef }
					className="measure suggest"
				>{ measureValue }</span>
			</div>
		)

		if( label ){
			return(
				<div className={`label-input-group${className ? ' '+className : ''}`}>
					<div className={`input-label ${noBars ? '' : 'bars'}`} >
						{ label }
					</div>
					{ inputEl }
				</div>
			)
		}

		if( icon ){
			return(
				<div className={`input-icon-group${className ? ' '+className : ''}`}>
					{ inputEl }
					<div className={`input-icon ${iconClass ? iconClass : ''}`} >
						{ icon }
					</div>
				</div>
			)
		}

		return inputEl

}

