import { Component, createRef } from "preact";
import { createPortal } from 'preact/compat';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import { store } from '../index';
import { API_ORIGIN, helpers } from "@cargo/common";
import axios from 'axios';
import _ from "lodash";

// Mobile resize handler
const instances = {};
let mediaQuery = window.matchMedia('(max-aspect-ratio: 1/1)');
let isMobile = mediaQuery.matches;

const matchListener = mediaQuery.addEventListener('change', (e) => {

	isMobile = e.matches;
	if( instances['1'] ) {
		instances['1'].setState({ isMobile })
	}

});

class Cart extends Component {

	constructor(props){

		super(props);

		const shop_id = store.getState().site.shop_id;

		if(shop_id === null) {
			this.closeOverlay();
			return;
		}

		this.props.fetchShopModel().then(() => {

			const shopModel = store.getState().commerce.shop;

			if(_.isEmpty(shopModel)) {
				this.closeOverlay();
				return;
			}

			if(
				//Store is missing config
				(
					helpers.isAdminEdit === false
					&& shopModel.has_valid_config === false
				) 
				// or site is a template
				&& !store.getState().site?.is_template
			) {
				console.log('coming soon')
				store.dispatch({
					type: 'UPDATE_FRONTEND_STATE', 
					payload: {
						alertModal: { header: 'Coming Soon', type: 'notice' }
					}
				});

				this.closeOverlay();

			} else {

				this.setState({
					shopModel
				}, () => {
					this.openOverlay();
				});

			}

		});

		this.state = {
			shopModel: null,
			loaded: false,
			animate: false,
			isMobile: isMobile,
			shadowRoot: null,
		};

		this.cartElementRef = createRef();
		this.iframeRef = createRef();

		this.startTime = null;
		this.inputRef = createRef();

		this.throttledResize = _.throttle(this.resizeHandler, 200);

	}

	closeOverlay = () =>{
		window.requestAnimationFrame( () => { 
			this.setState({ closing: true }, ()=> {
				setTimeout(this.props.closeOverlay, 280);
			});
		});
	}

	onKeyDown = (e) => {
	    if ( e.key === 'Escape' || e.keyCode === 9 ){
	    	let state = window.store?.getState();
	    	let hasAlert = state?.frontendState.alertModal !== false;
	    	let alertInDom = document.querySelector('.alert-window') !== null;

	    	if( hasAlert || alertInDom ){
	    		return
	    	}
	    	
	        this.closeOverlay( e );
	    }
	}

	openOverlay() {

		if( this.cartElementRef.current && !this.cartElementRef.current.shadowRoot ){
			this.cartElementRef.current.attachShadow({mode: 'open'})
		}

		this.setState({
			shadowRoot:this.cartElementRef.current?.shadowRoot
		})		

		this.props.updateFrontendState({
			cartOpen: true
		});

		window.addEventListener("keydown", this.onKeyDown, true );

		// for some reason the CSS ref only gets populated after one frame. Guessing 
		// this is to do with the shadow dom stuff
		requestAnimationFrame(() => {

			if(this.iframeRef.current) {

				axios.get(`${PUBLIC_URL}/following_frame.html`, {
					use_auth: false
				}).then(result => {

					this.iframeRef.current.contentWindow.document.write(result.data)

					// pass some methods into the frame so it can fetch products and navigate C3 frontend
					this.iframeRef.current.contentWindow.__fetchCommerceProducts__ = this.props.fetchCommerceProducts;
					this.iframeRef.current.contentWindow.__fetchContent__ = this.props.fetchContent;
					this.iframeRef.current.contentWindow.__frontend_history__ = this.props.history;
					this.iframeRef.current.contentWindow.__add_to_cart__ = this.props.addCommerceProductToCart;
					this.iframeRef.current.contentWindow.__reset_cart__ = this.props.resetCommerceCart;
					this.iframeRef.current.contentWindow.__close_overlay__ = () => {
						this.closeOverlay();
					}

					this.iframeRef.current.contentWindow.addEventListener('commerce-ready', () => {
						this.proxyHistory();
					});

				});

			}

		});

	}

	onCSSLoad = () => {

		// animate form
		this.setState({
			loaded: true,
			isMobile: mediaQuery.matches
		}, ()=> {

			// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
			let vh = ( window.innerHeight - 30 ) * 0.01;
			let vw = ( window.innerWidth - 30 ) * 0.01;
			// Then we set the value in the --cart-vh custom property to the root of the document
			document.documentElement.style.setProperty('--cart-vh', `${vh}px`);
			document.documentElement.style.setProperty('--cart-vw', `${vw}px`);

			window.addEventListener('resize', this.throttledResize);

			document.body.style.overflow = 'hidden';
			document.scrollingElement.style.overflow = 'hidden';

			instances['1'] = this;

			setTimeout(() => {
				this.setState({ 
					animate: true 
				})
			}, 33);

		})

	}

	resizeHandler = () => {
		// Set VW / VH - 30px manually for mobile form 
		let vh = ( window.innerHeight - 30 ) * 0.01;
		let vw = ( window.innerWidth - 30 ) * 0.01;

		document.documentElement.style.setProperty('--cart-vh', `${vh}px`);
		document.documentElement.style.setProperty('--cart-vw', `${vw}px`);
	}

	componentWillUnmount = () => {
		
		this.props.updateFrontendState({
			cartOpen: false
		});

		document.body.style.overflow = '';
		document.scrollingElement.style.overflow = '';
		window.removeEventListener('resize', this.throttledResize);
		window.removeEventListener("keydown", this.onKeyDown, true );

		document.documentElement.style.removeProperty('--cart-vh');
		document.documentElement.style.removeProperty('--cart-vw');

	}

	componentDidUpdate() {

		this.proxyHistory();

	}

	proxyHistory() {

		if(!this.iframeRef || !this.iframeRef.current) {
			return;
		}

		if(!_.isEqual(this.props.match, this.currentMatch)) {

			if(this.iframeRef.current.contentWindow.renderRoute) {
				this.currentMatch = this.props.match;
				this.iframeRef.current.contentWindow.renderRoute?.(this.currentMatch)
			}

		}

	}

	render() {

		if(!this.state.shopModel) {
			return null;
		}

		return (
			<div className="cart" ref={this.cartElementRef}> {/* The shadow root will be attached to this DIV */}
				{this.state.shadowRoot && createPortal(<>
					<link rel="stylesheet" onLoad={this.onCSSLoad} type="text/css" href={`${PUBLIC_URL}/css/front-end/cart.css`} />
					{/* Background */}
					<div 
						className={`background${this.state.animate ? " active" : ""}${this.state.closing ? " closing" : ""}`}
						onMouseDown={()=>{
							if (this.currentMatch && !this.currentMatch.url.match('checkout')) {
								this.closeOverlay()
							}
							
						}}
						// don't push content down while css is still loading 
						style={{height: this.state.loaded ? "" : "0"}}
					>
						{/* MODAL */}
						<div 
							className={`modal${this.state.animate ? " active" : ""}${this.state.closing ? " closing" : ""}${this.state.isMobile ? " mobile" : ""}`}
							style={{visibility: this.state.loaded ? "" : "hidden"}}
						> 
							<div className="modal-inner">
								<iframe 
									ref={this.iframeRef}
									src={'about:blank'}
								></iframe>
							</div>
						</div>
					</div>
				</>, this.state.shadowRoot)}
			</div>
		);

	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFrontendState: actions.updateFrontendState,
		fetchCommerceProducts: actions.fetchCommerceProducts,
		fetchContent: actions.fetchContent,
		fetchShopModel: actions.fetchShopModel,
		addCommerceProductToCart: actions.addCommerceProductToCart,
		resetCommerceCart: actions.resetCommerceCart
	}, dispatch);
}


const ContactForm = withRouter(
	connect(
		null,
		mapDispatchToProps
	)(
		Cart
	)
);

export default ContactForm