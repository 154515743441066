import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';
// "image_url": "/_jsapps/backdrop/default_256.jpeg",
// "image_size": 256,
// "image": '',
// "style": "delaunay",
// "subdivisionCount": 421,
// "animationSpeed": 1,
// "hasMouseInteraction": true,
// "interactionSpeed": 15,
// "interactionRadius": 300,
// "plugin_id": 21,
// "hasMotionInteraction": false,
// "overlay_alpha": 0,
// "overlay_hex": '#000000'

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'polygon_engine',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},		
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			labelName: "Style:",
			name: "style",
			type: "select",
			value: 'delaunay',
			values: [
				{
					labelName: 'Random 1',
					value:"random1"
				},
				{
					labelName: 'Random 2',
					value:"random2"
				},
				{
					labelName: 'Iso',
					value:"iso"
				},
				{
					labelName: 'Rectangular 1',
					value:"straight1"
				},
				{
					labelName: 'Rectangular 2',
					value:"straight2"
				},
				{
					labelName: 'Voronoi',
					value:"voronoi"
				},
				{
					labelName: 'Delaunay',
					value:"delaunay"
				}
			]
		},
		{
			labelName: "Cuts",
			name: "subdivisionCount",
			type: "range-input",
			value: 421,
			min: 3,
			max: 500,
			step: 1
		},
		{
			labelName: "Animation Speed",
			name: "animationSpeed",
			type: "range-input",
			value: 1,
			min: 0,
			max: 20,
			step: 0.1
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Mouse Interaction",
			name: "hasMouseInteraction",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Interaction Speed",
			name: "interactionSpeed",
			type: "range-input",
			value: 15,
			min: 0,
			max: 20,
			step: 0.1,
			requirements: [
				{
					option: 'hasMouseInteraction',
					shouldBe: true
				}
			]
		},
		{
			labelName: "Interaction Radius",
			name: "interactionRadius",
			type: "range-input",
			value: 300,
			suffix: 'px',
			min: 0,
			max: 500,
			step: 1,
			requirements: [
				{
					option: 'hasMouseInteraction',
					shouldBe: true
				}
			]
		},
		{
			type: 'spacer'
		},
		{
			excludeFromShuffle: true,
			labelName: "Overlay Color",
			name: "color",
			type: "color",
			value: '#33333300',
		},

	]
}

settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_256.jpg";
settings.transformChanges= (settings, changes)=>{

	if(changes.color) {
		const color = tinycolor(changes.color);

		changes['overlay_hex'] = color.toHexString();
		changes['overlay_alpha'] = color.getAlpha();
	}

	return changes;
}

export default settings;

export {onChangeCustom}
