import { Component, createRef, render } from "preact";
import { createPortal } from "preact/compat"
import _ from 'lodash';

import { connect } from 'react-redux';
import selectors from "../../../../../selectors";
import { backdropSettings } from "@cargo/common";

class Gradient extends Component {

	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			counter: 0,
			shadowRootRef: null
		}

		this.gradientElementRef = createRef();
	}

	render(){

		const {
			visibility,
			dimensions,
		} = this.props;

		const {
			counter
		} = this.state;

		const settings = {};

		for (const [key, defaultEntry] of Object.entries(backdropSettings.gradient.default.defaults)) {
			if(this.props.settings?.hasOwnProperty(key)){
				settings[key] = this.props.settings[key]
			} else {
				settings[key] = defaultEntry.value;
			}
		}

		let {
			repeat,
			rotation,

			scale,
			smoothness,

			'animation-speed': animationSpeed,

			'pos-x': posX,
			'pos-y': posY,
			'gradient-type': gradientType,
			'color-one': colorOne,
			'color-two': colorTwo,
			'color-three': colorThree,
			'color-one-active': colorOneActive,
			'color-two-active': colorTwoActive,
			'color-three-active': colorThreeActive,

		} = settings;

		posY = posY + 50;
		posX = posX + 50;
		scale = parseFloat(scale)/100;
		smoothness = parseFloat(smoothness)/100;
		rotation = parseInt(rotation);

		// normalize down to 0 - 1;
		let animationPositive = animationSpeed > 0;
		animationSpeed = Math.abs(animationSpeed/100);
		animationSpeed = animationSpeed * animationSpeed;
		animationSpeed = animationPositive ? animationSpeed : - animationSpeed;

		let animate = animationSpeed !== 0;

		let slideAnimate = animate && gradientType == 'linear' && repeat;
		let zoomAnimate = animate && gradientType == 'radial' && repeat;

		let radialAnimLerp = animationSpeed > 0 ? (animationSpeed) * 120 + ((1-animationSpeed)*2400) : (Math.abs(animationSpeed))*120 + ((1-Math.abs(animationSpeed))*2400)

		let linearAnimLerp = (1 - Math.abs(animationSpeed) ) * 40 + Math.abs(animationSpeed)*4;
		let animProg = ((counter%(radialAnimLerp))/(radialAnimLerp));
		if(animationSpeed > 0){
			animProg = 1 -animProg
		}
		let colorOffset = 0;

		let stepOffset = zoomAnimate ? animProg*100*scale : 0;

		const colors = [];
		if(colorOneActive){
			colors.push(colorOne)
		}
		if(colorTwoActive){
			colors.push(colorTwo)
		}
		if(colorThreeActive){
			colors.push(colorThree)
		}

		let cssString = '';

		let fromAnimationString = ''
		let toAnimationString = '';

		// init other variables for calcuations
		let box_size = 0,
			rotate_scale = 0,
			gradient_string = "",
			bg_color_string = "",
			stops = [];

		if( colors.length == 0 ){

		} else if ( colors.length == 1 ){

			cssString+= `background-color: ${colors[0]}`

		} else if (colors.length == 2){

			if ( repeat ) {

				scale= (1-smoothness)*scale + scale*smoothness;

				let firstStep =( (100/4) * 1 +-smoothness * (100/4) ) *scale - (1-smoothness)*(100/5)*scale; //col1
				stops[0] = 0+stepOffset;
				stops[1] = ( (100/4) * 1 + smoothness * (100/4) ) *scale - (1-smoothness)*(100/5)*scale + -firstStep + stepOffset; //col2
				stops[2] = ( (100/4) * 3 +-smoothness * (100/4) ) *scale - (1-smoothness)*(100/5)*scale + -firstStep + stepOffset; //col2
				stops[3] = ( (100/4) * 3 + smoothness * (100/4) ) *scale - (1-smoothness)*(100/5)*scale + -firstStep + stepOffset; //col3
				stops[4] = 100 * scale +stepOffset;

				// stops for multiply / screen modes
				// stops[5] = (stops[0]+stops[1])/2+-firstStep;
				// stops[6] = (stops[2]+stops[3])/2+-firstStep;


			} else {

				stops[0] = 0
				stops[1] = scale * 100 +- (scale * 100)*smoothness
				stops[2] = scale * 100 + (1-scale)*100*smoothness
				stops[3] = 100

				// stops for multiply / screen modes
				// stops[4] = (stops[1]+stops[2])/2;
				// stops[5] = (stops[2]+stops[3])/2;
									
			}				

		} else {

			if ( repeat ) {
				scale= (1-smoothness)*scale + scale*smoothness;

				// smooth & scale, max is less than 100% ( gradients auto-repeat from last stop )
				let firstStep = ( (100/6) * 1 +-smoothness * (100/6) ) *scale - (1-smoothness)*(100/7)*scale; //col1

				stops[0] = 0+stepOffset;
				stops[1] = ( (100/6) * 1 + smoothness * (100/6) ) *scale - (1-smoothness)*(100/7)*scale +-firstStep+stepOffset; //col2
				stops[2] = ( (100/6) * 3 +-smoothness * (100/6) ) *scale - (1-smoothness)*(100/7)*scale +-firstStep+stepOffset; //col2
				stops[3] = ( (100/6) * 3 + smoothness * (100/6) ) *scale - (1-smoothness)*(100/7)*scale +-firstStep+stepOffset; //col3
				stops[4] = ( (100/6) * 5 +-smoothness * (100/6) ) *scale - (1-smoothness)*(100/7)*scale +-firstStep+stepOffset; //col3
				stops[5] = ( (100/6) * 5 + smoothness * (100/6) ) *scale - (1-smoothness)*(100/7)*scale +-firstStep+stepOffset; //col1
				stops[6] = 100 * scale+ stepOffset;                         										 //col1


		


				// stops for multiply / screen modes
				// stops[7] = (stops[0]+stops[1])/2;
				// stops[8] = (stops[2]+stops[3])/2;
				// stops[9] = (stops[4]+stops[5])/2;


			} else {

				scale= (1-smoothness)*3*scale + scale*smoothness;

				// should be done programmatically
															// scale  0   0     1   1
														    //smooth  0   1     0   1
				// smooth it
				stops[0] = (100/6) * 0 + smoothness * (100/6) * 0; // 0   0   / 0   0    //col1
				stops[1] = (100/6) * 2 +-smoothness * (100/6) * 2; // 0   33  / 0   0    //col1
				stops[2] = (100/6) * 2 + smoothness * (100/6) * 1; // 0   33  / 0   50   //col2
				stops[3] = (100/6) * 4 +-smoothness * (100/6) * 1; // 100 66  / 100 50   //col2
				stops[4] = (100/6) * 4 + smoothness * (100/6) * 2; // 100 66  / 100 100  //col3
				stops[5] = (100/6) * 6 +-smoothness * (100/6) * 0; // 100 100 / 100 100  //col3

				// scale it
				// lock to 0 - 100 range
				stops[0] = Math.max(stops[0]*scale + 50 * (1-scale), 0);
				stops[1] = Math.max(stops[1]*scale + 50 * (1-scale), 0);
				stops[2] = stops[2]*scale + 50 * (1-scale);
				stops[3] = stops[3]*scale + 50 * (1-scale);
				stops[4] = Math.min(stops[4]*scale + 50 * (1-scale), 100);
				stops[5] = Math.min(stops[5]*scale + 50 * (1-scale), 100);

				// stops for multiply / screen modes
				// stops[7] = (stops[1]+stops[2])/2;
				// stops[8] = (stops[3]+stops[4])/2;

			}

		}


		if(colors.length > 1){
			cssString = 'background-image: '

			cssString+= repeat ? 'repeating-' :''
			cssString+= gradientType =='linear' ? `linear-gradient( ${rotation+180}deg` : `radial-gradient( circle at ${posX}% ${posY}%`;

			fromAnimationString = toAnimationString ='background-position: ';


			let colorIndexMap = []
			if( colors.length == 2 ){
				if(repeat){
					colorIndexMap = [0, 1, 1, 0, 0]
				} else {
					colorIndexMap = [0, 0, 1, 1]
				}
			} else {
				if(repeat){
					colorIndexMap = [0, 1, 1, 2, 2, 0, 0]
				} else {
					colorIndexMap = [0, 0, 1, 1, 2, 2]
				}
			}

			if( slideAnimate){
				stops.forEach((colorStop, index)=>{
					const color = colors[colorIndexMap[index]] || colors[0];
					cssString+= `, ${color} ${colorStop/2}%`
				})
			} else {
				stops.forEach((colorStop, index)=>{
					const color = colors[colorIndexMap[index]] || colors[0];
					cssString+= `, ${color} ${colorStop}%`
				})				
			}

			cssString+= ')'


			if(slideAnimate){

				let rad = (rotation+90)*(Math.PI/180);
				let aspectRatio = dimensions.width/dimensions.height


				// let xMove = 200*(1/aspectRatio);
				// let yMove = 200*(aspectRatio);
				let xMove = Math.cos(rad);
				let yMove = Math.sin(rad);

				let lastStop = stops[stops.length-1]+'%';
				if( xMove < 0){
					
					toAnimationString+= '0% ';
					fromAnimationString+= lastStop+' ';
				} else {
					toAnimationString+= lastStop+' ';
					fromAnimationString+= '0% ';
				}

				if( yMove < 0){
					yMove*= -1;
					toAnimationString+= '0%;';
					fromAnimationString+= lastStop+';'
				} else {
					toAnimationString+= lastStop+';'
					fromAnimationString+='0%;';
				}

			}
		}

		return <div className="gradient" ref={this.gradientElementRef}>
		
			{this.state.shadowRoot && createPortal(<style>
				{`:host {
						${cssString};
					    position: absolute;
					    top: 0;
					    left: 0;
					    right: 0;
					    bottom: 0;
					    margin: auto;
					    background-position: 0 0;
					    background-size: ${slideAnimate? '200% 200%;' : '100% 100%;'}
					    ${slideAnimate && 'will-change: background-position, background-image;'}
					    ${slideAnimate && 'animation: slideAnimation '+linearAnimLerp+'s linear infinite '+(animationSpeed < 0 ? 'reverse' : 'normal')+';'}
					}

					${slideAnimate ? `
					@keyframes slideAnimation {
						0% {
							${fromAnimationString}
						}

						100% {
							${toAnimationString}
						}
					}
					`: ''}
					`}
			</style>, this.state.shadowRoot)}
			
		</div>
	}

	componentDidUpdate(prevProps, prevState){
		if(
			this.props.settings.repeat !== prevProps.settings.repeat ||
			this.props['animation-speed'] !== prevProps['animation-speed']
		){
			cancelAnimationFrame(this.animFrame);
			if( this.props.settings.repeat && this.props['animation-speed'] !== 0){
				this.animFrame = requestAnimationFrame(this.draw);
			}
		}
	}

	componentWillUnmount(){
		cancelAnimationFrame(this.animFrame);
	}

	draw = ()=>{
		this.setState((prevState)=> {return {counter: prevState.counter+1}}, ()=>{
			this.animFrame = requestAnimationFrame(this.draw);
		});
	}

	componentDidMount(){

		if( this.gradientElementRef.current && !this.gradientElementRef.current.shadowRoot ){
			this.gradientElementRef.current.attachShadow({mode: 'open'})
		}

		this.setState({
			shadowRoot:this.gradientElementRef.current?.shadowRoot
		})


		if( this.props.settings.repeat && this.props['animation-speed'] !== 0 ){
			this.animFrame = requestAnimationFrame(this.draw)
		}

		if(this.props.onLoad){
			this.props.onLoad();
		}
		
	}

	onFirstLoad=(e)=>{

	}

}

export default Gradient
